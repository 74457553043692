<template>
  <div>
    <div
      v-if="charts.length"
      class="base-chart"
      id="apexcharts-line"
    >
      <div class="base-chart__header">
        <span @click="toggleChartDropdown"
              class="base-chart__title">Типы обращений</span>
        <icon-arrow-linear-down
          :class="{'toggle': showChartDropdown}"/>
        <div class="tags-select">
          <div v-for="chartType in charts"
               :key="chartType.id"
               @click="setCurrentChartType(chartType)"
               class="tags-select__item"
               :class="{
                 'selected': currentChartType.id === chartType.id,
               }">
            {{ chartType.groupName }}
          </div>
        </div>
        <base-dropdown class="base-chart__dropdown"
                       v-if="showChartDropdown">
          <span v-for="chart in charts"
                :key="chart.id"
                @click="setCurrentChartType(chart.id)"
                class="base-dropdown__option">{{ chart.groupName }}</span>
        </base-dropdown>
      </div>
      <div v-for="chart in charts"
           :key="chart.id"
           class="chart-wrapper">
        <apexchart
          v-if="chart.id === currentChartType.id"
          type="donut"
          :options="chart.options"
          :series="chart.series"
          height="392px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import VueApexCharts from "vue3-apexcharts";
import IconArrowLinearDown from "@/components/Icons/IconArrowLinearDown.vue";
import BaseDropdown from "@/components/BaseDropdown.vue";
import {getCssVariableColor} from "@/utils/helpers";

export default {
  name: "AppealsCountBaCategory",
  components: {BaseDropdown, IconArrowLinearDown, 'apexchart': VueApexCharts},
  data() {
    return {
      charts: [],
      currentChartType: null,
      showChartDropdown: false
    }
  },
  computed: {
    ...mapGetters({
      appealsCount: 'appealsStatistic/getAppealsCountByCategory'
    }),
    ...mapState({
      categoryGroups: state => state.serviceDeskCategories.categoryGroups
    })
  },
  watch: {
    appealsCount: function () {
      this.createChartData();
    }
  },
  mounted() {
      this.createChartData();
  },
  methods: {
    createChartData() {
      this.charts = [];

      this.categoryGroups.forEach(group => {
        const filteredStatistic = this.appealsCount.filter(statItem => statItem.group.id === group.id);

        if (filteredStatistic.length) {
          this.charts.push({
            id: group.id,
            groupName: group.name,
            appealsCountByCategories: filteredStatistic,
            series: filteredStatistic.map(statItem => statItem.count),
            options: {
              legend: {
                show: true,
                position: 'bottom',
                labels: {
                  colors: getCssVariableColor('--text-primary')
                }
              },
              labels: filteredStatistic.map(statItem => statItem.category.name),
              tooltip: {
                enabled: false
              },
              dataLabels: {
                formatter: (val, opts) => {
                  return opts.w.config.series[opts.seriesIndex]
                }
              },
              plotOptions: {
                pie: {
                  expandOnClick: false,
                  donut: {
                    labels: {
                      show: true,
                      value: {
                        color: getCssVariableColor('--text-primary')
                      },
                      total: {
                        show: true,
                        label: 'Всего',
                        color: getCssVariableColor('--text-primary')
                      }
                    }
                  }
                }
              }
            }
          })
          this.setCurrentChartType(this.charts[0]);
        }
      })
    },
    setCurrentChartType(type) {
      this.currentChartType = type;
      this.showChartDropdown = false;
    },
    toggleChartDropdown() {
      this.showChartDropdown = !this.showChartDropdown;
    }
  }
}
</script>

<style scoped>
</style>
