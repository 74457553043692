<template>
  <div class="page-wrapper">
    <base-panel
      title="Статистика по обращениям"
      :is-add-button-visible="false"/>
    <div class="filter">
      <div class="filter__left">
        <base-date-range-picker  @updateDateRange="setRange($event)" />
        <base-date-picker
          v-model="range"
          range
          value-format="yyyy-MM-dd"
          placeholder="Выберите период"
          @update:modelValue="getAppealsData"
        />
      </div>
      <button @click.prevent="getExcel(tableRows)"
              :disabled="!tableRows.length"
              class="button button--mode-outline">
        <icon-export/>
        <span>Экспорт</span>
      </button>
    </div>
    <base-content-loaders v-if="loading" />
    <div v-if="contentLoaded">
      <base-statistic-cards
        :all="appealsSummary.opened"
        :close="appealsSummary.closed"
        :open="appealsSummary.active" />
      <appeals-count-by-day  />
      <appeals-count-by-category />
      <div v-if="Object.keys(getAppealsTableData).length"
           class="base-table__container">
        <div class="base-table__header base-table__header--appeals-dashboard">
          <div class="base-table__header-cell">Тип обращений</div>
          <div class="base-table__header-cell">Количество</div>
        </div>

        <div v-for="appeal in getAppealsTableData"
             :key="appeal.name"
             class="base-table__group" >
          <div class="base-table__group-header">
            <span class="base-table__group-title">{{appeal.name}}</span>
          </div>
          <div v-for="item in appeal.appeals"
               :key="item.category.id"
               class="base-table__row base-table__row--appeals-dashboard" >
            <div class="base-table__column">
              <div class="base-table__text">{{ item.category.name }}</div>
            </div>
            <div class="base-table__column">
              <div class="base-table__text">{{ item.count }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppealsCountByDay from "@/components/CallCenter/AppealsCountByDay";
import AppealsCountByCategory from "@/components/CallCenter/AppealsCountBaCategory";
import BaseDateRangePicker from "@/components/BaseDateRangePicker.vue";
import {mapState} from "vuex";
import IconExport from "@/components/Icons/IconExport.vue";
import BasePanel from "@/components/BasePanel.vue";
import {getExcel} from "@/utils/table";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import BaseStatisticCards from "@/components/ServiceDesk/BaseStatisticCards.vue";
import BaseDatePicker from "@/components/BaseDatePicker.vue";

export default {
  name: "AppealsDashboardPage",
  components: {
    BaseDatePicker,
    BaseStatisticCards,
    BaseContentLoaders,
    BasePanel,
    IconExport,
    BaseDateRangePicker,
    AppealsCountByCategory,
    AppealsCountByDay
},
  data() {
    return {
      range: []
    }
  },
  computed: {
    ...mapState({
      appealsCountByDay: state => state.appealsStatistic.appealsCountByDay,
      appealsCount: state => state.appealsStatistic.appealsCountByCategory,
      categoryGroups: state => state.serviceDeskCategories.categoryGroups,
      loading: state => state.appealsStatistic.loading,
      appealsSummary: state => state.appealsStatistic.appealsSummary
    }),
    contentLoaded() {
      return !this.loading && this.appealsCountByDay.length;
    },
    getFilterParams () {
      const [start, end] = this.range;
      return {
        start_period: `${start} 00:00:00`,
        end_period: `${end} 23:59:59`
      }
    },
    getAppealsTableData() {
      const appealsTableData = {};
      for (let appeal of this.appealsCount) {
        if (appealsTableData[appeal.group.id]?.appeals?.length) {
          appealsTableData[appeal.group.id].appeals.push(appeal)
        } else {
          appealsTableData[appeal.group.id] = {};
          appealsTableData[appeal.group.id].appeals = [appeal];
          appealsTableData[appeal.group.id].name = appeal.group.name;
        }
      }
      return appealsTableData;
    },
    tableRows() {
      const rows = [];
      if (this.appealsCount.length) {
      rows.push(['Тип обращений','Количество']);
      for (let key in this.getAppealsTableData) {
        rows.push([this.getAppealsTableData[key].name])
        for (let item of this.getAppealsTableData[key].appeals) {
            rows.push([item.category.name, item.count]);
        }
      }
      }
      return rows;
    }
  },
  mounted() {
    this.$store.dispatch('serviceDeskCategories/getCategoryGroups');
  },
  methods: {
    getExcel,
    getAppealsData() {
      this.$store.dispatch('appealsStatistic/getAppealsStatistic', this.getFilterParams);
    },
    setRange(date) {
      this.range = [date.start, date.end];
      this.getAppealsData();
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers/index" as *;
.filter {
  display: grid;
  grid-template-columns: 771px 144px;
  gap: 20px;
  width: 100%;
  margin-bottom: 32px;
  justify-content: space-between;

  &__left {
    display: grid;
    grid-template-columns: 1fr 320px;
    gap: 20px;
    justify-content: center;

    @media (max-width: $tablet) {
      grid-template-columns: 1fr;
    }
  }

  .base-date-range-picker {
    justify-self: flex-start;
  }


  @media (max-width: $tablet) {
    grid-template-columns: 1fr;
  }
}
</style>
