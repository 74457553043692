<template>
  <div v-if="appealsCount.length"
       class="base-chart">
    <div class="base-chart__header">
      <span
        class="base-chart__title">Обращения</span>
    </div>
    <apexchart
      ref="chartComponent"
      type="bar"
      height="392"
      :options="createChartOptions"
      :series="createChartOptions.series"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import VueApexCharts from "vue3-apexcharts";
import {getCssVariableColor} from "@/utils/helpers";
export default {
  name: "AppealsCountByDay",
  components: { 'apexchart': VueApexCharts},
  computed: {
    ...mapGetters({
      appealsCount: 'appealsStatistic/getAppealsCountByDay'
    }),
    createChartOptions() {
      return {
        chart: {
          type: 'bar'
        },
        xaxis: {
          labels: {
            style: {
              cssClass: 'base-bar-chart-x'
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              cssClass: 'base-bar-chart-y'
            }
          }
        },
        plotOptions: {
          bar: {
            colors: {
              backgroundBarOpacity: 1
            },
            dataLabels: {
              total: {
                style: {
                  color: getCssVariableColor('--text-primary')
                }
              }
            }
          }
        },
        series: [{
          data: this.appealsCount.map(item => {
            return {
              x: item.date,
              y: item.count
            }
          }),
          color: '#6375D4'
        }]

      }
    }
  }
}
</script>

<style lang="scss">
.base-bar-chart-x, .base-bar-chart-y {
  fill: var(--text-primary) !important;
}
</style>
