<template>
  <div>
    <base-functional-line @search-change="event => $store.commit('questions/SET_QUERY', event.target.value)">
      <button
        @click="openQuestionPopup()"
        class="button button--mode-primary">
        <icon-plus/>
        Добавить вопрос
      </button>
    </base-functional-line>

    <base-content-loaders v-if="loading"/>

    <div
      v-if="questionsList.length"
      class="base-table">
      <div class="base-table__container">
        <div class="base-table__header base-table__column--question">
          <div class="base-table__header-cell">ID</div>
          <div class="base-table__header-cell">Наименование</div>
          <div class="base-table__header-cell">Тип вопроса</div>
          <div class="base-table__header-cell"/>
        </div>

        <div v-for="question in questionsList"
             :key="question.id"
             class="base-table__row base-table__column--question">
          <div class="base-table__column">
            <div class="base-table__text">{{ question.id }}</div>
          </div>
          <div class="base-table__column">
            <div class="base-table__text">{{ question.question }}</div>
          </div>
          <div class="base-table__column">
            <div class="base-table__text">{{ question.answerType.name }}</div>
          </div>
          <div class="base-table__column base-table__column--content-right">
            <div class="base-table__short-info">
              <div @click="openQuestionPopup(question)">
                <icon-pencil class="base-table__short-info--p-2"/>
              </div>
              <div @click="deleteQuestion(question.id)">
                <icon-trash/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <base-no-data v-else-if="!loading"/>
  </div>
</template>

<script>
import IconPlus from "@/components/Icons/IconPlus.vue";
import BaseFunctionalLine from "@/components/BaseFunctionalLine.vue";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import IconPencil from "@/components/Icons/IconPencil.vue";
import IconTrash from "@/components/Icons/IconTrash.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import QuestionsForm from "@/components/TechnicalInspection/NetworkObjectTypes/QuestionsForm.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import {showToast, showAlert, showConfirmationModal} from "@/utils/notification";

export default {
  name: "QuestionsList",
  components: {BaseNoData, IconTrash, IconPencil, BaseContentLoaders, BaseFunctionalLine, IconPlus},
  props: {
    questionsList: {
      type: Array,
      required: true
    },
    dictionariesList: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      popupName: 'question-popup'
    }
  },
  methods: {
    openQuestionPopup(question = {}) {
      this.$modal.show(
        QuestionsForm,
        {
          'popupName': this.popupName,
          'question': question,
          'createQuestion': this.createQuestion,
          'updateQuestion': this.updateQuestion,
          'dictionariesList': this.dictionariesList
        },
        {
          name: this.popupName,
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        }
      )
    },
    createQuestion(payload) {
      this.$store.dispatch('questions/createQuestion', payload)
        .then(() => {
          this.$store.dispatch('questions/getQuestionsList', this.$route.params.id);
          this.$modal.hide(this.popupName);
          showToast('Вопрос добавлен', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    updateQuestion(id, payload) {
      this.$store.dispatch('questions/updateQuestion', {id, payload})
        .then(() => {
          this.$store.dispatch('questions/getQuestionsList', this.$route.params.id);
          this.$modal.hide(this.popupName);
          showToast('Вопрос обновлен', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    deleteQuestion(id) {
      showConfirmationModal('Вы уверены, что хотите удалить вопрос? Это действие невозвратно.')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('questions/deleteQuestion', id)
              .then(() => {
                this.$store.dispatch('questions/getQuestionsList', this.$route.params.id);
                showToast('Вопрос удален', 'success');
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    }
  }
}
</script>

<style scoped>
.base-table__column--question {
  grid-template-columns: 60px 2fr 1fr 100px;
}
</style>