<template>
  <aside class="sidebar"
         :class="{'collapsed': getIsActiveSidebar}">
    <button class="sidebar__toggle"
            @click="isToggled">
      <icon-arrow-double/>
    </button>
    <div class="sidebar__inner">
      <div class="sidebar__top"
           :class="{'sidebar__top--active': isActiveBorderMenu}">
        <router-link to="/">
          <img :class="`sidebar__logo ${getProjectImageUrlClassPosition}`"
               :src="`${getProjectImageUrl}`"
               alt="logo">
        </router-link>
      </div>
      <div class="sidebar__navigation">
        <ul class="nav-links">
          <menu-link
            v-for="link in menu"
            :key="link.label"
            :link="link"
            :deep="0"
            :is-collapsed="getIsActiveSidebar"
            @active-border-menu="val => isActiveBorderMenu = val"
          />
        </ul>
      </div>
      <div class="sidebar__profile"
           :class="{'sidebar__profile--active': isActiveBorderMenu}">
        <div class="sidebar__profile-content">
          <span class="sidebar__profile-name">
            {{ shortenName }}
          </span>
          <div
            class="sidebar__profile-menu"
            @click="togglePopup()">
            <icon-menu-dots/>
          </div>
        </div>
        <div
          v-if="isUserPopupActive"
          class="sidebar__popup">
          <div class="sidebar__popup-inner">
            <div class="sidebar__popup-item">
              <div class="theme-switcher">
                <div class="sidebar__popup-title">Цветовая схема</div>
                <div class="theme-switcher__themes">
                  <div
                    @click="changeColorSchema('light')"
                    class="theme-switcher__themes-item-wrapper">
                    <div
                      class="theme-switcher__themes-item"
                      :class="{'theme-switcher__themes-item--active': selectedTheme === 'light'}">
                      <icon-sun/>
                    </div>

                    <span v-if="selectedTheme === 'light'">Светлая</span>
                  </div>
                  <div
                    @click="changeColorSchema('dark')"
                    class="theme-switcher__themes-item-wrapper">
                    <div
                      class="theme-switcher__themes-item"
                      :class="{'theme-switcher__themes-item--active': selectedTheme === 'dark'}">
                      <icon-moon/>
                    </div>

                    <span v-if="selectedTheme === 'dark'">Темная</span>
                  </div>
                  <div
                    @click="changeColorSchema('system')"
                    class="theme-switcher__themes-item-wrapper">
                    <div
                      class="theme-switcher__themes-item"
                      :class="{'theme-switcher__themes-item--active': selectedTheme === 'system'}">
                      <icon-monitor/>
                    </div>

                    <span v-if="selectedTheme === 'system'">Система</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="sidebar__popup-item">
              <div class="sidebar__popup-item-simple">
                <icon-book3/>
                <a class="sidebar__popup-title"
                   target="_blank"
                   href="https://docs.masis05.ru">Документация</a>
              </div>
            </div>
            <div
              @click="logout"
              class="sidebar__popup-item">
              <div class="sidebar__popup-item-simple">
                <icon-logout/>
                <div class="sidebar__popup-title">Выйти из системы</div>
              </div>
            </div>
          </div>
        </div>
        <div class="sidebar__version">
          <span class="sidebar__version-name">Версия {{ localAppVersion }}</span>
          <div v-if="isUpdateAvailable"
               class="sidebar__version-update">
            <span class="update-text">Доступно обновление</span>
            <button @click="reloadPage"
                    class="update-button">Обновить
            </button>
          </div>
        </div>
      </div>
    </div>
  </aside>

</template>

<script>
import {mapGetters, mapState} from "vuex";
import IconArrowDouble from "@/components/Icons/IconArrowDouble.vue";
import IconLogout from "@/components/Icons/IconLogout.vue";
import MenuLink from "@/layout/MenuLink.vue";
import IconMenuDots from "@/components/Icons/IconMenuDots.vue";
import IconSun from "@/components/Icons/IconSun.vue";
import IconMoon from "@/components/Icons/IconMoon.vue";
import IconMonitor from "@/components/Icons/IconMonitor.vue";
import IconBook3 from "@/components/Icons/IconBook3.vue";

export default {
  name: "TheSidebar",
  components: {
    IconBook3,
    IconMonitor,
    IconMoon,
    IconSun,
    IconMenuDots,
    MenuLink,
    IconLogout,
    IconArrowDouble
  },
  data() {
    return {
      logoutBlock: false,
      isActiveBorderMenu: false,
      showServiceDeskSettingsCollapse: false,
      showBillingSettingCollapse: false,
      showTelemetryCollapse: false,
      showTransportCollapse: false,
      showNetworkSettingsCollapse: false,
      isUserPopupActive: false,
      menu: [
        {
          label: 'Телеметрия',
          icon: 'iconWifi',
          isActive: false,
          children: [
            {
              label: 'Телеметрия',
              route: '/telemetry',
              typePermission: "object-monitoring"
            },
            {
              label: 'Телеметрия 2.0',
              route: '/telemetry-lists',
              typePermission: "object-monitoring"
            },
            {
              label: 'SCADA',
              route: '/scada',
              typePermission: "object-monitoring.scada"
            },
            {
              label: 'Архив',
              route: '/telemetry-archive',
              typePermission: "object-monitoring"
            },
            {
              label: 'Журнал ручного ввода',
              route: '/logbook',
              typePermission: "object-monitoring"
            }
          ]
        },
        {
          label: 'Диспетчерская',
          icon: 'iconCall',
          isActive: false,
          children: [
            {
              label: 'Обращения',
              route: '/appeals-list',
              typePermission: "service-desk.appeals"
            },
            {
              label: 'Заявки',
              route: '/issues-list',
              typePermission: "service-desk.issues"
            },
            {
              label: 'Задания',
              route: '/tasks',
              typePermission: "service-desk.issues"
            },
            {
              label: 'Оперативная схема',
              route: '/operational-scheme',
              typePermission: "service-desk.issues"
            },
            {
              label: 'Отчеты',
              name: 'service-desk-reports',
              children: [
                {
                  label: 'Аварийные элементы',
                  route: '/emergency-elements-reports',
                  typePermission: "service-desk.reports"
                },
                {
                  label: 'Статистика по аварийности',
                  route: '/accident-reports',
                  typePermission: "service-desk.reports"
                },
                {
                  label: 'Суточный отчет ЕДС',
                  route: '/daily-reports',
                  typePermission: "service-desk.reports"
                },
                {
                  label: 'Показатели в динамике',
                  route: '/issues-count-reports',
                  typePermission: "service-desk.reports"
                },
                {
                  label: 'Расход ГСМ',
                  route: '/fuel-and-lubricants-reports',
                  typePermission: "service-desk.reports"
                },
                {
                  label: 'Расходы и потери воды',
                  route: '/water-flow-reports',
                  typePermission: "service-desk.reports"
                },
                {
                  label: 'Загруженность транспорта',
                  route: '/transport-load-report',
                  typePermission: "service-desk.reports"
                }
              ]
            },
            {
              label: 'Статистика',
              name: 'service-desk-statistic',
              children: [
                {
                  label: 'Статистика обращений',
                  route: '/appeals-dashboard',
                  typePermission: "service-desk.appeals"
                },
                {
                  label: 'Статистика заявок',
                  route: '/issues-dashboard',
                  typePermission: "service-desk.issues"
                },
                {
                  label: 'Статистика по ресурсам',
                  route: '/resource-rating',
                  typePermission: "service-desk.resource_management"
                }
              ]
            },
            {
              label: 'Конфигурация',
              name: 'service-desk-configuration',
              children: [
                {
                  label: 'Категории',
                  route: '/service-desk-categories',
                  typePermission: "service-desk.settings"
                },
                {
                  label: 'Подразделения',
                  route: '/departments',
                  typePermission: "service-desk.settings"
                },
                {
                  label: 'Ресурсы',
                  route: '/resources',
                  typePermission: "service-desk.settings"
                },
                {
                  label: 'Типы заданий',
                  route: '/task-types',
                  typePermission: "service-desk.settings"
                },
                {
                  label: 'Работы',
                  route: '/works',
                  typePermission: "service-desk.settings"
                },
                {
                  label: 'Средства малой механизации',
                  route: '/mechanical-equipments',
                  typePermission: "service-desk.settings"
                },
                {
                  label: 'Расходные материалы',
                  route: '/consumables-catalog',
                  typePermission: "service-desk.settings"
                },
                {
                  label: 'Дефекты',
                  route: '/defects-catalog',
                  typePermission: "service-desk.settings"
                }
              ]
            },
            {
              label: 'Заказы транспорта',
              route: '/transport-orders',
              typePermission: "service-desk.transport-orders"
            },
            {
              label: 'Ресурсы',
              route: '/resources-list',
              typePermission: 'service-desk.resource_management'
            },
            {
              label: 'Загруженность ресурсов',
              route: '/resources-load',
              typePermission: "service-desk.resource_management"
            },
            {
              label: 'Расписание ресурсов',
              route: '/brigades-work-shift',
              typePermission: "service-desk.resource_management"
            },
            {
              label: 'Оповещения',
              route: '/shutdown-notifications',
              typePermission: 'service-desk.shutdown_notification'
            }
          ]
        },
        {
          label: 'Транспорт',
          icon: 'iconTruck',
          isActive: false,
          children: [
            {
              label: 'Трекинг транспорта',
              route: '/tracking',
              typePermission: "transport-tracking"
            },
            {
              label: 'Автопарк',
              route: '/admin-autos',
              typePermission: "transport.management"
            },
            {
              label: 'Сервера данных',
              route: '/transport-data-servers',
              isAdmin: true
            }
          ]
        },
        {
          label: 'Обходчик',
          icon: 'iconClipboardText',
          isActive: false,
          children: [
            {
              label: 'Задания',
              route: '/technical-inspection-tasks',
              typePermission: 'technical-inspection'
            },
            {
              label: 'Объекты',
              route: '/technical-inspection-network-objects',
              typePermission: 'technical-inspection'
            },
            {
              label: 'Статистика',
              name: 'technical-inspection-statistic',
              children: [
                {
                  label: 'По ресурсам',
                  route: '/technical-inspection-resources-statistic',
                  typePermission: 'technical-inspection'
                },
                {
                  label: 'По объектам',
                  route: '/technical-inspection-objects-statistic',
                  typePermission: 'technical-inspection'
                }
              ]
            },
            {
              label: 'Конфигурация',
              name: 'technical-inspection-configuration',
              children: [
                {
                  label: 'Конфигурация типов объектов',
                  route: '/technical-inspection-network-object-types',
                  typePermission: "technical-inspection.settings"
                },
                {
                  label: 'Словари',
                  route: '/technical-inspection-dictionaries',
                  typePermission: 'technical-inspection.settings'
                }
              ]
            }
          ]
        },
        {
          label: 'Биллинг',
          icon: 'iconBilling',
          isActive: false,
          children: [
            {
              label: 'Фильтр по абонентам',
              route: '/billing-clients',
              typePermission: "billing"
            },
            {
              label: 'Фильтр по объектам',
              route: '/billing-buildings',
              typePermission: "billing"
            },
            {
              label: 'Эталонный объект',
              route: '/standard-building',
              typePermission: "billing"
            },
            {
              label: 'Баланс водоснабжения',
              route: '/water-balance',
              typePermission: "billing"
            },
            {
              label: 'Баланс водоотведения',
              route: '/drain-balance',
              typePermission: "billing"
            },
            {
              label: 'Зоны',
              route: '/admin-zones',
              isAdmin: true
            },
            {
              label: 'Балансовые группы',
              route: '/admin-balance-groups',
              isAdmin: true
            }
          ]
        },
        {
          label: 'Инженерные сети',
          icon: 'iconNetworks',
          isActive: false,
          isAdmin: true,
          children: [
            {
              label: 'Сети',
              route: '/engineering-networks',
              typePermission: 'network-engineering.settings'
            },
            {
              label: 'Объекты сети',
              route: '/network-objects-catalog',
              typePermission: 'network-engineering.settings'
            },
            {
              label: 'Типы объектов сети',
              route: '/network-object-types',
              typePermission: 'network-engineering.settings'
            },
            {
              label: 'Типы сетевого оборудования',
              route: '/network-equipment-types',
              typePermission: 'network-engineering.settings'
            },
            {
              label: 'Провайдеры данных',
              route: '/semantic-providers-catalog',
              typePermission: 'network-engineering.settings'
            },
            {
              label: 'Картографические слои',
              route: '/admin-layers',
              typePermission: 'network-engineering.settings'
            },
            {
              label: 'Виды работ технического обслуживания',
              route: '/technical-service-works',
              typePermission: 'network-engineering.settings'
            },
            {
              label: 'Протяженность сетей',
              route: '/networks-length',
              typePermission: 'network-engineering.length'
            }
          ]
        },
        {
          label: 'Энергопотребление',
          icon: 'iconEnergy',
          isActive: false,
          children: [
            {
              label: 'Журнал энергопотребления',
              route: '/energy-consumption-logbook',
              typePermission: "energy-consumption"
            },
            {
              label: 'Объекты энергопотребления',
              route: '/energy-consumption-object-catalog',
              typePermission: "energy-consumption"
            }
          ]
        },
        {
          label: 'Система',
          icon: 'iconSettings',
          isActive: false,
          isAdmin: true,
          children: [
            {
              label: 'Пользователи',
              route: '/admin-users',
              typePermission: "system-settings",
              isAdmin: true
            },
            {
              label: 'Роли',
              route: '/admin-roles',
              typePermission: "system-settings",
              isAdmin: true
            },
            {
              label: 'Структурные подразделения',
              route: '/structural-units',
              typePermission: "system-settings",
              isAdmin: true
            }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: "auth/isAdmin",
      userName: "auth/userName",
      checkPermission: "auth/checkPermission",
      getIsActiveSidebar: 'sidebar/getIsActiveSidebar',
      getProjectImageUrl: 'projectConfig/getProjectImageUrl',
      getProjectImageUrlClassPosition: "projectConfig/getProjectImageUrlClassPosition",
      localAppVersion: "appVersion/localAppVersion",
      isUpdateAvailable: "appVersion/isUpdateAvailable"
    }),
    ...mapState({
      authUser: state => state.auth.user,
      selectedTheme: state => state.projectConfig.selectedColorSchema
    }),
    shortenName() {
      const userWords = this.userName.split(' ');
      return userWords.length > 1 ? `${userWords[0]} ${userWords[1][0]}.` : this.userName;
    }
  },
  mounted() {
    const windowInnerWidth = document.documentElement.clientWidth;
    if (windowInnerWidth <= 1440) this.$store.commit('sidebar/CHANGE_SIDEBAR_STATE', true);

    document.addEventListener('click', e => {
      if (!e.target.closest('.sidebar__popup') && (!e.target.closest('.sidebar__profile-menu'))) {
        this.isUserPopupActive = false;
      }
    })
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
    isToggled() {
      this.$store.dispatch("sidebar/togledSidebar")
    },
    changeColorSchema(schemaName) {
      this.$store.dispatch('projectConfig/setColorScheme', schemaName);
    },
    togglePopup() {
      this.isUserPopupActive = !this.isUserPopupActive;
    },
    reloadPage() {
      location.reload();
    }
  }
}
</script>
