<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      Новое СММ
      <span @click="$modal.hide('mechanical-equipment-form')"
            class="base-popup__close">
        <icon-close />
      </span>
    </div>
    <div class="popup-form-group">
      <div class="form-item">
        <div class="form-item__name">СММ</div>
        <div class="form-item__value">
          <v-select
            v-model="v$.payload.equipment_id.$model"
            :options="mechanicalEquipments"
            :reduce="equipment => equipment.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите из списка"
            class="vue-select-input"
            :class="{'vue-select-input--error': v$.payload.equipment_id.$error}"
          />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Время работы, часы</div>
        <div class="form-item__value">
          <input
            v-model="v$.payload.duration.$model"
            class="base-input base-input--bordered"
            :class="{'base-input--error': v$.payload.duration.$error}"
            type="text"
            name="material"
            placeholder="Укажите время работы">
        </div>
      </div>
    </div>
    <div class="base-popup__footer">
      <div @click="submitForm()"
           class="button">
        Добавить
      </div>
    </div>
  </div>
</template>

<script>
import {showAlert, showToast} from "@/utils/notification";
import IconClose from "@/components/Icons/IconClose";
import {useVuelidate} from "@vuelidate/core";
import { required, minValue } from '@vuelidate/validators'


export default {
  name: "MechanicalEquipmentForm",
  components: {IconClose},
  props: {
    mechanicalEquipments: {
      type: Array,
      required: true
    },
    taskId: {
      type: Number,
      required: true
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      payload: {
        equipment_id: null,
        duration: null
      }
    }
  },
  validations() {
    return {
      payload: {
        equipment_id: {required},
        duration: {minValue: minValue(0), required}
      }
    }
  },
  methods: {
    submitForm() {
      this.$store.dispatch('taskMechanicalEquipments/addMechanicalEquipmentToTask', {
        taskId: this.taskId,
        payload: this.payload
      })
        .then(() => {
          this.$store.dispatch('taskModal/getMechanicalEquipmentsList', this.taskId);
          showToast('Средство малой механизации добавлено в задание', 'success');
          this.$modal.hide('mechanical-equipment-form');
        })
        .catch(error => showAlert('Ошибка', error, 'error'));
    }
  }
}
</script>

<style scoped>

</style>
