<template>
  <div class="page-wrapper">
    <base-panel
      title="Фильтр по абонентам"
      :is-add-button-visible="false"
    />

    <div class="billing-filter-container">
      <div class="tabs">
        <div class="tabs__header tabs__header--block">
          <div
            @click="activeTab = tabFilter"
            :class="{'active': activeTab === tabFilter}"
            class="tabs__header-item">
            Фильтр
          </div>
          <div
            @click="activeTab = tabResult"
            :class="{'active': activeTab === tabResult}"
            class="tabs__header-item">
            Результат
          </div>
          <div
            @click="activeTab = tabClient"
            :class="{'active': activeTab === tabClient}"
            class="tabs__header-item">
            Абонент
          </div>
        </div>

        <div
          v-show="activeTab === tabFilter"
          :class="{'active': activeTab === tabFilter}"
          class="tabs__item">
          <billing-clients-filter @data-complete="filter = $event"/>
        </div>

        <div
          v-if="activeTab === tabResult"
          :class="{'active': activeTab === tabResult}"
          class="tabs__item">
          <billing-clients-filter-result :filter_params="filter"/>
        </div>

        <div
          v-if="activeTab === tabClient"
          :class="{'active': activeTab === tabClient}"
          class="tabs__item">
          <billing-client-detail title="Выберите абонента в результате фильтрации"/>
        </div>
      </div>

      <div>
        <billing-clients-map/>
      </div>
    </div>
  </div>
</template>

<script>
import BillingClientsFilter from "@/components/Billing/BillingFilters/BillingClientsFilter.vue";
import BillingClientsFilterResult from "@/components/Billing/BillingFilters/BillingClientsFilterResult.vue";
import BillingClientsMap from "@/components/Billing/BillingFilters/BillingClientsMap.vue";
import BillingClientDetail from "@/components/Billing/BillingFilters/BillingClientDetail.vue";
import BasePanel from "@/components/BasePanel.vue";

export default {
  name: "BillingClientsPage",
  components: {
    BasePanel,
    BillingClientDetail,
    BillingClientsMap,
    BillingClientsFilterResult,
    BillingClientsFilter
  },
  data() {
    return {
      tabFilter: 0,
      tabResult: 1,
      tabClient: 2,
      client_detail: null,
      detail_disabled: true,
      filter: null
    }
  },
  computed: {
    activeTab: {
      get() {
        return this.$store.getters['client/getTabIndex']
      },
      set(index) {
        this.$store.commit('client/SET_TAB_INDEX', index)
      }
    }
  },
  beforeUnmount() {
    this.$store.commit('client/SET_TAB_INDEX', this.tabFilter);
  }
}
</script>

<style scoped>
</style>
