<template>
  <div class="page-wrapper">
    <div class="telemetry-page">
      <div class="telemetry-page__list">
        <network-objects-filter
          :active-tab="activeTab"
          @change-active-tab="activeTab = $event"
          @toggle-settings-block="sortingBlock = $event"
          @search-query="query = $event"
        />

        <network-objects-list
          v-show="!sortingBlock"
          :active-tab="activeTab"
          :query="query"/>

      </div>

      <div class="telemetry-page__map">
        <telemetry-map
          :active-tab="activeTab"
          @change-active-tab="activeTab = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TelemetryMap from "@/components/Telemetry/TelemetryMap";
import NetworkObjectsFilter from "@/components/Telemetry/NetworkObjectsFilter";
import NetworkObjectsList from "@/components/Telemetry/NetworkObjectsList";

export default {
  name: "TelemetryPage",
  components: {
    NetworkObjectsList,
    NetworkObjectsFilter,
    TelemetryMap
  },
  data() {
    return {
      activeTab: 1,
      timing: null,
      sortingBlock: false,
      query: ''
    }
  },
  mounted() {
    this.$store.dispatch('telemetry/getNetworkObjectsTelemetry');
    this.$store.dispatch('networkObjectTypes/getNetworkObjectTypesList');
    this.$store.dispatch('telemetry/getNetworkEquipments');
    this.getParametersValues();

    if (localStorage.getItem('hiddenInList') == null) {
      localStorage.setItem('hiddenInList', JSON.stringify([]));
    }
    if (localStorage.getItem('hiddenOnMap') == null) {
      localStorage.setItem('hiddenOnMap', JSON.stringify([]));
    }
    if (localStorage.getItem('fixedOnMap') == null) {
      localStorage.setItem('fixedOnMap', JSON.stringify([]));
    }
  },
  beforeUnmount() {
    clearInterval(this.timing);
  },
  methods: {
    getParametersValues() {
      this.$store.dispatch('telemetry/getParametersValues');

      if (!this.timing) {
        this.runTiming();
      }
    },
    runTiming() {
      clearInterval(this.timing);
      this.timing = setInterval(this.getParametersValues, 60000)
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers/index" as *;

.telemetry-page {
  display: flex;
  gap: 24px;

  &__list {
    flex: .35;
  }

  &__map {
    flex: .65;
  }
}

@media (max-width: $mobile-big) {
  .telemetry-page {
    flex-direction: column;

    &__list, &__map {
      flex: 1;
    }
  }
}
</style>