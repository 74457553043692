<template>
  <base-map
    :base-layers="baseLayers"
    :layers="layers"
    :zoom="zoom"
    :center="center"
    :markers="markers"
  />
</template>

<script>
import BaseMap from "@/components/BaseMap.vue";
import {mapState} from "vuex";
import PersonalListNetworkObjectMapMarker
  from "@/components/Telemetry/PersonalLists/PersonalListNetworkObjectMapMarker.vue";
import PersonalListNetworkObjectMapPopup
  from "@/components/Telemetry/PersonalLists/PersonalListNetworkObjectMapPopup.vue";
import Marker from "@/models/baseMap/Marker";
import Icon from "@/models/baseMap/Icon";
import {createVNode, render} from 'vue';

export default {
  name: "PersonalListMap",
  components: {BaseMap},
  props: {
    networkObjects: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      markers: []
    }
  },
  computed: {
    ...mapState({
      baseLayers: state => state.baseLayers.baseLayers,
      layers: state => state.baseMap.layers,
      zoom: state => state.baseMap.zoom,
      center: state => state.baseMap.center,
      parametersValues: state => state.telemetry.parametersValues
    })
  },
  watch: {
    parametersValues: {
      handler() {
        this.createMarkers();
      },
      immediate: true
    }
  },
  methods: {
    createMarkers() {
      this.markers = this.networkObjects.map(object => {
        const iconContainer = document.createElement('div');
        const popupContainer = document.createElement('div');

        const iconVNode = createVNode(PersonalListNetworkObjectMapMarker, {
          typeGroup: object.typeGroupName
        });

        render(iconVNode, iconContainer);
        const iconHtml = iconContainer.innerHTML;

        const popupVNode = createVNode(PersonalListNetworkObjectMapPopup, {
          networkObject: object
        });

        render(popupVNode, popupContainer);
        const popupHtml = popupContainer.innerHTML

        const marker = new Marker({
          coords: object.coordinates,
          icon: new Icon({
            type: 'div_icon',
            size: [36, 42],
            anchor: [18, 21],
            html: iconHtml
          }),
          popup_content: popupHtml
        });

        render(null, iconContainer);
        render(null, popupContainer);

        return marker;
      });
    }
  }
}
</script>

<style scoped>

</style>