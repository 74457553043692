<template>
  <div class="base-card login-card">
    <div class="m-sm-4">
      <div class="project-name">
        {{ projectName }}
      </div>
      <form
        @submit.prevent="login"
        class="login-card__form">

        <div class="form-item">
          <div class="form-item__name">E-mail</div>
          <div class="form-item__value">
            <input
              v-model="username"
              class="base-input base-input--bordered"
              type="email"
              name="email"
              placeholder="Введите ваш e-mail"
              required
            >
          </div>
        </div>

        <div class="form-item">
          <div class="form-item__name">Пароль</div>
          <div class="form-item__value">
            <input
              v-model="password"
              class="base-input base-input--bordered"
              type="password"
              name="password"
              placeholder="Введите пароль"
              required
            >
          </div>
        </div>

        <div class="form-item">
          <label class="base-checkbox base-checkbox--squared">
            <input
              id="remember"
              type="checkbox"
              value="remember-me"
              name="remember-me"
            >
            <span>Запомнить этот компьютер</span>
          </label>
        </div>

        <button
          type="submit"
          class="button button--mode-primary"
          :disabled="loading"
        >
          Авторизоваться
          <base-loader
            v-if="loading"
            color-class="text-light"
          />
        </button>
      </form>
    </div>
    <flash-message :error="error"/>
  </div>
</template>

<script>
import FlashMessage from "@/components/FlashMessage";
import {mapGetters} from "vuex";
import BaseLoader from "@/layout/BaseLoader";

export default {
  name: "LoginPage",
  components: {
    BaseLoader,
    FlashMessage
  },
  data() {
    return {
      username: null,
      password: null,
      error: null
    };
  },
  computed: {
    ...mapGetters({
      projectName: 'projectConfig/getProjectName',
      loading: 'auth/loading'
    })
  },
  methods: {
    async login() {
      const payload = {
        username: this.username,
        password: this.password
      };
      this.error = null;
      try {
        await this.$store.dispatch('auth/login', payload);
        const authUser = await this.$store.dispatch("auth/getAuthUser");
        if (authUser) {
          this.$store.dispatch("auth/setGuest", {value: "isNotGuest"});
          this.$store.dispatch('baseLayers/getBaseLayers');
          this.$store.dispatch("auth/subscribeWSChannels");
          this.$router.push("/");
        } else {
          const error = Error(
            "Не удается получить данные пользователя, проверьте настройки API."
          );
          error.name = "Fetch User";
          throw error;
        }
      } catch (error) {
        this.$store.commit("auth/SET_LOADING", false);
        if (typeof error.response.data === 'string') this.error = 'ошибка API, попробуйте ещё раз.'
        else this.error = error.response.data.errors;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers/index" as *;

.project-name {
  font-weight: 700;
  font-size: 2.15rem;
  padding: 0px 1rem 0.5rem 1rem;
  color: var(--text-primary);
  display: block;
  text-align: center;
}

.login-card {
  min-width: 460px;

  &__form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.form-item {
  & .base-checkbox span {
    gap: 6px;
  }
}

@media (max-width: $mobile-big) {
  .login-card {
    min-width: 0;
    width: 100%;
    margin: 24px;
  }
}
</style>
