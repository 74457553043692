<template>
  <base-popup
    :title="popupTitle"
    :name="popupName"
  >
    <template #body>
      <div class="personal-list-form">
        <div class="personal-list-form__left-side">
          <div class="personal-list-form__filter">
            <base-collapse :default-collapse-state="true">
              <template #toggle="{toggle, isCollapsed}">
                <div
                  @click="toggle()"
                  class="personal-list-form__filter-button"
                  :class="{'personal-list-form__filter-button--collapsed': isCollapsed}"
                >
                  <span>Фильтр</span>
                  <icon-arrow-down/>
                </div>
              </template>

              <template #body>
                <div class="personal-list-form__filter-form">
                  <div class="personal-list-form__filter-form-item">
                    <v-select
                      class="vue-select-input"
                      v-model="filter.category"
                      :options="NetworkObjectTypes"
                      :reduce="type => type.id"
                      :get-option-label="(option) => option.label"
                      placeholder="Категория"
                    />

                    <v-select
                      class="vue-select-input"
                      v-model="filter.type"
                      :options="networkObjectTypesList"
                      :reduce="type => type.id"
                      :get-option-label="(option) => option.name"
                      placeholder="Тип"
                    />
                  </div>

                  <v-select
                    class="vue-select-input"
                    v-model="filter.structuralUnitId"
                    :options="structuralUnitsList"
                    :reduce="unit => unit.id"
                    :get-option-label="(option) => option.name"
                    placeholder="Подразделение"
                  />
                </div>
              </template>
            </base-collapse>
          </div>

          <div class="personal-list-form__objects">
            <div class="personal-list-form__objects-search">
              <input
                v-model="query"
                type="text"
                class="base-input"
                placeholder="Введите название объекта"
              >
            </div>

            <div class="personal-list-form__objects-wrapper">
              <div
                v-for="networkObject in filteredNetworkObjectsList"
                :key="networkObject.id"
                @click="toggleNetworkObject(networkObject.id)"
                class="personal-list-form__objects-item"
              >
                <div class="personal-list-form__objects-item-left">
                  <div class="personal-list-form__objects-item-name">
                    {{ networkObject.name }}
                  </div>
                  <div class="personal-list-form__objects-item-address">
                    {{ networkObject.address }}
                  </div>
                </div>
                <div @click.stop="toggleNetworkObject(networkObject.id)"
                     class="personal-list-form__objects-item-right">
                  <label class="base-checkbox base-checkbox--squared base-checkbox--reset-disabled-styles">
                    <input
                      :checked="isNetworkObjectSelected(networkObject.id)"
                      type="checkbox"
                      disabled
                    >
                    <span/>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="personal-list-form__right-side">
          <div class="form-item">
            <div class="form-item__name">
              Наименование списка
            </div>
            <div class="form-item__value">
              <input
                v-model="payload.name"
                type="text"
                class="base-input base-input--bordered"
                placeholder="Введите наименование"
                maxlength="20"
              >
            </div>
          </div>

          <div
            v-if="payload.object_list.length"
            class="personal-list-form__selected-objects">
            <div class="personal-list-form__selected-objects-header">
              <div class="personal-list-form__selected-objects-title">Выбранные объекты</div>
              <div
                @click="payload.object_list = []"
                class="personal-list-form__selected-objects-delete">Удалить все
              </div>
            </div>

            <div class="personal-list-form__selected-objects-wrapper">
              <div
                v-for="networkObjectId in payload.object_list"
                :key="networkObjectId"
                class="personal-list-form__selected-objects-item">
                <span>{{ networkObjectName(networkObjectId) }}</span>
                <div
                  @click="toggleNetworkObject(networkObjectId)"
                  class="personal-list-form__selected-objects-item-delete">
                  <icon-close />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="personal-list-form__footer">
        <button
          v-if="!isUpdateForm"
          type="button"
          :disabled="isButtonLocked"
          @click="createList()"
          class="button button--mode-primary">Создать</button>
        <button
          v-else
          type="button"
          :disabled="isButtonLocked"
          @click="updateList()"
          class="button button--mode-primary">Изменить</button>
        <div
          @click="$modal.hide(popupName)"
          class="button button--mode-outline">Отменить</div>
      </div>
      <div
        v-if="isUpdateForm"
        class="personal-list-form__delete-list">
        <span @click="deleteList()">Удалить список</span>
      </div>
    </template>
  </base-popup>
</template>

<script>
import BasePopup from "@/components/BasePopup.vue";
import BaseCollapse from "@/components/BaseCollapse.vue";
import IconArrowDown from "@/components/Icons/IconArrowDown.vue";
import NetworkObject from "@/models/telemetry/NetworkObject";
import {mapGetters, mapState} from "vuex";
import IconClose from "@/components/Icons/IconClose.vue";
import {showToast, showAlert, showConfirmationModal} from "@/utils/notification";

export default {
  name: "PersonalListsForm",
  components: {IconClose, IconArrowDown, BaseCollapse, BasePopup},
  props: {
    popupName: {
      type: String,
      required: true
    },
    personalList: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      filter: {
        category: null,
        type: null,
        structuralUnitId: null
      },
      query: '',
      payload: {
        name: '',
        object_list: []
      },
      isButtonLocked: false
    }
  },
  computed: {
    ...mapState({
      networkObjectsList: state => state.networkObjects.networkObjects,
      networkObjectTypesList: state => state.networkObjectTypes.networkObjectTypes,
      structuralUnitsList: state => state.structuralUnits.userStructuralUnitsList
    }),
    ...mapGetters({
      networkObjectName: 'networkObjects/getNetworkObjectName'
    }),
    popupTitle() {
      return this.isUpdateForm ? 'Редактирование списка' : 'Добавить список';
    },
    isUpdateForm() {
      return Object.keys(this.personalList).length;
    },
    isNetworkObjectSelected() {
      return id => {
        return this.payload.object_list.includes(id);
      }
    },
    NetworkObjectTypes: () => NetworkObject.getTypesArray(),
    filteredNetworkObjectsList() {
      let networkObjects = this.networkObjectsList;

      if (this.filter.category) networkObjects = this.filterNetworkObjectsByCategory(networkObjects);
      if (this.filter.type) networkObjects = this.filterNetworkObjectsByType(networkObjects);
      if (this.filter.structuralUnitId) networkObjects = this.filterNetworkObjectsByStructuralUnit(networkObjects);
      if (this.query.length) networkObjects = this.searchNetworkObjects(networkObjects);

      return networkObjects;
    }
  },
  mounted() {
    this.$store.dispatch('networkObjectTypes/getNetworkObjectTypesList');
    this.$store.dispatch('structuralUnits/getUserStructuralUnitsList');

    if (this.isUpdateForm) {
      const {name, object_list} = this.personalList;
      this.payload.name = name;
      this.payload.object_list = object_list;
    }
  },
  methods: {
    createList() {
      this.isButtonLocked = true;
      this.$store.dispatch('telemetryPersonalLists/createPersonalList', this.payload)
        .then(() => {
          this.$store.dispatch('telemetryPersonalLists/getPersonalLists');
          this.$modal.hide(this.popupName);
          showToast('Список создан', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
        .finally(() => this.isButtonLocked = false)
    },
    updateList() {
      this.isButtonLocked = true;
      this.$store.dispatch('telemetryPersonalLists/updatePersonalList', {
        id: this.personalList.id,
        payload: this.payload
      })
        .then(() => {
          this.$store.dispatch('telemetryPersonalLists/getPersonalLists');
          this.$modal.hide(this.popupName);
          showToast('Список обновлен', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
        .finally(() => this.isButtonLocked = false)
    },
    deleteList() {
      showConfirmationModal('Вы уверены, что хотите удалить персональный список? Это действие невозвратно.')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('telemetryPersonalLists/deletePersonalList', this.personalList.id)
              .then(() => {
                this.$store.dispatch('telemetryPersonalLists/getPersonalLists');
                this.$modal.hide(this.popupName);
                showToast('Список удален', 'success');
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    },
    filterNetworkObjectsByCategory(networkObjects) {
      return networkObjects.filter(object => object.type === this.filter.category);
    },
    filterNetworkObjectsByType(networkObjects) {
      return networkObjects.filter(object => object._type.id === this.filter.type);
    },
    filterNetworkObjectsByStructuralUnit(networkObjects) {
      return networkObjects.filter(object => object.structuralUnits
          .some(structuralUnit => structuralUnit.id === this.filter.structuralUnitId));
    },
    searchNetworkObjects(networkObjects) {
      const lowerCaseQuery = this.query.toLowerCase();
      return networkObjects.filter(object => object.address.toLowerCase().includes(lowerCaseQuery)
        || object.name.toLowerCase().includes(lowerCaseQuery));
    },
    toggleNetworkObject(networkObjectId) {
      const index = this.payload.object_list.findIndex(id => id === networkObjectId);
      if (index > -1) this.payload.object_list.splice(index, 1);
      else this.payload.object_list.push(networkObjectId);
    }
  }
}
</script>

<style lang="scss">
@use "@/assets/scss/helpers/index" as *;

.personal-list-form {
  display: flex;

  &__left-side {
    flex: 1;
    padding-right: 22px;
    border-right: 1px solid var(--border-main);
  }

  &__right-side {
    flex: 1;
    padding-left: 22px;
  }

  &__filter {
    border: 1px solid var(--border-main);
    border-radius: 16px;
    padding: 16px 20px;

    &-form {
      display: flex;
      flex-direction: column;
      gap: 20px;

      padding-top: 20px;
    }

    &-form-item {
      display: flex;
      gap: 20px;
    }

    &-button {
      display: flex;
      gap: 4px;
      cursor: pointer;

      @include font-style(600, 16px, 24px, var(--text-primary));

      & svg {
        transform: rotateZ(180deg);

        & path {
          stroke: var(--ico);
        }
      }

      &--collapsed {
        & svg {
          transform: rotateZ(0deg);
        }
      }
    }
  }

  &__objects {
    &-search {
      padding: 24px 24px 16px;
      border-bottom: 1px solid var(--border-main);
    }

    &-wrapper {
      height: 418px;
      overflow-y: auto;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;

      cursor: pointer;
      transition: .3s;
      padding: 8px 24px;

      &-left {
        display: flex;
        flex-direction: column;
      }

      &-name {
        @include font-style(400, 16px, 24px, var(--text-primary));
      }

      &-address {
        @include font-style(400, 16px, 24px, var(--text-secondary));
      }

      &:hover {
        background-color: var(--panel-bg);
      }
    }
  }

  &__selected-objects {
    margin-top: 24px;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 24px;
    }

    &-title {
      @include font-style(600, 16px, 24px, var(--text-primary));
    }

    &-delete {
      cursor: pointer;
      @include font-style(400, 14px, 16px, var(--text-secondary));
    }

    &-wrapper {
      display: flex;
      align-content: flex-start;
      flex-wrap: wrap;
      gap: 12px;

      height: 418px;
      overflow-y: auto;
    }

    &-item {
      display: flex;
      align-items: center;
      gap: 4px;

      border-radius: 8px;
      background-color: var(--bg-accent);
      padding: 4px 12px;

      @include font-style(400, 16px, 24px, var(--text-panel));

      &-delete {
        cursor: pointer;

        & svg {
          & path {
            stroke: var(--ico-panel);
          }
        }
      }
    }
  }

  &__footer {
    display: flex;
    gap: 40px;
  }

  &__delete-list {
    display: flex;
    justify-content: center;

    margin-top: 24px;

    @include font-style(400, 14px, 16px, var(--border-validation));

    & span {
      cursor: pointer;
    }
  }
}
</style>