<template>
  <div class="custom-card">
    <div class="card-body">
      <div class="chart-title">Выполненные заявки
        <base-loader v-if="issuesStatisticLoading" />
      </div>
      <apexchart
        ref="chartComponent"
        type="bar"
        :options="chartOptions"
        :series="chartSeries"
        height="400px"
      />
    </div>
  </div>
</template>

<script>
import BaseLoader from "@/layout/BaseLoader";
import VueApexCharts from "vue3-apexcharts";
import {mapGetters, mapState} from "vuex";
export default {
  name: "CompletedIssues",
  components: {BaseLoader, 'apexchart': VueApexCharts},
  props: {
    timePeriod: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      chartOptions: {},
      chartSeries: []
    }
  },
  computed: {
    ...mapGetters({
      issuesStatistic: 'issuesStatistic/getIssuesStatistic',
      issueTypes: 'issueTypes/getIssueTypes'
    }),
    ...mapState({
      issuesStatisticLoading: state => state.issuesStatisticLoading
    })
  },
  watch: {
    issuesStatistic () {
      this.createChartData();
    }
  },
  mounted() {
    this.createChartData();
  },
  methods: {
    createChartData() {
      this.chartOptions = {
        chart: {
          type: 'bar',
          height: '400px',
          fontFamily: 'Lato',
          foreColor: '#9192AD'
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          }
        },
        colors: ['#6375D4', '#27D8AD', '#1AB9EB'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
            width: 2,
            colors: ['transparent']
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'top',
          fontSize: '12px'
        },
        grid: {
          borderColor: 'rgba(0, 0, 0, 0.20)',
          strokeDashArray: 2,
          position: 'back'
        },
        xaxis: {
          categories: this.createXasis(),
          labels: {
            show: true,
            rotateAlways: true
          }
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toFixed()
            }
          }
        }
      }
      this.createSeries();
    },
    createSeries() {
      this.chartSeries = this.issueTypes.map(item => {
        return {
          name: item.name,
          typeId: item.id,
          data: []
        }
      })

      this.chartSeries.forEach(series => {
        this.issuesStatistic.completed_issues?.forEach(statistic => {
          series.data.push(statistic.values.find(value => value.type === series.typeId).count)
        })
      })
    },
    createXasis() {
      if (this.timePeriod < 31) {
        return this.issuesStatistic.completed_issues?.map(item => item.date)
      }
      return this.issuesStatistic.completed_issues?.map(item => item.date.slice(0, 3));
    }
  }
}
</script>

<style scoped>
.chart-title {
  font-size: 22px;
  font-weight: 600;
  color: var(--text-primary);
}
</style>
