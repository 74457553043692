<template>
  <vue-tree
    class="tree-chart"
    :dataset="createHierarchy"
    :config="treeConfig"
    :collapse-enabled="true"
    linkStyle="straight">
    <template v-slot:node="{ node, collapsed }">
      <div class="tree-chart__node">
        <div class="structural-unit__name">
          <icon-building />
          <span>{{node.name}}</span>
        </div>
        <div class="structural-unit__head-name">{{node.headName}}</div>
        <div class="structural-unit__collapse">
          <icon-plus2 v-if="collapsed" />
          <icon-minus v-else />
        </div>
      </div>
    </template>
  </vue-tree>
</template>

<script>
import VueTree from '@ssthouse/vue3-tree-chart'
import {mapState} from "vuex";
import IconBuilding from "@/components/Icons/IconBuilding.vue";
import IconMinus from "@/components/Icons/IconMinus.vue";
import IconPlus2 from "@/components/Icons/IconPlus2.vue";

export default {
  name: "StructuralUnitsFlowChart",
  components: {IconPlus2, IconMinus, IconBuilding, VueTree},
  data() {
    return {
      treeConfig: {
        nodeWidth: 185,
        nodeHeight: 85,
        levelHeight: 200
      }
    }
  },
  computed: {
    ...mapState({
      structuralUnits: state => state.structuralUnits.structuralUnits
    }),
    createHierarchy() {
      const tree = [];
      const childOf = {};
      const nodesArr = JSON.parse(JSON.stringify(this.structuralUnits))
      nodesArr.forEach(node => {
        const { id, parentId } = node;
        childOf[id] = childOf[id] || [];
        node.children = childOf[id];
        if (parentId) {
          (childOf[parentId] = childOf[parentId] || []).push(node)
        } else tree.push(node)
      });
      return tree;
    }
  }
}
</script>

<style lang="scss">
@use "@/assets/scss/helpers/index" as *;

.structural-unit {
  &__name {
    display: flex;
    align-items: center;
    gap: 8px;

    @include font-style(400, 14px, 16px, var(--text-primary));

    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid var(--border-main);
  }

  &__head-name {
    @include font-style(400, 14px, 16px, var(--text-primary));
  }

  &__collapse {
    width: 16px;
    height: 16px;

    position: absolute;
    bottom: -8px;
    left: calc(50% - 8px);

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 4px;

    border-radius: 4px;
    background-color: var(--border-main);

    & svg {
      width: 8px;
      height: 8px;

      & path {
        stroke: var(--ico-panel);
      }
    }
  }
}
</style>