<template>
  <div class="users-list-filter">
    <div class="filter__button"
         :class="{'opened': isFilterModalVisible}"
         @click="isFilterModalVisible = true">
      <icon-settings2/>
      <span>Фильтры</span>
      <div
        v-if="selectedFiltersCount > 0"
        class="filter__button-indicator"
      >
        {{selectedFiltersCount}}
      </div>
    </div>

    <base-filter-modal
      :is-visible="isFilterModalVisible"
      @toggle-modal="isVisible => this.isFilterModalVisible = isVisible">
      <div class="filter__modal-header">
        <div class="filter__modal-name">Фильтры</div>
        <div @click="resetFilters()"
             class="filter__modal-reset">Сбросить все
        </div>
      </div>
      <div class="filter__modal-form">
        <div>
          <label class="input-label">Должность</label>
          <input
            v-model="filter.position"
            class="base-input base-input--bordered"
            type="text"
            placeholder="Введите должность"
          >
        </div>
        <div>
          <label class="input-label">Подразделение</label>
          <v-select
            class="vue-select-input"
            v-model="filter.structural_unit_id"
            :options="structuralUnits"
            :reduce="unit => unit.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите из списка"
          />
        </div>
        <div>
          <label class="base-switcher">
            <input
              v-model="filter.state"
              true-value="active"
              :false-value="null"
              class="base-switcher__checkbox"
              type="checkbox"
            >
            <div class="base-switcher__switch"/>
            <span class="base-switcher__label">
              Только активные
            </span>
          </label>
        </div>
      </div>
      <div class="filter__modal-buttons">
        <div @click="submitFilter()"
             class="button">Применить
        </div>
        <div @click="isFilterModalVisible = false"
             class="button button--mode-outline">Закрыть
        </div>
      </div>
    </base-filter-modal>
  </div>
</template>

<script lang="js">
import {defineComponent} from 'vue'
import IconSettings2 from "@/components/Icons/IconSettings2.vue";
import BaseFilterModal from "@/components/BaseFilterModal.vue";
import {mapState} from "vuex";

export default defineComponent({
  name: "UsersListFilter",
  components: {BaseFilterModal, IconSettings2},
  data() {
    return {
      isFilterModalVisible: false,
      filter: {
        position: null,
        structural_unit_id: null,
        state: null
      }
    }
  },
  computed: {
    ...mapState({
      structuralUnits: state => state.structuralUnits.structuralUnits
    }),
    selectedFiltersCount() {
      return Object.values(this.filter).reduce((count, value) => {
        return count + (value ? 1 : 0);
      }, 0);
    }
  },
  methods: {
    submitFilter() {
      this.$store.commit('user/SET_FILTER_PARAMS', {...this.filter});
      this.$store.dispatch('user/getUsers');
      this.isFilterModalVisible = false;
    },
    resetFilters() {
      this.filter = {
        position: null,
        structural_unit_id: null,
        state: null
      };

      this.$store.commit('user/SET_FILTER_PARAMS', {...this.filter});
      this.$store.dispatch('user/getUsers');
    }
  }
})
</script>

<style scoped>
.users-list-filter {
  position: relative;
}
</style>
