<template>
  <div class="i-modal__content-wrapper">
    <div class="i-modal__content">
      <div class="i-modal__inner">
        <div class="base-modal__left-side">
          <div class="base-modal-header">
            <span
              v-if="!Object.keys(this.appeal).length"
              class="base-modal-title">Новое обращение</span>
            <span
              v-else
              class="base-modal-title">Редактирование обращения</span>
          </div>
          <div class="base-modal-info">
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-call/>
                <span>От куда поступило</span>
              </div>
              <div class="base-modal-info__value">
                <div class="tags-select">
                  <div v-for="source in SourceType.sourceTypesList"
                       :key="source.id"
                       @click="payload.source = source.id"
                       class="tags-select__item"
                       :class="{
                         'selected': payload.source === source.id,
                         'tags-select__item--error': v$.payload.source.$error
                       }">
                    {{ source.label }}
                  </div>
                </div>
              </div>
            </div>
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-profile/>
                <span>Кто обратился</span>
              </div>
              <div class="base-modal-info__value base-modal-info__values-list">
                <base-suggestions
                  v-model="v$.payload.applicant_name.$model"
                  :validation-error="v$.payload.applicant_name.$error"
                  type="fio"
                  placeholder="Введите ФИО"
                />
                <input
                  v-mask="[phoneMask]"
                  type="text"
                  v-model="v$.payload.applicant_phone.$model"
                  class="base-input base-input--bordered"
                  :class="{'base-input--error': v$.payload.applicant_phone.$error}"
                  placeholder="Введите телефон"
                >
                <v-select
                  class="vue-select-input"
                  :class="{'vue-select-input--error': v$.payload.applicant_type.$error}"
                  v-model="payload.applicant_type"
                  :options="ApplicantType.applicantTypesList"
                  :reduce="type => type.id"
                  :get-option-label="(option) => option.label"
                  placeholder="Выберите из списка"/>
              </div>
            </div>
            <div
              v-if="!Object.keys(this.appeal).length"
              class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-link/>
                <span>Связанная заявка</span>
              </div>
              <div class="base-modal-info__value">
                <v-select
                  class="vue-select-input"
                  v-model="linked_issue_id"
                  :options="issuesList"
                  :reduce="issue => issue.id"
                  :get-option-label="(option) => option.id"
                  placeholder="Выберите из списка"
                >
                  <template #option="option">
                    <div class="issue-select-option">
                      <div class="issue-select-option__id">
                        {{ option.id }}
                      </div>
                      <div class="issue-select-option__address">
                        {{ option.address.street }}, {{ option.address.house }}
                      </div>
                      <div class="issue-select-option__time">
                        {{ moment(option.created_at).fromNow() }}
                      </div>
                      <div class="issue-select-option__checkbox">
                        <label class="base-checkbox base-checkbox--squared">
                          <input
                            @click="linked_issue_id = option.id"
                            :checked="linked_issue_id === option.id"
                            type="radio">
                          <span/>
                        </label>
                      </div>
                    </div>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-info-circle/>
                <span>Проблема</span>
              </div>
              <div class="base-modal-info__value base-modal-info__values-list">
                <problem-category-selector
                  :category_id="payload.category_id"
                  :group="group_id"
                  :validation="v$.payload.category_id.$error"
                  @update:category_id="categoryId => payload.category_id = categoryId"
                  @update:group_id="groupId => group_id = groupId"/>

                <textarea
                  type="text"
                  v-model="v$.payload.appeal_message.$model"
                  class="base-textarea"
                  :class="{'base-textarea--error': v$.payload.appeal_message.$error}"
                  placeholder="Опишите проблему"
                />
              </div>
            </div>
            <div
              v-if="!Object.keys(this.appeal).length"
              class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-pin/>
                <span>Подразделение</span>
              </div>
              <div class="base-modal-info__value">
                <v-select
                  class="vue-select-input"
                  v-model="payload.structural_unit_id"
                  :options="structuralUnitsList"
                  :reduce="unit => unit.id"
                  :get-option-label="(option) => option.name"
                  placeholder="Выберите из списка"/>
              </div>
            </div>
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-pin/>
                <span>Адрес</span>
              </div>
              <div class="base-modal-info__value">
                <base-suggestions
                  v-model="address"
                  type="address"
                  placeholder="Введите адрес"
                  @change="setAddress"
                />
              </div>
            </div>
            <manual-address-form
              :address_flat="payload.address_flat"
              :address_block="payload.address_block"
              :address_house="payload.address_house"
              :address_street="payload.address_street"
              :address_settlement="payload.address_settlement"
              :address_city="payload.address_city"
              :address_region="payload.address_region"
              @update:address_flat="value => payload.address_flat = value"
              @update:address_block="value => payload.address_block = value"
              @update:address_house="value => payload.address_house = value"
              @update:address_street="value => payload.address_street = value"
              @update:address_settlement="value => payload.address_settlement = value"
              @update:address_city="value => payload.address_city = value"
              @update:address_region="value => payload.address_region = value"
            />
            <div class="base-modal-info__item--border-bottom create-appeal-map">
              <create-appeal-map
                :marker-coords="markerCoords"
                @update:coords="({lat, lng}) => {
                  payload.lat = lat;
                  payload.lon = lng;
                }"/>

              <div v-if="v$.payload.lat.$error"
                   class="error-message">
                Не указаны координаты, поставьте метку на карте
              </div>
            </div>
            <div class="base-modal-btn-wp create-appeal-buttons">
              <button
                @click="checkFormType()"
                :disabled="isSubmitButtonDisabled"
                class="button button--mode-primary">
                <span>Сохранить</span>
              </button>
              <div @click="cancelAppealCreate()"
                   class="button button--mode-outline">
                <span>Закрыть</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="i-modal-btn-wp i-modal-btn-wp--back">
      <div class="task-modal__mobile-actions">
        <button @click="$store.dispatch('baseSideModal/closeModal')"
                class="button button--mode-outline task-modal__mobile-back">
          <icon-arrow-left/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IconLink from "@/components/Icons/IconLink.vue";
import ProblemCategorySelector from "@/components/ServiceDesk/Issues/ProblemCategorySelector.vue";
import IconArrowLeft from "@/components/Icons/IconArrowLeft.vue";
import IconPin from "@/components/Icons/IconPin.vue";
import IconInfoCircle from "@/components/Icons/IconInfoCircle.vue";
import IconCall from "@/components/Icons/IconCall.vue";
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Inputmask from "bitweb-inputmask";
import IconProfile from "@/components/Icons/IconProfile.vue";
import ApplicantType from "@/models/callCenter/ApplicantType";
import IssueState from "@/models/serviceDesk/IssueState";
import {mapGetters, mapState} from "vuex";
import CreateAppealMap from "@/components/CallCenter/CreateAppealMap.vue";
import AppealSourceType from "@/models/callCenter/AppealSourceType";
import {showAlert, showToast} from "@/utils/notification";
import ManualAddressForm from "@/components/ServiceDesk/ManualAddressForm.vue";
import BaseSuggestions from "@/components/BaseSuggestions.vue";
import moment from "moment";
import emitter from "@/utils/emitter";

export default {
  name: "CreateAppealModalContent",
  components: {
    BaseSuggestions,
    ManualAddressForm,
    CreateAppealMap,
    IconProfile,
    IconCall,
    IconInfoCircle,
    IconPin,
    IconArrowLeft,
    ProblemCategorySelector,
    IconLink
  },
  directives: {
    mask: {
      mounted(el, binding) {
        Inputmask(binding.value).mask(el);
      },
      updated(el, binding) {
        Inputmask(binding.value).mask(el);
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      payload: {
        applicant_type: null,
        applicant_name: null,
        applicant_phone: null,
        source: 2,
        appeal_message: null,
        category_id: null,
        address_region: null,
        address_city: null,
        address_settlement: null,
        address_street: null,
        address_house: null,
        address_block: null,
        address_flat: null,
        lat: null,
        lon: null,
        structural_unit_id: null
      },
      linked_issue_id: null,
      group_id: null,
      address: null,
      isSubmitButtonDisabled: false,
      markerCoords: [0, 0]
    }
  },
  validations() {
    return {
      payload: {
        applicant_type: {required},
        applicant_name: {required},
        applicant_phone: {required},
        source: {required},
        appeal_message: {required},
        category_id: {required},
        lat: {required},
        lon: {required}
      }
    }
  },
  computed: {
    ApplicantType: () => ApplicantType,
    SourceType: () => AppealSourceType,
    ...mapState({
      issuesList: state => state.issues.allIssues,
      structuralUnitsList: state => state.structuralUnits.structuralUnits,
      appeal: state => state.baseSideModal.componentPropsData
    }),
    ...mapGetters({
      phoneMask: 'projectConfig/getProjectPhoneMask'
    })
  },
  mounted() {
    this.$store.dispatch('issues/getAllIssues', [
      IssueState.NEW,
      IssueState.IN_WORK
    ].join(','));
    this.$store.dispatch('structuralUnits/getStructuralUnitsList');

    if (Object.keys(this.appeal).length) {
      const {applicant, source, message, category, address, coordinates} = this.appeal;
      this.payload.applicant_type = applicant.type;
      this.payload.applicant_name = applicant.name;
      this.payload.applicant_phone = applicant.phone;
      this.payload.source = source;
      this.payload.appeal_message = message;
      this.payload.category_id = category.id;
      this.payload.address_region = address.region;
      this.payload.address_city = address.city;
      this.payload.address_settlement = address.settlement;
      this.payload.address_street = address.street;
      this.payload.address_house = address.house;
      this.payload.address_block = address.block;
      this.payload.address_flat = address.flat;
      const [lat, lon] = coordinates;
      this.payload.lat = lat;
      this.payload.lon = lon;

      this.markerCoords = coordinates;
      this.$store.commit('baseMap/SET_CENTER_ZOOM', {
        center: coordinates,
        zoom: 18
      });

      this.group_id = category.group_id;
      this.address = this.appeal.getFullAddress();
    }
  },
  methods: {
    moment,
    setAddress(i) {
      if (i.data) {
        this.payload.address_region = i.data.region;
        this.payload.address_city = i.data.city;
        this.payload.address_settlement = i.data.settlement_with_type;
        this.payload.address_street = i.data.street_with_type;
        this.payload.address_house = i.data.house;
        this.payload.address_block = i.data.block;
        this.payload.address_flat = i.data.flat;

        this.payload.lat = parseFloat(i.data.geo_lat);
        this.payload.lon = parseFloat(i.data.geo_lon);

        emitter.$emit('fit-bounds-map', {
          coords: [parseFloat(i.data.geo_lat), parseFloat(i.data.geo_lon)],
          qc_geo: i.data.qc_geo
        })
      }
    },
    cancelAppealCreate() {
      this.v$.$reset();
      this.$store.dispatch('baseSideModal/goBack');

      this.group_id = null;
      this.address = null;
      this.payload = {
        applicant_type: null,
        applicant_name: null,
        applicant_phone: null,
        source: 2,
        appeal_message: null,
        category_id: null,
        address_region: null,
        address_city: null,
        address_settlement: null,
        address_street: null,
        address_house: null,
        address_block: null,
        address_flat: null,
        lat: null,
        lon: null
      }
    },
    checkFormType() {
      this.v$.$touch()
      if (this.v$.$invalid) return false

      if (!Object.keys(this.appeal).length) this.createAppeal()
      else this.updateAppeal()
    },
    createAppeal() {
      this.isSubmitButtonDisabled = true
      if (this.v$.$invalid) {
        this.v$.$touch();
        this.isSubmitButtonDisabled = false;
      } else {
        this.v$.$reset();
        this.$store.dispatch('appeals/createAppeal', this.payload)
          .then(id => {
            showToast('Обращение зарегистрировано', 'success');
            if (this.linked_issue_id !== null) {
              this.$store.dispatch('appeals/linkAppealToIssue', {
                appeal_id: id,
                params: {
                  issue_id: this.linked_issue_id
                }
              });
            }

            this.$store.dispatch('appeals/getAppeals', 1);
            this.$store.dispatch('baseSideModal/goBack');
            this.isSubmitButtonDisabled = false;

            this.payload = {
              applicant_type: null,
              applicant_name: null,
              applicant_phone: null,
              source: 2,
              appeal_message: null,
              category_id: null,
              address_region: null,
              address_city: null,
              address_settlement: null,
              address_street: null,
              address_house: null,
              address_block: null,
              address_flat: null,
              lat: null,
              lon: null
            }
          })
          .catch(() => {
            showAlert('Ошибка', this.$store.state.appeals.error, 'error');
            this.isSubmitButtonDisabled = false;
          })
      }
    },
    updateAppeal() {
      delete this.payload.structural_unit_id
      this.isSubmitButtonDisabled = true;

      this.$store.dispatch('appeals/updateAppeal', {
        appealId: this.appeal.id,
        payload: this.payload
      })
        .then(() => {
          showToast('Обращение обновлено', 'success');
          this.$store.dispatch('baseSideModal/goBack');
        })
        .catch(error => showAlert('Ошибка', error))
        .finally(() => this.isSubmitButtonDisabled = false)
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers/index" as *;

.issue-select-option {
  padding: 0 !important;
}

.create-appeal-buttons {
  display: flex;
  gap: 12px;

  & .button {
    flex: 1;
  }
}

.base-modal-info__item {
  align-items: baseline;
}

.create-appeal-map {
  margin-top: 20px;
}
</style>
