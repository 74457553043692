<template>
  <div class="question-preview">
    <div class="question-preview__inner">
      <div class="question-preview__title">Пример</div>
      <div class="question-preview__question">{{ question }}</div>
      <div class="question-preview__input">
        <input
          v-if="answerType === questionModel.ANSWER_TEXT.id"
          type="text"
          class="base-input base-input--bordered"
          placeholder="Введите значение"
        >

        <input
          v-else-if="answerType === questionModel.ANSWER_NUM.id"
          type="number"
          class="base-input base-input--bordered"
          placeholder="Введите значение"
        >

        <v-select
          v-else-if="answerType === questionModel.ANSWER_DICTIONARY.id"
          :options="dictionary"
          :reduce="item => item.id"
          :get-option-label="(option) => option.name"
          placeholder="Выберите значение"
          class="vue-select-input"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Question from "@/models/TechnicalInspection/Question";

export default {
  name: "QuestionPreview",
  props: {
    question: {
      type: String,
      required: true
    },
    answerType: {
      type: Number,
      required: true
    },
    dictionary: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    questionModel() {
      return Question;
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers/index" as *;

.question-preview {
  padding-top: 32px;
  border-top: 1px solid var(--border-main);

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 16px;

    padding: 16px;
    border-radius: 16px;
    border: 1px solid var(--border-main);
    background-color: var(--panel-bg);
  }

  &__title {
    @include font-style(600, 16px, 24px, var(--text-link));
  }

  &__question {
    @include font-style(600, 16px, 24px, var(--text-primary));
  }
}
</style>