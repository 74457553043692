<template>
  <apexchart
    :options="chartOptions"
    :series="chartData"
    height="350"
    type="bar"
  />
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import moment from "moment";

export default {
  name: "BillingClientReadingsModalContent",
  components: {'apexchart': VueApexCharts},
  data() {
    return {
      chartOptions: null,
      chartData: []
    }
  },
  mounted() {
    this.generateChartData();
  },
  methods: {
    generateChartData() {
      let ru = require('apexcharts/dist/locales/ru.json')
      let months = []

      this.$store.getters["client/getClientReadings"].forEach(el => {
        months.push(moment(el.period).format('MMMM-Y'))
      })

      this.chartOptions = {
        xaxis: {
          categories: [].concat(months).reverse()
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '80%',
            endingShape: 'rounded',
            borderRadius: 5
          }
        },
        fill: {
          opacity: 1,
          colors: ['#0A6187', '#f0ad4e']
        },
        legend: {
          show: true,
          markers: {
            fillColors: ['#0A6187', '#f0ad4e']
          }
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " м<sup>3</sup>"
            }
          }
        },
        chart: {
          locales: [ru],
          defaultLocale: 'ru'
        }
      }

      let hw = []
      let drains = []

      let readings = [].concat(this.$store.getters["client/getClientReadings"]).reverse()
      readings.forEach(el => {
        hw.push(el.hw)
        drains.push(el.shw + el.sgw)
      })
      this.chartData = [
        {
          name: `ХВС, м<sup>3</sup>`,
          data: hw
        },
        {
          name: `Стоки, м<sup>3</sup>`,
          data: drains
        }]
    }
  }
}
</script>

<style scoped>

</style>