<template>
  <div class="page-wrapper">
    <base-panel
      title="Протяженность сетей"
      :is-add-button-visible="false"/>

    <div class="networks-length-filter">
      <base-date-picker
        v-model="selectedYear"
        :year-picker="true"
        format="yyyy"
        value-format="yyyy"
        placeholder="Выберите период"
        @update:modelValue="getNetworksLength()"
      />
    </div>

    <engineering-networks-length-list
      :engineering-networks-list="engineeringNetworksList"
      :selected-year="selectedYear" />
  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel.vue";
import EngineeringNetworksLengthList from "@/components/EngineeringNetworks/EngineeringNetworksLengthList.vue";
import {mapState} from "vuex";
import BaseDatePicker from "@/components/BaseDatePicker.vue";

export default {
  name: "EngineeringNetworksLengthPage",
  components: {BaseDatePicker, EngineeringNetworksLengthList, BasePanel},
  data() {
    return {
      selectedYear: new Date().getFullYear().toString()
    }
  },
  computed: {
    ...mapState({
      engineeringNetworksList: state => state.engineeringNetworks.engineeringNetworks
    })
  },
  mounted() {
    this.getNetworksLength();
    this.$store.dispatch('engineeringNetworks/getAllEngineeringNetworks');
  },
  methods: {
    getNetworksLength() {
      this.$store.dispatch('networksLength/getNetworksLength', {
        year_date: this.selectedYear
      });
    }
  }
}
</script>

<style scoped>
.networks-length-filter {
  width: 320px;
  margin-bottom: 32px;
}
</style>
