<template>
  <div class="base-table">
    <div class="base-table__container">
      <div class="base-table__header base-table__column--engineering-network">
        <div class="base-table__header-cell">ID</div>
        <div class="base-table__header-cell">Наименование</div>
        <div class="base-table__header-cell">Тип</div>
        <div class="base-table__header-cell">Статус</div>
        <div class="base-table__header-cell"/>
      </div>

      <div v-for="engineeringNetwork in engineeringNetworksList"
           :key="engineeringNetwork.id"
           class="base-table__row base-table__column--engineering-network">
        <div class="base-table__column">
          <div class="base-table__text">{{ engineeringNetwork.id }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ engineeringNetwork.name }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ engineeringNetwork.typeName }}</div>
        </div>
        <div class="base-table__column">
          <div class="engineering-network-toggle">
            <v-select
              class="vue-select-input vue-select-input-borderless vue-select-input-borderless--arrow-right"
              :value="engineeringNetwork.active"
              :options="engineeringNetworkStates"
              :reduce="state => state.id"
              :get-option-label="(option) => option.label"
              placeholder="Выберите из списка"
              @update:modelValue="$emit('switch-engineering-network-state', {
                networkId: engineeringNetwork.id,
                event: $event
              })"
            />
          </div>
        </div>
        <div class="base-table__column base-table__column--content-right">
          <div class="base-table__short-info">
            <div @click="$emit('update-engineering-network', engineeringNetwork)">
              <icon-pencil class="base-table__short-info--p-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconPencil from "@/components/Icons/IconPencil.vue";

export default {
  name: "EngineeringNetworksList",
  components: {IconPencil},
  props: {
    engineeringNetworksList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      engineeringNetworkStates: [
        {id: 0, label: 'Не активна'},
        {id: 1, label: 'Активна'}
      ]
    }
  }
}
</script>

<style scoped>
.base-table__column--engineering-network {
  grid-template-columns: 1fr 4fr 4fr 2fr 1fr;
}

.engineering-network-toggle {
  max-width: 160px;
}
</style>
