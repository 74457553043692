<template>
  <div>
    <base-functional-line
      @search-change="event => this.$store.commit('reportsData/SET_SEARCH_QUERY', event.target.value)">
      <div class="functional-line__view-buttons">
        <div
          @click="isListView = true"
          :class="{'active': isListView}">
          <icon-hamburger-menu/>
        </div>
        <div
          @click="isListView = false"
          :class="{'active': !isListView}">
          <icon-map/>
        </div>
      </div>
    </base-functional-line>

    <div v-if="isListViewContent"
         class="table-controls">
      <span class="table-controls__title">
        Сортировать
      </span>
      <div @click="sortEmergencyElements"
           class="table-controls__control">
        <span>
          по кол-ву инцидентов
        </span>
        <icon-arrow-linear-down
          :class="{'toggle': !isSortByIncrease}"/>
      </div>
    </div>
    <div v-if="isListView">
      <div v-if="reportData.length && !loading"
           class="base-table__container"
           id="emergency-elements-table">
        <div class="base-table__header base-table__header--emergency-elements">
          <div class="base-table__header-cell">ID</div>
          <div class="base-table__header-cell">Сетевой элемент</div>
          <div @click="sortEmergencyElements"
               class="base-table__header-cell base-table__header-cell-sort"
               :class="{'toggled': !isSortByIncrease}">
            Завершенные инциденты
            <icon-sort />
          </div>
        </div>

        <div v-for="element in reportData"
             :key="element.date"
             @click="openModal(element)"
             class="base-table__row base-table__row--emergency-elements" >
          <div class="base-table__column">
            <div class="base-table__num">{{ element.id }}</div>
          </div>
          <div class="base-table__column base-table__column--emergency-elements">
            <div class="base-table__text">{{ element.networkElementId }}</div>
          </div>
          <div class="base-table__column">
            <div class="base-table__text">{{ element.countIncidents }}</div>
          </div>
        </div>
      </div>
    </div>

    <div v-else
         class="emergency-elements-map reset-leaflet-popup-styles">
      <emergency-elements-report-map
        :report-data="reportData"
        @open-modal="openModal"
      />
    </div>

    <network-element-modal
      :is-visible-mode-switcher="false"
      :network-element="selectedElement"
      :is-modal-active="isModalActive"
      :related-issues="relatedIssuesList"
      @close-modal="closeModal()" />

  </div>
</template>

<script>
import BaseFunctionalLine from "@/components/BaseFunctionalLine.vue";
import IconMap from "@/components/Icons/IconMap.vue";
import IconHamburgerMenu from "@/components/Icons/IconHamburgerMenu.vue";
import {mapGetters, mapState} from "vuex";
import EmergencyElementsReportMap
  from "@/components/ServiceDesk/ReportsPreviews/EmergencyElementsReport/EmergencyElementsReportMap.vue";
import NetworkElementModal from "@/components/ServiceDesk/OperationalScheme/NetworkElementModal.vue";
import IssueState from "@/models/serviceDesk/IssueState";
import IconSort from "@/components/Icons/IconSort.vue";
import IconArrowLinearDown from "@/components/Icons/IconArrowLinearDown.vue";

export default {
  name: "EmergencyElementsReport",
  components: {
    IconArrowLinearDown,
    IconSort, NetworkElementModal, EmergencyElementsReportMap, IconHamburgerMenu, IconMap, BaseFunctionalLine
},
  data() {
    return {
      isListView: true,
      isModalActive: false,
      selectedElement: {},
      isSortByIncrease: false
    }
  },
  computed: {
    ...mapGetters({
      reportData: 'reportsData/getEmergencyElementsByQuery'
    }),
    ...mapState({
      relatedIssuesList: state => state.issues.allIssues,
      loading: state => state.reportsData.loading
    }),
    isListViewContent() {
      return this.isListView && this.reportData.length && !this.loading
    }
  },
  methods: {
    openModal(element) {
      this.$store.dispatch('issues/getAllIssues', {
        issue_states: [IssueState.CLOSED, IssueState.CANCELED].join(),
        semantic_source_id: element.semanticSource.id,
        network_element_id: element.networkElementId
      });
      this.selectedElement = element;
      this.isModalActive = true;
    },
    sortEmergencyElements() {
      this.toggleSort();
      this.$store.dispatch('reportsData/sortEmergencyElements', this.isSortByIncrease);
    },
    closeModal() {
      this.selectedElement = {};
      this.isModalActive = false;
    },
    toggleSort() {
      this.isSortByIncrease = !this.isSortByIncrease;
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers/index" as *;

.table-controls {
  display: none;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0;

  @media (max-width: $mobile) {
    display: flex;
  }

  &__title {
    color: var(--text-secondary);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  &__control {
    font-size: 16px;
    cursor: pointer;

    svg {
      &.toggle {
        transform: rotate(180deg);
      }
    }
  }
}

.base-table__header {
  @media (max-width: $tablet) {
    display: grid;
  }

  @media (max-width: $mobile-big) {
    display: none;
  }
}

.base-table__row--issue {

}


.base-table--emergency-elements {
  & .base-table__header, .base-table__row {
    grid-template-columns: 4.74% 46.07% 1fr;

    @media (max-width: $mobile-big) {
      grid-template-columns: 13.74% 66.07% 1fr;
      gap: 8px;
    }
  }
}

.functional-line__view-buttons {
  & div:last-child {
    @media (max-width: $mobile-big) {
      display: none;
    }
  }
}

.emergency-elements-map {
  height: calc(100vh - 420px);
}
</style>
