import * as API from './API';
import {getError} from "@/utils/helpers";

export default {
    fetchResourcesStatistic(params) {
        return new Promise((resolve, reject) => {
            API.apiClient.get('/technical-inspection/stat/resources', {params})
                .then(response => resolve(response.data.data))
                .catch(error => reject(getError(error)))
        })
    },
    fetchObjectsStatistic(params) {
        return new Promise((resolve, reject) => {
            API.apiClient.get('/technical-inspection/stat/objects', {params})
                .then(response => resolve(response.data.data))
                .catch(error => reject(getError(error)))
        })
    }
}
