<template>
  <div class="appeals-map reset-leaflet-popup-styles">
    <base-map
      :center="center"
      :zoom="zoom"
      :base-layers="baseLayers"
      :layers="layers"
      :markers="createMarkers()"
    />
  </div>
</template>

<script>
import BaseMap from "@/components/BaseMap";
import {mapState} from "vuex";
import {createVNode, render} from 'vue';
import AppealMapIcon from "@/components/CallCenter/AppealMapIcon.vue";
import AppealMapPopupContent from "@/components/CallCenter/AppealMapPopupContent.vue";
import Marker from "@/models/baseMap/Marker";
import Icon from "@/models/baseMap/Icon";

export default {
  name: "AppealsListMap",
  components: {BaseMap},
  props: {
    appealsList: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState({
      center: state => state.baseMap.center,
      zoom: state => state.baseMap.zoom,
      layers: state => state.baseMap.layers,
      baseLayers: state => state.baseLayers.baseLayers
    })
  },
  mounted() {
    this.$store.dispatch('baseMap/getLayers');
  },
  beforeUnmount() {
    this.$store.commit("baseMap/SET_LAYERS", []);
  },
  methods: {
    createMarkers() {
      return this.appealsList.map(appeal => {
        const iconContainer = document.createElement('div');
        const popupContainer = document.createElement('div');

        const iconVNode = createVNode(AppealMapIcon, {appeal})
        render(iconVNode, iconContainer);
        const iconHtml = iconContainer.innerHTML;

        const popupVNode = createVNode(AppealMapPopupContent, {appeal})
        render(popupVNode, popupContainer);

        const marker = new Marker({
          coords: appeal.coordinates,
          accumulation: true,
          icon: new Icon({
            type: 'div_icon',
            size: [36, 42],
            anchor: [18, 21],
            html: iconHtml
          }),
          popup_content: popupContainer
        })

        render(null, iconContainer);

        return marker;
      })
    }
  }
}
</script>

<style scoped>
.appeals-map {
  height: calc(100vh - 280px);
  position: relative;
  z-index: 9;
}
</style>
