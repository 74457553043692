<template>
  <div class="page-wrapper">
    <base-panel
      title="Месячный отчет загрузки транспорта"
      :is-add-button-visible="false"
    />

    <div class="transport-report-filter">
      <div class="transport-report-filter__form">
        <base-date-picker
          v-model="filter.report_date"
          placeholder="Выберите дату"
          month-picker
          format="MM yyyy"
          @update:modelValue="getReportData()"
        />

        <v-select
          class="vue-select-input"
          v-model="filter.transport_type"
          :options="transportTypes"
          placeholder="Выберите тип транспорта"
          :reduce="transport => transport.id"
          :get-option-label="(option) => option.name"
          @update:modelValue="getReportData()"
        />
      </div>

      <div class="transport-report-filter__export">
        <button
          @click="processExcelData()"
          class="button button--mode-outline">
          <icon-upload/>
          <span>
            Экспорт
          </span>
        </button>
      </div>
    </div>

    <div
      v-if="reportData.length"
      class="base-table"
    >
      <div class="base-table__container">
        <div
          class="base-table__header"
          :style="columnsStyle">
          <div class="base-table__header-cell vehicle-load-name">Наименование ТС</div>
          <div
            v-for="day in daysCount"
            :key="`${day}_header`"
            class="base-table__header-cell base-table__header-cell--center">
            {{ day }}
          </div>
          <div class="base-table__header-cell">Итого</div>
        </div>

        <div v-for="vehicle in reportData"
             :key="vehicle.id"
             class="base-table__row"
             :style="columnsStyle">
          <div class="base-table__column base-table__column--emergency-elements vehicle-load-name">
            <div class="base-table__text">{{ vehicle.attributes.name }}</div>
            <div class="base-table__text--secondary">{{ vehicle.attributes.gov_number }}</div>
          </div>
          <div
            v-for="day in daysCount"
            :key="`${day}_table`"
            class="base-table__column base-table__column--content-center">
            <div
              v-if="getDayLoad(vehicle.attributes.works_days, day)"
              class="base-table__text base-table__text--center base-table__num">
              {{ getDayLoad(vehicle.attributes.works_days, day) }}
            </div>
            <div
              v-else
              class="base-table__text base-table__text--center">
              —
            </div>
          </div>
          <div class="base-table__column">
            <div class="base-table__text">{{ getSumVehicleLoad(vehicle.attributes.works_days) }} ч.</div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="no-result">
      <img
        src="@/assets/img/no-result-2.png"
        alt="не сформирован отчёт">
      <div class="no-result__text">
        Сформируйте запрос, чтобы получить данные
      </div>
    </div>
  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel.vue";
import Vehicle from "@/models/transport/Vehicle";
import {mapState} from "vuex";
import IconUpload from "@/components/Icons/IconUpload.vue";
import {getExcel} from "@/utils/table";
import moment from "moment";
import BaseDatePicker from "@/components/BaseDatePicker.vue";

export default {
  name: "TransportLoadReportPage",
  components: {BaseDatePicker, IconUpload, BasePanel},
  data() {
    return {
      filter: {
        report_date: moment().format('YYYY-MM-DD'),
        transport_type: null
      }
    }
  },
  computed: {
    transportTypes: () => Vehicle.getTransportTypes(),
    daysCount() {
      return moment(this.filter.report_date).daysInMonth()
    },
    columnsStyle() {
      return `grid-template-columns: 200px repeat(${this.daysCount}, 1fr) 80px;`
    },
    ...mapState({
      reportData: state => state.reportsData.reportData
    })
  },
  mounted() {
    this.$store.dispatch('reportsData/getTransportLoadReportData', this.filter);
  },
  methods: {
    getExcel,
    processExcelData() {
      if (!this.reportData.length) return false

      const rows = [];

      const daysArray = Array.from({length: this.daysCount}, (_, i) => i + 1)
      rows.push(['Наименование ТС', ...daysArray, 'Итого'])

      for (let item of this.reportData) {
        const vehicleLoadByDays = daysArray.map(day => {
          const dayLoad = this.getDayLoad(item.attributes.works_days, day);
          return dayLoad ? dayLoad : '—'
        });

        rows.push([
          item.attributes.name + ' ' + item.attributes.gov_number,
          ...vehicleLoadByDays,
          this.getSumVehicleLoad(item.attributes.works_days)
        ])
      }

      this.getExcel(rows, true);
    },
    getDayLoad(daysList, day) {
      const currentYearMonth = moment(this.filter.report_date).format('YYYY-MM')
      const date = moment(currentYearMonth + `-${day}`).format('YYYY-MM-DD');
      return daysList[date] ? daysList[date] : null
    },
    getSumVehicleLoad(daysList) {
      return Object.values(daysList).reduce((acc, current) => acc + current, 0);
    },
    getReportData() {
      this.$store.dispatch('reportsData/getTransportLoadReportData', this.filter);
    }
  }
}
</script>

<style lang="scss" scoped>
.transport-report-filter {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  &__form {
    display: flex;
    gap: 24px;
    max-width: 640px;
    width: 100%;
  }
}

.base-table__row {
  gap: 0;
}

.base-table__header {
  gap: 0;
}

.vehicle-load-name {
  padding-right: 24px;
}
</style>
