<template>
  <div class="i-modal__content-wrapper">
    <div class="i-modal__content">
      <div class="i-modal__inner">
        <div class="base-modal__left-side base-modal__left-side--hidden">
          <div class="base-modal-info base-modal-info--padding-m base-modal-info--gap-24">
            <transport-movement-history-form
              :form-title="`${vehicle.attributes.label} - история передвижения`"
              :vehicle-id="vehicle.id"
              @get-movement-history="createPolyline(); createMarkers()"/>
            <div class="transport-movement-history-map reset-leaflet-popup-styles">
              <base-map
                id-key="movement-history"
                :base-layers="baseLayers"
                :zoom="zoom"
                :center="center"
                :polyline="polyline"
                :markers="markers"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import TransportMovementHistoryForm from "@/components/Transport/TransportMovementHistoryForm.vue";
import BaseMap from "@/components/BaseMap.vue";
import Polyline from "@/models/baseMap/Polyline";
import Marker from "@/models/baseMap/Marker";
import Icon from '@/models/baseMap/Icon'
import {createVNode, render} from "vue";
import IconCourseMarker from "@/components/Icons/IconCourseMarker.vue";
export default {
  name: "TransportMovementHistoryModalContent",
  components: {BaseMap, TransportMovementHistoryForm},
  data() {
    return {
      polyline: {},
      markers: []
    }
  },
  computed: {
    ...mapState({
      movementHistory: state => state.autos.movement_history,
      vehicle: state => state.autos.auto,
      center: state => state.baseMap.center,
      zoom: state => state.baseMap.zoom,
      baseLayers: state => state.baseLayers.baseLayers
    })
  },
  mounted() {
    this.createPolyline();
    this.createMarkers();
  },
  beforeUnmount() {
    this.$store.commit('autos/SET_MOVEMENT_HISTORY_FROM', null);
    this.$store.commit('autos/SET_MOVEMENT_HISTORY_TO', null);
  },
  methods: {
    createMarkers() {
      this.markers = [];

      this.createCourseMarkers();
      this.createBeginAndEndMarkers();
    },
    createCourseMarkers() {
      const iconContainer = document.createElement('div');
      const iconVNode = createVNode(IconCourseMarker);
      render(iconVNode, iconContainer);
      const iconHtml = iconContainer.innerHTML;

      this.markers.push(...this.movementHistory
        .filter((_, index) => (index + 1) % 10 === 0)
        .map(item => {
          return new Marker({
            coords: [item.attributes.lon, item.attributes.lat],
            icon: new Icon({
              type: 'div_icon',
              size: [20, 26],
              anchor: [12, 13],
              html: iconHtml
            }),
            rotation_angle: item.attributes.course
          })
        }))

      render(null, iconContainer);
    },
    createBeginAndEndMarkers() {
      this.markers.push(new Marker({
        coords: [this.movementHistory[0].attributes.lon, this.movementHistory[0].attributes.lat],
        icon: new Icon({
          type: 'div_icon',
          size: [6, 6],
          anchor: [3, 3],
          html: '<div class="transport-end-maker"></div>'
        })
      }))

      const lastItem = this.movementHistory[this.movementHistory.length - 1]
      this.markers.push(new Marker({
        coords: [
          lastItem.attributes.lon,
          lastItem.attributes.lat
        ],
        icon: new Icon({
          type: 'div_icon',
          size: [6, 6],
          anchor: [3, 3],
          html: '<div class="transport-end-maker"></div>'
        })
      }))
    },
    createPolyline() {
      this.polyline = new Polyline({
        coords: this.movementHistory.map(item => [item.attributes.lon, item.attributes.lat]),
        color: '#FF3A81'
      })
    }
  }
}
</script>

<style scoped>
.transport-movement-history-map {
  height: calc(100vh - 300px);
  width: calc(100vw - 470px);
}

.base-modal__left-side {
  max-width: 100%;
}
</style>
