import {formatDateTime, formatDate} from '@/utils/filters';
import {dictionaryAnswer, answerByQuestionId, isAnswerTypeDictionary} from "@/utils/technicalInspection";
import axios from "axios";

const imageClient = axios.create({
    baseURL: '/',
    withCredentials: true
})

async function getImageBase64(url) {
    try {
        const response = await imageClient.get(url, {responseType: 'blob'});

        const reader = new FileReader();

        return new Promise((resolve, reject) => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;  // Обработка ошибок
            reader.readAsDataURL(response.data);
        });

    } catch (error) {
        return null;
    }
}

export const technicalInspectionTaskPdf = async (
    technicalInspectionTask,
    questionsList,
    answersList,
    violationsList
) => {

    const violationsListProcessed = await Promise.all(
        violationsList.map(async violation => {
            const violationStatus = () => {
                if (!violation.finishedDate && !violation.canceledDate) return 'Не устранено';
                return violation.finishedDate ? 'Устранено' : 'Отменено';
            };

            const violationBody = [];
            violationBody.push(['Тип дефекта/нарушения', violation.violation.name]);
            violationBody.push(['Статус', violationStatus()]);
            violationBody.push(['Описание', violation.description]);

            violation.fields.forEach(field => {
                violationBody.push([field.field.name, field.value]);
            });

            let violationImages = [];
            for (const attachment of violation.attachments) {
                const image = await getImageBase64(attachment.url);
                violationImages.push({
                    image,
                    width: 200,
                    margin: [0, 0, 0, 10],
                    alignment: 'center'
                });
            }

            return {
                style: 'table',
                table: {
                    widths: ['auto', 'auto'],
                    body: violationBody
                },
                violationImages: violationImages
            };
        })
    );

    const attachmentsList = await Promise.all(
        technicalInspectionTask.attachments.map(async attachment => {
            const image = await getImageBase64(attachment.url);
            return {
                image: image,
                width: 200,
                margin: [0, 0, 0, 10],
                alignment: 'center'
            }
        })
    )

    const docDefinition = {
        content: [
            {
                text: `Задание на обход №${technicalInspectionTask.id}`,
                style: 'header'
            },
            {
                style: 'table',
                table: {
                    widths: ['auto', '*'],
                    body: [
                        ['Объект', technicalInspectionTask.object.name],
                        ['Адрес', technicalInspectionTask.object.address],
                        ['Обходчик', technicalInspectionTask.resource.name],
                        ['Плановая дата выполнения', formatDate(technicalInspectionTask.plannedDate)],
                        [
                            'Фактическая дата выполнения',
                            technicalInspectionTask.finishedDate ?
                                formatDateTime(technicalInspectionTask.finishedDate) : ''
                        ]
                    ]
                }
            },
            {
                text: `Чек-лист`,
                style: 'subHeader'
            },
            {
                style: 'table',
                table: {
                    widths: ['auto', '*'],
                    body: questionsList.map(question => {
                        if (isAnswerTypeDictionary(question.answerType.id)) {
                            const answer = answerByQuestionId(question.id, answersList);
                            const getDictionaryAnswer = dictionaryAnswer(
                                question.dictionary.attributes.items,
                                answer?.value
                            );
                            return [question.question, getDictionaryAnswer ? getDictionaryAnswer.name : '—'];
                        } else {
                            const answer = answerByQuestionId(question.id, answersList);
                            return [question.question, answer ? answer.value : '—'];
                        }
                    })
                }
            }
        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10]
            },
            subHeader: {
                fontSize: 16,
                bold: true,
                margin: [0, 0, 0, 10]
            },
            table: {
                margin: [0, 5, 0, 15]
            }
        }
    }

    if (violationsList.length) {
        docDefinition.content.push({
            text: `Нарушения`,
            style: 'subHeader'
        })
        violationsListProcessed.forEach(violation => {
            docDefinition.content.push(violation)
            docDefinition.content.push(violation.violationImages)
        })
    }

    if (technicalInspectionTask.attachments.length) {
        docDefinition.content.push({
            text: `Фотографии`,
            style: 'subHeader'
        });
        docDefinition.content.push(...attachmentsList);
    }

    const pdfMake = require('pdfmake');
    pdfMake.createPdf(docDefinition).open();
}
