<template>
  <div class="issue-map-popup">
    <div class="issue-map-popup__content">
      <div class="issue-map-popup__header">
        <div @click="openIssueModal(issue.id)"
             class="issue-map-popup__id">{{ issue.id }}</div>
        <div class="issue-map-popup__state"
             :class="[
               {'base-table__tag--new': issue.state.id === IssueState.NEW},
               {'base-table__tag--work': issue.state.id === IssueState.IN_WORK},
               {'base-table__tag--done': issue.state.id === IssueState.CLOSED},
               {'base-table__tag--canceled': issue.state.id === IssueState.CANCELED}
             ]">
          {{ issue.state.label }}
        </div>
      </div>
      <div class="issue-map-popup__category">
        <span class="issue-map-popup__category-indicator"
              :style="{'background-color': issue.category.group_color}"/>
        {{issue.category.name}}
      </div>
      <div class="issue-map-popup__address">{{issue.getFullAddress()}}</div>
      <div class="issue-map-popup__created-at">{{ formatDateTime(issue.created_at) }}</div>
    </div>
  </div>
</template>

<script>
import IssueState from "@/models/serviceDesk/IssueState";
import store from "@/store";
import {formatDateTime} from "@/utils/filters";

export default {
  name: "IssueMapPopupContent",
  props: {
    issue: {
      type: Object
    }
  },
  computed: {
    IssueState: () => IssueState
  },
  methods: {
    formatDateTime,
    openIssueModal(issueId) {
      store.dispatch('baseSideModal/openModal', {
        modalContentType: 'issue',
        entityId: issueId
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers/index" as *;

.issue-map-popup {
  border-radius: 16px;
  border: 1px solid var(--border-main);

  background-color: var(--panel);
  box-shadow: 0px 6px 20px 0px rgba(6, 5, 50, 0.10);

  &__content {
    display: flex;
    flex-direction: column;
    gap: 12px;

    padding: 16px 24px;
  }

  &__header {
    display: flex;
    gap: 8px;
  }

  &__id {
    padding: 4px 8px;
    border-radius: 6px;
    background-color: var(--panel-bg);

    cursor: pointer;

    @include font-style(400, 14px, 16px, var(--text-primary));
  }

  &__state {
    padding: 4px 8px;
    border-radius: 8px;

    @include font-style(600, 14px, 16px, var(--text-panel));
  }

  &__category {
    display: flex;
    align-items: center;
    gap: 8px;

    @include font-style(600, 16px, 24px, var(--text-primary));
  }

  &__category-indicator {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }

  &__address {
    @include font-style(400, 14px, 16px, var(--text-primary));
  }

  &__created-at {
    @include font-style(400, 14px, 16px, var(--text-secondary));
  }
}
</style>
