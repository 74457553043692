<template>
  <div class="page-wrapper">
    <base-panel
      title="Система мониторинга параметров"
      :is-add-button-visible="false"
      :is-time-visible="true"
      :is-weather-visible="true"
    />

    <scada-filter />

    <scada-map v-if="currentPageView === 'MAP'" />
    <scada-table v-if="currentPageView === 'TABLE'" />

  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel";
import ScadaFilter from "@/components/Telemetry/Scada/ScadaFilter";
import ScadaTable from "@/components/Telemetry/Scada/ScadaTable";
import ScadaMap from "@/components/Telemetry/Scada/ScadaMap";
import {mapState} from "vuex";

export default {
  name: "ScadaPage",
  components: {
    ScadaMap,
    ScadaTable,
    ScadaFilter,
    BasePanel
  },
  data() {
    return {
      timing: null
    }
  },
  computed: {
    ...mapState({
      currentPageView: state => state.scadaPage.currentPageView
    })
  },
  mounted() {
    this.$store.dispatch('telemetry/getNetworkObjects');
    this.$store.dispatch('telemetry/getScadaNetworkEquipments');
    this.getParametersValues();

    this.$store.dispatch('networkObjectTypes/getNetworkObjectTypesList');
    this.$store.dispatch('structuralUnits/getStructuralUnitsList');
  },
  beforeUnmount() {
    clearInterval(this.timing);
  },
  methods: {
    getParametersValues() {
      this.$store.dispatch('telemetry/getParametersValues');

      if (!this.timing) {
        this.runTiming();
      }
    },
    runTiming() {
      clearInterval(this.timing);
      this.timing = setInterval(this.getParametersValues, 60000)
    }
  }
}
</script>

<style scoped>
</style>
