<template>
  <div class="base-table">
    <div class="base-table__container">
      <div class="base-table__header base-table__row--networks-length">
        <div class="base-table__header-cell">Наименование сети</div>
        <div v-for="month in monthsList"
             :key="month.number"
             class="base-table__header-cell base-table__header-cell--capitalize base-table__header-cell--center">
          {{month.label}}
        </div>
      </div>

      <div v-for="network in engineeringNetworksList"
           :key="network.id"
           class="base-table__row base-table__row--networks-length">
        <div class="base-table__column">
          <div class="network-info-cell">
            <div class="network-info-cell__id">{{ network.id }}</div>
            <div class="network-info-cell__text">
              <div class="network-info-cell__name">{{network.name}}</div>
              <div class="network-info-cell__type">{{network.typeName}}</div>
            </div>
          </div>
        </div>
        <div v-for="month in monthsList"
             :key="month.number"
             class="base-table__column">
          <div class="network-length">
            <input
              class="network-length__input"
              :value="networkLength(network.id, month.number)"
              v-mask="`9{1,10}.9{1,2}`"
              type="text"
              placeholder="—"
              @change="changeNetworkLength(network.id, month.number, $event)">
            <span class="network-length__units">км</span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {showToast, showAlert} from "@/utils/notification";
import Inputmask from "bitweb-inputmask";

export default {
  name: "EngineeringNetworksLengthList",
  directives: {
    mask: {
      mounted(el, binding) {
        Inputmask(binding.value).mask(el);
      },
      updated(el, binding) {
        Inputmask(binding.value).mask(el);
      }
    }
  },
  props: {
    engineeringNetworksList: {
      type: Array,
      required: true
    },
    selectedYear: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      monthsList: [
        {number: 1, label: 'Янв'},
        {number: 2, label: 'Фев'},
        {number: 3, label: 'Мар'},
        {number: 4, label: 'Апр'},
        {number: 5, label: 'Май'},
        {number: 6, label: 'Июн'},
        {number: 7, label: 'Июл'},
        {number: 8, label: 'Авг'},
        {number: 9, label: 'Сен'},
        {number: 10, label: 'Окт'},
        {number: 11, label: 'Ноя'},
        {number: 12, label: 'Дек'}
      ]
    }
  },
  computed: {
    ...mapGetters({
      networkLength: 'networksLength/getNetworkLengthByIdAndDate'
    })
  },
  methods: {
    changeNetworkLength(networkId, month, event) {
      const networkLengthDate = `${this.selectedYear}-${month}-01`;
      if (!event.target.value) return false;

      this.$store.dispatch('networksLength/changeNetworkLength', {
        network_id: networkId,
        value_date: networkLengthDate,
        length_value: parseFloat(event.target.value)
      })
        .then(() => showToast('Протяженность сети изменена', 'success'))
        .catch(error => showAlert('Ошибка', error, 'error'));
    }
  }
}
</script>

<style lang="scss">
@use "@/assets/scss/helpers/index" as *;

.network-info-cell {
  display: flex;
  align-items: center;
  gap: 24px;

  &__id {
    padding: 4px 8px;
    border-radius: 6px;
    background-color: var(--panel-bg);

    @include font-style(400, 14px, 16px, var(--text-primary));
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__name {
    @include font-style(600, 14px, 16px, var(--text-primary));
  }

  &__type {
    @include font-style(400, 12px, 14px, var(--text-secondary));
  }
}

.network-length {
  position: relative;

  &__input {
    width: 100%;
    border: none;
    outline: none;
    padding: 12px;
    margin: -4px;
    text-align: center;
    background-color: var(--panel);
    color: var(--text-primary);

    &:focus {
      border-radius: 16px;
      border: 1px solid var(--border-active);
      background: var(--panel);
      padding-right: 24px;
    }

    &:focus + .network-length__units {
      display: block;
    }
  }

  &__units {
    display: none;
    position: absolute;
    top: 9px;
    right: 9px;

    @include font-style(400, 14px, 16px, var(--text-secondary));
  }
}

.base-table__row--networks-length {
  grid-template-columns: 1fr repeat(12, 5.35%);
}
</style>
