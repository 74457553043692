<template>
  <div class="base-modal-info__card">
    <div class="base-modal-info__card-title base-modal-info__card-title--loader">
      {{formTitle}}
      <base-loader v-if="loadingHistory"/>
    </div>
    <div class="transport-history">
      <base-date-picker
        v-model="historyFrom"
        enable-time-picker
        format="dd.MM.yyyy, HH:mm"
        value-format="yyyy-MM-dd HH:mm"
        placeholder="Начало периода"
      />
      <div class="transport-history__dash"/>
      <base-date-picker
        v-model="historyTo"
        enable-time-picker
        format="dd.MM.yyyy, HH:mm"
        value-format="yyyy-MM-dd HH:mm"
        placeholder="Конец периода"
        :min-date="minDate"
        :max-date="maxDate"
      />
      <button
        @click="getVehicleMovementHistory()"
        :disabled="loadingHistory"
        class="button button--mode-primary transport-history__button">Показать
      </button>
    </div>
    <div
      v-if="isEmptyData"
      class="transport-history__error">История перемещения в указанный период не найдена.</div>
  </div>
</template>

<script>
import BaseLoader from "@/layout/BaseLoader.vue";
import {mapState} from "vuex";
import moment from "moment";
import BaseDatePicker from "@/components/BaseDatePicker.vue";

export default {
  name: "TransportMovementHistoryForm",
  components: {BaseDatePicker, BaseLoader},
  props: {
    formTitle: {
      type: String,
      default: 'История передвижения'
    },
    vehicleId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      historyFrom: null,
      historyTo: null,
      loadingHistory: false,
      isEmptyData: false
    }
  },
  computed: {
    ...mapState({
      movementHistoryFrom: state => state.autos.movementHistoryFrom,
      movementHistoryTo: state => state.autos.movementHistoryTo
    }),
    minDate() {
      return this.historyFrom ? new Date(new Date(this.historyFrom).getTime() - (24 * 60 * 60 * 1000)) : null
    },
    maxDate() {
      return this.historyFrom ? new Date(new Date(this.historyFrom).getTime() + (24 * 60 * 60 * 1000)): null
    }
  },
  mounted() {
    this.historyFrom = this.movementHistoryFrom;
    this.historyTo = this.movementHistoryTo;
  },
  methods: {
    getVehicleMovementHistory() {
      this.loadingHistory = true;

      let from = moment(this.historyFrom).format('YYYY-MM-DD HH:mm:ss');
      let to = moment(this.historyTo).format('YYYY-MM-DD HH:mm:ss');

      this.$store.dispatch('autos/getMovementHistory', {from, to, id: this.vehicleId})
        .then(movementHistory => {
          this.$emit('get-movement-history')
          this.$store.commit('autos/SET_MOVEMENT_HISTORY_FROM', from);
          this.$store.commit('autos/SET_MOVEMENT_HISTORY_TO', to);

          this.isEmptyData = !movementHistory.length;
        })
        .finally(() => {
          this.loadingHistory = false;
        })
    }
  }
}
</script>

<style lang="scss">
.transport-history {
  display: flex;
  align-items: center;
  gap: 24px;

  & .base-date-picker {
    width: 250px;
  }

  &__dash {
    height: 1px;
    width: 12px;
    background-color: var(--bg-secondary);
  }

  &__button {
    flex: 1;
  }

  &__error {
    margin-top: 16px;
    color: var(--border-validation);
  }
}

.transport-end-maker {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--bg-sidebar);
}
</style>
