<template>
  <div class="base-date-switcher">
    <div class="base-date-switcher__wrapper">
      <div
        @click="minusDate()"
        class="base-date-switcher__arrow"
        :class="{'base-date-switcher__arrow--disabled': currentDateLessThenMin}">
        <icon-arrow-left/>
      </div>
      <base-date-picker
        v-model="date"
        :min-date="new Date(new Date().getDate() - 7)"
        :max-date="new Date()"
        class="base-date-switcher__input"
      />
      <div
        @click="plusDate()"
        class="base-date-switcher__arrow"
        :class="{'base-date-switcher__arrow--disabled': currentDateEqualMax}">
        <icon-arrow-right/>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrowLeft from "@/components/Icons/IconArrowLeft.vue";
import IconArrowRight from "@/components/Icons/IconArrowRight.vue";
import moment from "moment";
import BaseDatePicker from "@/components/BaseDatePicker.vue";

export default {
  name: "BaseDateSwitcher",
  components: {
    BaseDatePicker,
    IconArrowRight,
    IconArrowLeft
  },
  props: {
    daysPastLimit: {
      type: Number,
      default: Infinity
    }
  },
  data() {
    return {
      date: moment().format("YYYY-MM-DD")
    }
  },
  computed: {
    currentDateEqualMax() {
      return moment(this.date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');
    },
    currentDateLessThenMin() {
      return moment(this.date) < moment().subtract((this.daysPastLimit - 1), "days");
    }
  },
  watch: {
    date: function (value) {
      this.$emit('date-input', value);
    }
  },
  methods: {
    minusDate() {
      if (!this.currentDateLessThenMin) {
        this.date = moment(this.date).subtract(1, 'days').format("YYYY-MM-DD");
      }
    },
    plusDate() {
      if (!this.currentDateEqualMax) {
        this.date = moment(this.date).add(1, 'days').format("YYYY-MM-DD");
      }
    }
  }
}
</script>

<style lang="scss">
.base-date-switcher {
  &__wrapper {
    display: flex;
    align-items: center;

    height: 50px;

    border: 1px solid var(--border-main);
    border-radius: 16px;
    background-color: var(--panel);
  }

  &__arrow {
    display: flex;
    align-items: center;

    height: 100%;
    width: 24px;
    cursor: pointer;

    & svg {
      & path {
        stroke: var(--ico-active);
      }
    }

    &:first-child {
      border-right: 1px solid var(--border-main);
    }

    &:last-child {
      border-left: 1px solid var(--border-main);
    }

    &--disabled {
      cursor: default;

      & svg {
        & path {
          stroke: var(--ico);
        }
      }
    }
  }

  &__input {
    & .dp__input {
      width: 122px;

      border: none !important;
      border-radius: 0 !important;
      box-shadow: none;

      font-size: 16px;
      color: var(--text-primary) !important;
      background-color: var(--panel) !important;
      padding: 10px 12px !important;
    }

    & .dp__input_icon {
      display: none;
    }
  }
}
</style>