<template>
  <div class="page-wrapper">
    <base-panel title="Журнал ручного ввода">
      <template #buttons>
        <base-loader v-show="loading" />

        <base-date-picker
          v-model="date"
          format="dd.MM.yyyy"
          value-format="yyyy-MM-dd"
          placeholder="Выберите день"
          @update:modelValue="getParametersValues"
        />
      </template>
    </base-panel>

    <logbook-table
      :tabs-list="tabsList"
      :change-active-tab="changeActiveTab" />
  </div>
</template>

<script>
import LogbookTable from "@/components/Telemetry/LogbookTable";
import BasePanel from "@/components/BasePanel.vue";
import {mapState} from "vuex";
import BaseLoader from "@/layout/BaseLoader.vue";
import moment from "moment";
import BaseDatePicker from "@/components/BaseDatePicker.vue";

export default {
  name: "LogbookPage",
  components: {
    BaseDatePicker,
    BaseLoader,
    BasePanel,
    LogbookTable
  },
  data() {
    return {
      date: null,
      tabsList: []
    }
  },
  computed: {
    ...mapState({
      loading: state => state.logbookPage.valuesLoading
    })
  },
  mounted() {
    this.date = moment().format('YYYY-MM-DD');
    this.getParametersValues();
    this.$store.dispatch('logbookPage/getNetworkObjectsList')
      .then(networkObjects => {
        this.tabsList = networkObjects
          .map(item => {return {id: item.typeId, name: item.typeName, active: false}})
          .filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);

        if (this.tabsList.length) this.tabsList[0].active = true;
      })
    this.$store.commit('logbookPage/SET_CURRENT_LOGBOOK_DATE', this.date)
  },
  methods: {
    changeActiveTab(tabId) {
      this.tabsList = this.tabsList.map(item => {
        return {
          ...item,
          active: tabId === item.id
        }
      })
    },
    getParametersValues() {
      this.$store.commit('logbookPage/SET_CURRENT_LOGBOOK_DATE', this.date);
      this.$store.dispatch('logbookPage/getParametersValues', {
        date: moment(this.date).format('YYYY-MM-DD')
      })
    }
  }
}
</script>

<style scoped>

</style>