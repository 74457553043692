<template>
  <div class="page-wrapper">
    <base-panel
      title="Статистика по заявкам"
      :is-add-button-visible="false"/>
    <div class="filter">
      <div class="filter__left">
        <base-date-range-picker @updateDateRange="setRange($event)"/>
        <base-date-picker
          v-model="range"
          range
          format="dd.MM.yyyy"
          value-format="yyyy-MM-dd"
          placeholder="Выберите период"
          @update:modelValue="getIssuesData"
        />
      </div>
    </div>
    <base-statistic-cards
      v-if="Object.keys(issuesStatistic).length"
      :all="issuesStatistic.summary.opened"
      :open="issuesStatistic.summary.active"
      :close="issuesStatistic.summary.closed"/>
    <base-content-loaders v-if="contentLoading"/>
    <div class="charts"
         v-else>
      <div class="charts-top">
        <completed-issues v-if="Object.keys(issuesStatistic).length"
                          :time-period="timePeriod"/>
        <transit-issues :time-period="timePeriod"/>
      </div>
      <div class="charts-bottom">
        <issues-count-by-groups
          chart-title="Типы поступивших заявок"
          :issues-statistic="issuesStatistic.group_active_issues"/>
      </div>
      <div class="charts-bottom">
        <issues-count-by-groups
          chart-title="Типы закрытых заявок"
          :issues-statistic="issuesStatistic.group_finished_issues"/>
      </div>
    </div>
  </div>
</template>

<script>
import IssuesCountByGroups from "@/components/ServiceDesk/Issues/IssuesCountByGroups.vue";
import BaseDateRangePicker from "@/components/BaseDateRangePicker.vue";
import BasePanel from "@/components/BasePanel.vue";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import {mapState} from "vuex";
import {timePeriodLength} from "@/utils/timePeriodLength";
import BaseStatisticCards from "@/components/ServiceDesk/BaseStatisticCards.vue";
import CompletedIssues from "@/components/ServiceDesk/Issues/CompletedIssues.vue";
import TransitIssues from "@/components/ServiceDesk/Issues/TransitIssues.vue";
import BaseDatePicker from "@/components/BaseDatePicker.vue";

export default {
  name: "IssuesDashboardPage",
  components: {
    BaseDatePicker,
    TransitIssues,
    CompletedIssues,
    BaseStatisticCards,
    BaseContentLoaders,
    BasePanel,
    BaseDateRangePicker,
    IssuesCountByGroups
  },
  data() {
    return {
      range: []
    }
  },
  computed: {
    ...mapState({
      reportLoading: state => state.reportsData.loading,
      reportData: state => state.reportsData.reportData,
      issuesStatistic: state => state.issuesStatistic.issuesStatistic,
      issuesStatisticLoading: state => state.issuesStatisticLoading
    }),
    contentLoading() {
      return this.issuesStatisticLoading || this.reportLoading;
    },
    createFilterParams() {
      const [start, end] = this.range;
      return {
        start_period: `${start} 00:00:00`,
        end_period: `${end} 23:59:59`
      }
    },
    timePeriod() {
      return this.timePeriodLength(this.range[0], this.range[1]);
    }
  },
  mounted() {
    this.$store.dispatch('serviceDeskCategories/getCategoryGroups');
    this.$store.dispatch('issueTypes/getIssueTypes');
  },
  methods: {
    timePeriodLength,
    setRange(date) {
      this.range = [date.start, date.end];
      this.getIssuesData();
    },
    getIssuesData() {
      const dayGroup = 1;
      const monthGroup = 2;
      const currentGroup = this.timePeriod > 30 ? monthGroup : dayGroup;
      this.$store.dispatch('issuesStatistic/fetchIssuesStatistic', this.createFilterParams);
      this.$store.dispatch('reportsData/getIssuesCountReportData', {
        group: currentGroup,
        period_start: this.createFilterParams.start_period,
        period_end: this.createFilterParams.end_period
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers/index" as *;

.filter {
  display: grid;
  grid-template-columns: 771px 144px;
  gap: 20px;
  width: 100%;
  margin-bottom: 32px;
  justify-content: space-between;

  &__left {
    display: grid;
    grid-template-columns: 1fr 320px;
    gap: 20px;
    justify-content: center;

    @media (max-width: $tablet) {
      grid-template-columns: 1fr;
    }
  }

  .base-date-range-picker {
    justify-self: flex-start;
  }


  @media (max-width: $tablet) {
    grid-template-columns: 1fr;
  }
}

.charts {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  .charts-top {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    @media (max-width: $desktop) {
      grid-template-columns: 1fr;
    }
  }

  .charts-bottom {
    width: 100%;
    display: flex;
  }
}

.page-title {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  color: var(--text-link);
}

.days-count-button:first-child {
  margin-left: 10px;
}

.days-count-button {
  display: inline-block;
  padding: 8px 12px;
  background-color: #EAEFF2;
  border-radius: 3px;
  color: var(--text-link);
  font-size: 13px;
  font-weight: 500;
  margin-right: 5px;
  cursor: pointer;
}

.days-count-button.active {
  background-color: var(--bg-sidebar);
  color: var(--text-panel);
}
</style>
