<template>
  <div class="scada-filter">
    <div class="scada-filter__search">
      <div class="icon-input">
        <img
          src="@/assets/img/n-icons/icon-search.svg"
          alt="icon">
        <input
          type="text"
          placeholder="Искать"
          class="icon-input__field"
          v-model="filterByNameQuery">
      </div>
    </div>

    <div class="scada-filter__controls">
      <div class="scada-filter__indication-buttons">
        <div :class="{'toggled': filterEquipmentsByDangerStatus}"
             @click="$store.commit('scadaPage/TOGGLE_DANGER_STATUS_FILTER')">
          <icon-flash />
        </div>
      </div>
      <div class="filter__button"
           :class="{'opened': isFilterModalVisible}"
           @click="isFilterModalVisible = true"
           data-modal-toggle="base-filter-modal">
        <icon-settings2 />
        <span>Фильтры</span>
        <div
          v-if="countFiltersSelected()"
          class="scada-filter__button-indicator">
          {{countFiltersSelected()}}
        </div>
      </div>
      <base-filter-modal
        :is-visible="isFilterModalVisible"
        @toggle-modal="isVisible => this.isFilterModalVisible = isVisible"
      >
        <div class="filter__modal-header">
          <div class="filter__modal-name">Фильтры</div>
          <div @click="resetFilters()"
               class="filter__modal-reset">Сбросить все</div>
        </div>
        <div class="filter__modal-form">
          <div>
            <label class="input-label">Тип сетевого объекта</label>
            <div class="custom-radio-buttons">
              <div v-for="type in networkObjectTypes"
                   :key="type.id"
                   @click="setNetworkObjectType(type.id)"
                   :class="{'active': payload.type_id === type.id}"
                   class="custom-radio-buttons__input">
                {{type.name}}
              </div>
            </div>
          </div>
          <div>
            <label class="input-label">Структурные подразделения</label>
            <v-select
              class="vue-select-input"
              v-model="structural_unit_ids"
              :options="structuralUnitsList"
              :reduce="structuralUnit => structuralUnit.id"
              :get-option-label="(option) => option.name"
              placeholder="Выберите из списка"
              multiple
            />
          </div>
        </div>
        <div class="filter__modal-buttons">
          <div @click="submitFilter()"
               class="button">Применить</div>
          <div @click="isFilterModalVisible = false"
               class="button button--mode-outline">Закрыть</div>
        </div>
      </base-filter-modal>
      <div class="scada-filter__view-buttons">
        <div
          @click="$store.commit('scadaPage/SET_CURRENT_PAGE_VIEW', 'MAP')"
          :class="{'active': currentPageView === 'MAP'}">
          <icon-map />
        </div>
        <div
          @click="$store.commit('scadaPage/SET_CURRENT_PAGE_VIEW', 'TABLE')"
          :class="{'active': currentPageView === 'TABLE'}">
          <icon-hamburger-menu />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconFlash from "@/components/Icons/IconFlash";
import IconSettings2 from "@/components/Icons/IconSettings2";
import BaseFilterModal from "@/components/BaseFilterModal";
import IconMap from "@/components/Icons/IconMap";
import IconHamburgerMenu from "@/components/Icons/IconHamburgerMenu";
import {mapState} from "vuex";

export default {
  name: "ScadaFilter",
  components: {IconHamburgerMenu, IconMap, BaseFilterModal, IconSettings2, IconFlash},
  data() {
    return {
      payload: {
        type_id: null
      },
      structural_unit_ids: [],
      isFilterModalVisible: false
    }
  },
  computed: {
    ...mapState({
      currentPageView: state => state.scadaPage.currentPageView,
      filterEquipmentsByDangerStatus: state => state.scadaPage.filterEquipmentByDangerStatus,
      networkObjectTypes: state => state.networkObjectTypes.networkObjectTypes,
      nameQuery: state => state.scadaPage.filterByNameQuery,
      structuralUnitsList: state => state.structuralUnits.structuralUnits
    }),
    filterByNameQuery: {
      get() {
        return this.nameQuery
      },
      set(value) {
        this.$store.commit('scadaPage/SET_FILTER_BY_NAME_QUERY', value);
      }
    }
  },
  methods: {
    countFiltersSelected() {
      return Object.values(this.payload).reduce((accum, value) => value ? accum + 1 : accum, 0)
    },
    setNetworkObjectType(value) {
      if (this.payload.type_id === value) {
        this.payload.type_id = null;
      } else {
      this.payload.type_id = value;
      }
    },
    resetFilters() {
      this.$store.commit('scadaPage/SET_STRUCTURAL_UNIT_IDS', []);
      this.structural_unit_ids = [];

      this.payload = {
        type_id: null
      }

      this.$store.commit('telemetry/SET_FILTER_PARAMS', null);
      this.$store.dispatch('telemetry/getNetworkObjects');
      this.isFilterModalVisible = false;
    },
    submitFilter() {
      if (this.structural_unit_ids.length) {
        this.$store.commit('scadaPage/SET_STRUCTURAL_UNIT_IDS', this.structural_unit_ids);
      }

      if (Object.values(this.payload).some(value => value)) {
        this.$store.commit('telemetry/SET_FILTER_PARAMS', this.payload);
        this.$store.dispatch('telemetry/getNetworkObjects');
      }
      this.isFilterModalVisible = false;
    }
  }
}
</script>

<style scoped>

</style>
