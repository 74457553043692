export default class StructuralUnit {
    constructor(params) {
        this.id = params.id;
        this.name = params.name;
        this.headName = params.head_name;
        this.parentId = params.parent?.id;
        this.parentName = params.parent?.name;
        this.type = params.type;
    }
}
