<template>
  <div v-if="reportData.length"
       class="base-table__container"
       id="daily-report-table">
    <div class="base-table__header base-table__header--accident">
      <div class="base-table__header-cell">Наименование</div>
      <div class="base-table__header-cell">Ед. изм.</div>
      <div v-for="(item, index) in reportData"
           :key="index"
           class="base-table__header-cell">{{ formatDate(item.date) }}
      </div>
    </div>
    <div class="base-table__group">
      <div class="base-table__group-header">
        <div class="base-table__group-title">
          Водопровод - сети
        </div>
      </div>
      <div class="base-table__row base-table__row--accident">
        <div class="base-table__column">
          <div class="base-table__text">Количество повреждений, в т.ч. аварий на водопроводной сети</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">шт.</div>
        </div>
        <div v-for="(item, index) in reportData"
             :key="index"
             class="base-table__column">
          <div class="base-table__text">{{ item.accident_water.all_count }}</div>
        </div>
      </div>
      <div class="base-table__row base-table__row--accident">
        <div class="base-table__column">
          <div class="base-table__text">Справочно: из грунта (с разрытием)</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">шт.</div>
        </div>
        <div v-for="(item, index) in reportData"
             :key="index"
             class="base-table__column">
          <div class="base-table__text">{{ item.accident_water.with_earth_breaking }}</div>
        </div>
      </div>
      <div class="base-table__row base-table__row--accident">
        <div class="base-table__column">
          <div class="base-table__text">Справочно: из ВК</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">шт.</div>
        </div>
        <div v-for="(item, index) in reportData"
             :key="index"
             class="base-table__column">
          <div class="base-table__text">{{ item.accident_water.from_well }}</div>
        </div>
      </div>
      <div class="base-table__row base-table__row--accident">
        <div class="base-table__column">
          <div class="base-table__text">Справочно: из поверхностной трубы</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">шт.</div>
        </div>
        <div v-for="(item, index) in reportData"
             :key="index"
             class="base-table__column">
          <div class="base-table__text">{{ item.accident_water.on_surface }}</div>
        </div>
      </div>
      <div class="base-table__row base-table__row--accident">
        <div class="base-table__column">
          <div class="base-table__text">Авария (с отключением)</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">шт.</div>
        </div>
        <div v-for="(item, index) in reportData"
             :key="index"
             class="base-table__column">
          <div class="base-table__text">{{ item.accident_water.with_shutdowns }}</div>
        </div>
      </div>
      <div class="base-table__row base-table__row--accident">
        <div class="base-table__column">
          <div class="base-table__text">Повреждения (без отключения)</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">шт.</div>
        </div>
        <div v-for="(item, index) in reportData"
             :key="index"
             class="base-table__column">
          <div class="base-table__text">{{ item.accident_water.without_shutdowns }}</div>
        </div>
      </div>
    </div>

    <div class="base-table__group">
      <div class="base-table__group-header">
        <div class="base-table__group-title">
          Канализация - сети
        </div>
      </div>
      <div class="base-table__row base-table__row--accident">
        <div class="base-table__column">
          <div class="base-table__text">Количество повреждений на канализационной сети</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">шт.</div>
        </div>
        <div v-for="(item, index) in reportData"
             :key="index"
             class="base-table__column">
          <div class="base-table__text">{{ item.accident_drainage.all_count }}</div>
        </div>
      </div>
      <div class="base-table__row base-table__row--accident">
        <div class="base-table__column">
          <div class="base-table__text">Засоры</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">шт.</div>
        </div>
        <div v-for="(item, index) in reportData"
             :key="index"
             class="base-table__column">
          <div class="base-table__text">{{ item.accident_drainage.clogging }}</div>
        </div>
      </div>
      <div class="base-table__row base-table__row--accident">
        <div class="base-table__column">
          <div class="base-table__text">Порывы на напорных сетях</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">шт.</div>
        </div>
        <div v-for="(item, index) in reportData"
             :key="index"
             class="base-table__column">
          <div class="base-table__text">{{ item.accident_drainage.gusts }}</div>
        </div>
      </div>
    </div>

    <div class="base-table__group">
      <div class="base-table__group-header">
        <div class="base-table__group-title">
          Протяженность сети
        </div>
      </div>
      <div class="base-table__row base-table__row--accident">
        <div class="base-table__column">
          <div class="base-table__text">Протяженность водопроводной сети</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">км.</div>
        </div>
        <div v-for="(item, index) in reportData"
             :key="index"
             class="base-table__column">
          <div class="base-table__text">{{ item.accident_water.network_len }}</div>
        </div>
      </div>
      <div class="base-table__row base-table__row--accident">
        <div class="base-table__column">
          <div class="base-table__text">Протяженность канализационной сети</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">км.</div>
        </div>
        <div v-for="(item, index) in reportData"
             :key="index"
             class="base-table__column">
          <div class="base-table__text">{{ item.accident_drainage.network_len }}</div>
        </div>
      </div>
    </div>

    <div class="base-table__group">
      <div class="base-table__group-header">
        <div class="base-table__group-title">
          Аварийность
        </div>
      </div>
      <div class="base-table__row base-table__row--accident">
        <div class="base-table__column">
          <div class="base-table__text">По водопроводным сетям</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">кол-во авар./км*год</div>
        </div>
        <div v-for="(item, index) in reportData"
             :key="index"
             class="base-table__column">
          <div class="base-table__text">{{accidentRate(
            item.accident_water.all_count,
            item.accident_water.network_len
          )}}
          </div>
        </div>
      </div>
      <div class="base-table__row base-table__row--accident">
        <div class="base-table__column">
          <div class="base-table__text">По канализационным сетям</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">кол-во авар./км*год</div>
        </div>
        <div v-for="(item, index) in reportData"
             :key="index"
             class="base-table__column">
          <div class="base-table__text">{{accidentRate(
            item.accident_drainage.all_count,
            item.accident_drainage.network_len
          )}}
          </div>
        </div>
      </div>
    </div>

    <div class="base-table__group">
      <div class="base-table__group-header">
        <div class="base-table__group-title">
          Очистка канализационной сети
        </div>
      </div>
      <div class="base-table__row base-table__row--accident">
        <div class="base-table__column">
          <div class="base-table__text">Протяженность промытой канализационной сети</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">м.</div>
        </div>
        <div v-for="(item, index) in reportData"
             :key="index"
             class="base-table__column">
          <div class="base-table__text">{{ item.accident_drainage.washed_len }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";

export default {
  name: "AccidentReport",
  props: {
    accidentRate: {
      type: Function,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      reportParams: 'reportsData/getReportParams',
      reportData: 'reportsData/getReportData'
    })
  },
  methods: {
    formatDate(date) {
      if (this.reportParams.group === 1) {
        return moment(date).format('MMMM');
      } else {
        return moment(date).format('DD');
      }
    }
  }
}
</script>

<style scoped>
.table-section-title {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
}
</style>