<template>
  <div class="resources-load-filter">
    <div class="resources-load-filter__date">
      <div class="base-date-range-picker">
        <div v-for="date in dateList"
             :key="date.name"
             @click="setCurrentDate(date)"
             :class="['base-date-range-picker__item',
                      {'base-date-range-picker__item--active': date.name === currentDate.name}]">
          {{ date.name }}
        </div>
      </div>
      <div class="resources-load-filter__date-selector">
        <base-date-picker
          v-model="range"
          placeholder="Выберите период"
          range
          @update:modelValue="fetchTasks"
        />
      </div>
    </div>
    <div class="resources-load-filter__department-selector">
      <v-select
        class="vue-select-input"
        v-model="department"
        :options="departmentsList"
        :reduce="device => device.id"
        :get-option-label="(option) => option.name"
        placeholder="Выберите подразделение"
        @update:modelValue="fetchResources"
      />
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import BaseDatePicker from "@/components/BaseDatePicker.vue";
import moment from "moment";

export default {
  name: "ResourcesLoadFilter",
  components: {BaseDatePicker},
  data() {
    return {
      range: [],
      department: null,
      currentDate: {
        name: 'Сегодня',
        days: 0
      },
      dateList: [
        {
          name: 'Сегодня',
          days: 0
        },
        {
          name: 'Неделя',
          days: 7
        },
        {
          name: 'Месяц',
          days: 30
        },
        {
          name: 'Квартал',
          days: 90
        },
        {
          name: 'Год',
          days: 365
        }
      ]
    }
  },
  computed: {
    ...mapState({
      departmentsList: state => state.departments.departments
    })
  },
  mounted() {
    const now = moment().format('YYYY-MM-DD');
    this.range = [now, now]
    this.$store.dispatch('departments/getAllDepartments')
      .then(() => {
        const [department] = this.departmentsList;
        this.department = department.id;
        this.fetchResources();
      })
    this.fetchTasks();
  },
  methods: {
    setCurrentDate(date) {
      this.currentDate = date;
      this.getDateNDaysAgo(date.days);
    },
    getDateNDaysAgo(n) {
      const today = new Date();
      const date = new Date();
      date.setDate(date.getDate() - n);
      this.range = [moment(date).format('YYYY-MM-DD'), moment(today).format('YYYY-MM-DD')];
      this.fetchTasks();
    },
    fetchResources() {
      this.$emit('get-resources', this.department);
    },
    fetchTasks() {
      const [startDate, endDate] = this.range;

      this.$emit('get-tasks', {
        s_planned_start: startDate,
        e_planned_start: moment(endDate).format('YYYY-MM-DD 23:59')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.resources-load-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 32px;

  &__date {
    display: flex;
    gap: 24px;
  }

  &__date-selector {
    width: 320px;
  }

  &__department-selector {
    width: 320px;
  }
}
</style>
