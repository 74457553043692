import {createApp, markRaw} from 'vue'
import App from '@/App.vue'
import router from '@/router/index'
import store from '@/store'

import 'sweetalert2/dist/sweetalert2.min.css';
import vSelect from 'vue-select';
import VModal from '@/plugins/vueModal'
import 'vue-select/dist/vue-select.css';
import 'moment/locale/ru'

import "@/assets/css/style.css";
import "@/assets/css/custom.css";

import moment from 'moment';
import 'moment/locale/ru';

moment.locale('ru')

const app = createApp(App);

window.Pusher = require('pusher-js');

app.use(router);
app.use(store);
app.use(VModal, {dynamic: true, injectModalsContainer: true});

app.component('VSelect', markRaw(vSelect));

app.mount('#app');
