<template>
  <div>

    <div class="loader-position"
         v-if="loading">
      <base-loader/>
    </div>

    <base-no-data
      v-if="!$store.state.building.buildings.length"
      title="Здания не найдены"/>

    <div class="buildings-filter-result">
      <div
        v-for="building in this.$store.getters['building/getBuildings']"
        :key="building.id"
        class="base-card building-item"
      >
        <div class="building-item__address-wrapper">
          <div
            class="building-item__address"
            @click="getBuildingDetail(building.id)"
          >
            {{ getFullAddress(building) }}
          </div>
          <div
            class="result-map"
            @click="$store.commit(
              'baseMap/SET_CENTER_ZOOM',
              { center: building.attributes.geometry.coordinates, zoom: 17})
            ">
            <icon-map />
          </div>
        </div>

        <template class="text-list">
          <div
            v-if="building.attributes.fact_consumption"
            class="text-list__item">
            <div class="text-list__item-name">Фактическое водопотребление:</div>
            <div class="text-list__item-value">
              {{building.attributes.fact_consumption.toFixed(2)}}м<sup>3</sup>/месяц
            </div>
          </div>
          <div v-if="building.attributes.fact_drainage"
               class="text-list__item">
            <div class="text-list__item-name">Фактическое водоотведение:</div>
            <div class="text-list__item-value">
              {{ building.attributes.fact_drainage.toFixed(2) }}м<sup>3</sup>/месяц
            </div>
          </div>
          <div v-if="building.attributes.normative_consumption"
               class="text-list__item">
            <div class="text-list__item-name">ТУ водопотребление:</div>
            <div class="text-list__item-value">
              {{ building.attributes.normative_consumption.toFixed(2) }}м<sup>3</sup>/месяц
            </div>
          </div>
          <div v-if="building.attributes.normative_drainage"
               class="text-list__item">
            <div class="text-list__item-name">ТУ водоотведение:</div>
            <div class="text-list__item-value">
              {{ building.attributes.normative_drainage.toFixed(2) }}м<sup>3</sup>/месяц
            </div>
          </div>
          <div v-if="building.attributes.fact_ratio"
               class="text-list__item">
            <div class="text-list__item-name">Водопотребление/водоотведение:</div>
            <div class="text-list__item-value"
                 :style="{'color': getDifferenceColor(building.attributes.fact_ratio)}">
              {{ getRatioPercentDifference(building.attributes.fact_ratio) }}
            </div>
          </div>
          <div v-if="building.attributes.consumption_ratio"
               class="text-list__item">
            <div class="text-list__item-name">Водопотребление/ТУ:</div>
            <div class="text-list__item-value"
                 :style="{'color': getDifferenceColor(building.attributes.consumption_ratio)}">
              {{ getRatioPercentDifference(building.attributes.consumption_ratio) }}
            </div>
          </div>
          <div v-if="building.attributes.drainage_ratio"
               class="text-list__item">
            <div class="text-list__item-name">Водоотведение/ТУ:</div>
            <div class="text-list__item-value"
                 :style="{'color': getDifferenceColor(building.attributes.drainage_ratio)}">
              {{ getRatioPercentDifference(building.attributes.drainage_ratio) }}
            </div>
          </div>
        </template>

      </div>
    </div>

    <base-paginate
      v-if="meta && meta.last_page > 1"
      :meta="meta"
      action="building/getBuildings"
      commit="building/SET_PAGE"
    />
  </div>
</template>

<script>
import BaseLoader from "@/layout/BaseLoader.vue";
import {mapGetters, mapState} from "vuex";
import BaseNoData from "@/components/BaseNoData.vue";
import IconMap from "@/components/Icons/IconMap.vue";
import BasePaginate from "@/components/BasePaginate.vue";
import {showAlert} from "@/utils/notification";

export default {
  name: "BillingBuildingsFilterResult",
  components: {BasePaginate, IconMap, BaseNoData, BaseLoader},
  props: ['filter_params'],
  computed: {
    ...mapState({
      meta: state => state.building.meta
    }),
    ...mapGetters({
      loading: 'building/getBuildingLoading'
    }),
    getFullAddress: function () {
      return building => {
        let full_address_arr = [building.attributes.city, building.attributes.settlement, building.attributes.street,
          building.attributes.house, building.attributes.block].filter(item => item !== null)
        return full_address_arr.join(', ')
      }
    }
  },
  methods: {
    getBuildingDetail(id) {
      this.$store.dispatch("building/getBuildingDetail", id)
          .then(() => {
            this.$store.commit('building/SET_TAB_INDEX', 2);
          })
          .catch(() => {
            showAlert('Ошибка', this.$store.state.building.error, 'error')
          })
    },
    getRatioPercentDifference(ratio) {
      let percentDifference = (ratio * 100) - 100
      if (ratio <= 1) {
        return percentDifference.toFixed(0) + '%'
      } else {
        return '+' + percentDifference.toFixed(0) + '%'
      }
    },
    getDifferenceColor(ratio) {
      if (ratio >= 0 && ratio <= 1.2) return '#04bd00'
      else if (ratio > 1.2 && ratio <= 2) return '#ffbf1c'
      else if (ratio > 2 && ratio <= 4) return '#ff6b1c'
      else if (ratio > 4) return '#E02800'
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers/index" as *;

.buildings-filter-result {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.building-item {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__address-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }

  &__address {
    cursor: pointer;
    @include font-style(400, 16px, 24px, var(--text-primary))
  }
}

.text-list {
  &__item {
    &-name {
      flex: 0.4;
    }
  }
}

.result-map {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.loader-position {
  position: absolute;
  right: 20px;
  top: 50px;
}
</style>
