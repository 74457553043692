<template>
  <div
    id="gallery"
    class="files-list"
    :class="{'files-list--float-right': isFilesMoreThenMax}"
    :style="{'gap': filesGap}">
    <a
      v-for="(file, index) in filesList"
      :key="file.id"
      :href="file.url"
      data-fancybox="gallery"
      :data-type="file.isVideoFile ? 'html5video' : ''"
      class="files-list__item"
      :class="{'files-list__item--hidden': index > maxLength - 1}">

      <video-preview
        v-if="file.isVideoFile"
        :index="index"
        :max-index="maxLength - 1"
        :url="file.url"/>

      <photo-preview
        v-else
        :index="index"
        :max-index="maxLength - 1"
        :url="file.url"/>

      <div
        v-if="isDeleteIconShown"
        @click.prevent="$emit('delete-file', file.id)"
        class="files-list__item-delete">
        <icon-close-square />
      </div>

      <div
        v-if="maxLength - 1 === index && isFilesMoreThenMax"
        class="files-list__last-item">
        +{{remainingFilesCount}}
      </div>
    </a>
  </div>
</template>

<script>
import PhotoPreview from "@/components/ServiceDesk/CommonComponents/FilesList/PhotoPreview.vue";
import VideoPreview from "@/components/ServiceDesk/CommonComponents/FilesList/VideoPreview.vue";
import IconCloseSquare from "@/components/Icons/IconCloseSquare.vue";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

export default {
  name: "FilesList",
  components: {IconCloseSquare, PhotoPreview, VideoPreview},
  props: {
    filesList: {
      type: Array,
      required: true
    },
    maxLength: {
      type: Number,
      default: 6
    },
    filesGap: {
      type: String,
      default: '8px'
    },
    isDeleteIconShown: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      gallery: null
    }
  },
  computed: {
    isFilesMoreThenMax() {
      return this.filesList.length > this.maxLength;
    },
    remainingFilesCount() {
      return this.filesList.length - this.maxLength;
    }
  },
  mounted() {
    Fancybox.bind('[data-fancybox="gallery"]');
  },
  beforeUnmount() {
    Fancybox.destroy();
  }
}
</script>

<style scoped>

</style>