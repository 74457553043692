<template>
  <base-popup
    title="Добавить утечку"
    :name="popupName">
    <template #body>
      <div class="form-item">
        <div class="form-item__name">Факт. дата начала</div>
        <div class="form-item__value form-item__value--column">
          <base-date-picker
            v-model="payload.actual_start_date"
            placeholder="Выберите дату и время"
            enable-time-picker
            :format="DATE_TIME_FORMAT"
            value-format="yyyy-MM-dd HH:mm"
            :flow="['calendar', 'time']"
          />
          <div class="form-item__value-tags">
            <div
              class="form-item__value-tag"
              v-for="tag in dateTags"
              :key="tag.value"
              @click="payload.actual_start_date = moment().add(tag.value, 'hours').format('YYYY-MM-DD HH:mm')"
            >{{ tag.label }}
            </div>
          </div>
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Факт. дата окончания</div>
        <div class="form-item__value form-item__value--column">
          <base-date-picker
            v-model="payload.actual_end_date"
            placeholder="Выберите дату и время"
            enable-time-picker
            :format="DATE_TIME_FORMAT"
            value-format="yyyy-MM-dd HH:mm"
            :flow="['calendar', 'time']"
          />
          <div class="form-item__value-tags">
            <div
              class="form-item__value-tag"
              v-for="tag in dateTags.filter((tag, index) => index)"
              :key="tag.value"
              @click="payload.actual_end_date = moment().add(tag.value, 'hours').format('YYYY-MM-DD HH:mm')"
            >{{ tag.label }}
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div @click="submitForm()"
           class="button">
        Добавить
      </div>
    </template>
  </base-popup>
</template>

<script>
import {showToast, showAlert} from "@/utils/notification";
import BasePopup from "@/components/BasePopup.vue";
import moment from "moment";
import BaseDatePicker from "@/components/BaseDatePicker.vue";

export default {
  name: "LeaksForm",
  components: {BaseDatePicker, BasePopup},
  props: {
    issueId: {
      type: Number,
      required: true
    },
    popupName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dateTags: [
        {value: 0, label: 'Сейчас'},
        {value: 2, label: '+2 ч'},
        {value: 4, label: '+4 ч'},
        {value: 8, label: '+8 ч'}
      ],
      payload: {
        actual_start_date: null,
        actual_end_date: null
      }
    }
  },
  computed: {
    DATE_TIME_FORMAT: () => process.env.VUE_APP_BASE_DATE_TIME_FORMAT
  },
  methods: {
    moment,
    submitForm() {
      this.$store.dispatch('leaks/createLeak', {
        issue_id: this.issueId,
        payload: this.payload
      })
        .then(() => {
          this.$store.dispatch('issueModal/getLeaksList', this.issueId);
          this.$store.dispatch('issueModal/getIssueHistory', this.issueId);
          this.$modal.hide(this.popupName);

          showToast('Утечка добавлена', 'success');
        })
        .catch(error => {
          showAlert('Ошибка', error, 'error');
        })
    }
  }
}
</script>

<style scoped>

</style>
