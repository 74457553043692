<template>
  <div
    @scroll="handleScroll"
    class="base-table base-table--responsive-x">
    <div v-if="isTableScroll"
         class="base-table__vertical-line">
      <div class="base-table__vertical-line__inner"/>
    </div>

    <div class="base-table__container">
      <div class="base-table__header base-table__row--energy-consumption-logbook">
        <div class="base-table__header-cell base-table__header-cell--fixed-left">Объект</div>
        <div class="base-table__header-cell">Счетчик</div>
        <div v-for="month in getMonths"
             :key="`${month}_header`"
             class="base-table__header-cell base-table__header-cell--capitalize base-table__header-cell--center">
          {{ month }}
        </div>
      </div>

      <template v-for="energyConsumptionObject in energyConsumptionObjects">
        <div
          v-for="electricMeter in energyConsumptionObject.electricMeters"
          :key="electricMeter.id"
          class="base-table__row base-table__row--energy-consumption-logbook">
          <div class="base-table__column base-table__column--fixed-left">
            <div class="base-table__text">
              {{ energyConsumptionObject.name }}
            </div>
          </div>
          <div class="base-table__column">
            <div class="base-table__text">{{ electricMeter.number }}</div>
          </div>
          <div
            v-for="(month, index) in getMonths"
            :key="month"
            class="base-table__column">
            <div class="electric-meter-reading">
              <input
                class="electric-meter-reading__input"
                :value="electricMeterMonthReading(electricMeter.id, index)"
                maxlength="11"
                type="text"
                placeholder="—"
                @change="$emit('save-electric-meter-reading', {
                  value: $event.target.value,
                  electricMeterId: electricMeter.id,
                  monthNumber: index + 1
                })"
                @keypress="isNumber($event)">
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {isNumber} from "@/utils/helpers";
import moment from "moment";

export default {
  name: "EnergyConsumptionLogbook",
  props: {
    energyConsumptionObjects: {
      type: Array,
      required: true
    },
    electricMeterMonthReading: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      isTableScroll: false
    }
  },
  computed: {
    getMonths() {
      return moment.months()
    }
  },
  methods: {
    isNumber,
    handleScroll() {
      const table = document.querySelector('.base-table');
      this.isTableScroll = table.scrollLeft > 0;

      const line = document.querySelector('.base-table__vertical-line__inner');
      const tableSizes = table.getBoundingClientRect();
      if (line) line.setAttribute('style', `height: ${tableSizes.height - 24}px`);
    }
  }
}
</script>

<style lang="scss" scoped>
.base-table__row--energy-consumption-logbook {
  grid-template-columns: 200px 100px repeat(12, 120px);
}

.electric-meter-reading {
  &__input {
    width: 100%;
    border: none;
    outline: none;
    padding: 12px;
    text-align: center;
    min-width: 120px;
    color: var(--text-primary);
    background-color: var(--panel);

    &:focus {
      border-radius: 16px;
      border: 1px solid var(--border-active);
      background: var(--panel);
      margin: -6px 0;
    }
  }
}
</style>
