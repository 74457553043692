<template>
  <base-popup
    :title="popupTitle"
    :name="popupName"
  >
    <template v-slot:body>
      <div class="form-item">
        <div class="form-item__name">Наименование датчика</div>
        <div class="form-item__value">
          <input
            v-model="v$.payload.sensor_name.$model"
            class="base-input base-input--bordered"
            :class="{'base-input--error': v$.payload.sensor_name.$error}"
            type="text"
            placeholder="Введите наименование">
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Краткое наименование датчика</div>
        <div class="form-item__value">
          <input
            v-model="v$.payload.sensor_short_name.$model"
            class="base-input base-input--bordered"
            :class="{'base-input--error': v$.payload.sensor_short_name.$error}"
            type="text"
            placeholder="Введите краткое наименование">
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Единицы измерения</div>
        <div class="form-item__value">
          <input
            v-model="v$.payload.sensor_measure.$model"
            class="base-input base-input--bordered"
            :class="{'base-input--error': v$.payload.sensor_measure.$error}"
            type="text"
            placeholder="Введите единицы измерения">
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Множитель</div>
        <div class="form-item__value">
          <input
            v-model="v$.payload.factor.$model"
            class="base-input base-input--bordered"
            :class="{'base-input--error': v$.payload.factor.$error}"
            type="text"
            placeholder="Введите множитель">
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Идентификатор OPC</div>
        <div class="form-item__value">
          <input
            v-model="v$.payload.opc_node_name.$model"
            class="base-input base-input--bordered"
            :class="{'base-input--error': v$.payload.opc_node_name.$error}"
            type="text"
            placeholder="Введите идентификатор OPC">
        </div>
      </div>

      <div class="form-item">
        <label class="base-switcher">
          <input
            v-model="payload.no_avg"
            class="base-switcher__checkbox"
            type="checkbox">
          <div class="base-switcher__switch"/>
          <span class="base-switcher__label">
            Не усреднять
          </span>
        </label>
      </div>
    </template>

    <template v-slot:footer>
      <div
        @click="checkFormType()"
        class="button">
        Сохранить
      </div>
    </template>
  </base-popup>
</template>

<script>
import {showToast, showAlert} from "@/utils/notification";
import BasePopup from "@/components/BasePopup.vue";
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import {mapState} from "vuex";

export default {
  name: "DeviceSensorsForm",
  components: {BasePopup},
  props: {
    sensor: {
      type: Object,
      required: false
    },
    deviceId: {
      type: Number,
      required: true
    },
    popupName: {
      type: String,
      required: true
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      payload: {
        device_id: null,
        sensor_name: null,
        sensor_short_name: null,
        sensor_measure: null,
        factor: null,
        no_avg: false,
        opc_node_name: null
      }
    }
  },
  validations() {
    return {
      payload: {
        device_id: {required},
        sensor_name: {required},
        sensor_short_name: {required},
        sensor_measure: {required},
        factor: {required},
        opc_node_name: {required}
      }
    }
  },
  computed: {
    ...mapState({
      networkObject: state => state.networkObjectPage.networkObject
    }),
    popupTitle() {
      return !Object.keys(this.sensor) ? 'Создать датчик' : 'Редактирование датчика'
    }
  },
  mounted() {
    this.payload.device_id = this.deviceId;

    if (Object.keys(this.sensor).length) {
      const {
        sensor_name,
        sensor_short_name,
        sensor_measure,
        factor,
        no_avg,
        opc_node_name
      } = this.sensor;

      this.payload.sensor_name = sensor_name;
      this.payload.sensor_short_name = sensor_short_name;
      this.payload.sensor_measure = sensor_measure;
      this.payload.factor = factor;
      this.payload.no_avg = !!no_avg;
      this.payload.opc_node_name = opc_node_name;
    }
  },
  methods: {
    checkFormType() {
      this.v$.$touch()
      if (this.v$.$invalid) return false

      if (!Object.keys(this.sensor).length) this.createSensor()
      else this.updateSensor()
    },
    createSensor() {
      this.$store.dispatch('sensor/createSensor', this.payload)
          .then(() => {
            this.$modal.hide(this.popupName);
            this.$store.dispatch('networkObjectPage/getNetworkObjectDevices', this.networkObject.id)
              .then(() => this.$store.dispatch('networkObjectPage/getDevice', this.deviceId))
            showToast('Датчик создан', 'success')
          })
          .catch(error => showAlert('Ошибка', error));
    },
    updateSensor() {
      this.$store.dispatch('sensor/updateSensor', {
        sensorId: this.sensor.id,
        payload: this.payload
      })
          .then(() => {
            this.$modal.hide(this.popupName);
            this.$store.dispatch('networkObjectPage/getNetworkObjectDevices', this.networkObject.id)
              .then(() => this.$store.dispatch('networkObjectPage/getDevice', this.deviceId))
            showToast('Датчик обновлен', 'success')
          })
          .catch(error => showAlert('Ошибка', error));
    }
  }
}
</script>

<style scoped>

</style>