<template>
  <div class="i-modal__content-wrapper">
    <div class="i-modal__content">
      <div class="i-modal__inner">
        <div class="base-modal__left-side">
          <div class="base-modal-header">
            <span class="base-modal-title">
              {{getPropData('isEditForm') ? 'Редактировать ' + vehicle.attributes.label : 'Новый транспорт'}}
            </span>
          </div>
          <div class="base-modal-info base-modal-info--centered-form">
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <span>Наименование ТС</span>
              </div>
              <div class="base-modal-info__value">
                <input
                  type="text"
                  v-model="v$.payload.label.$model"
                  class="base-input base-input--bordered"
                  :class="{'base-input--error': v$.payload.label.$error}"
                  placeholder="Введите наименование ТС"
                >
              </div>
            </div>

            <div
              v-if="getPropData('isEditForm')"
              class="base-modal-info__item">
              <div class="base-modal-info__name">
                <span>Структурное подразделение</span>
              </div>
              <div class="base-modal-info__value">
                <v-select
                  class="vue-select-input"
                  v-model="payload.structural_unit_id"
                  :options="structuralUnitsList"
                  :reduce="structuralUnit => structuralUnit.id"
                  :get-option-label="(option) => option.name"
                  placeholder="Выберите источник"
                />
              </div>
            </div>

            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <span>Тип транспорта</span>
              </div>
              <div class="base-modal-info__value">
                <div class="tags-select">
                  <div v-for="type in transportTypes"
                       :key="type.id"
                       @click="payload.type = type.id"
                       class="tags-select__item"
                       :class="{
                         'selected': payload.type === type.id,
                         'tags-select__item--error': v$.payload.type.$error
                       }">
                    {{ type.name }}
                  </div>
                </div>
              </div>
            </div>

            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <span>Гос. номер</span>
              </div>
              <div class="base-modal-info__value">
                <input
                  type="text"
                  v-model="v$.payload.gov_number.$model"
                  class="base-input base-input--bordered"
                  :class="{'base-input--error': v$.payload.gov_number.$error}"
                  placeholder="Введите гос. номер"
                >
              </div>
            </div>

            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <span>Внешний ID</span>
              </div>
              <div class="base-modal-info__value">
                <input
                  type="text"
                  v-model="payload.external_id"
                  class="base-input base-input--bordered"
                  placeholder="Введите ID"
                >
              </div>
            </div>

            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <span>Источник данных</span>
              </div>
              <div class="base-modal-info__value">
                <v-select
                  class="vue-select-input"
                  v-model="payload.data_source"
                  :options="transportDataSources"
                  :reduce="dataSource => dataSource.id"
                  :get-option-label="(option) => option.name"
                  placeholder="Выберите источник"
                />
              </div>
            </div>

            <div class="base-modal-btn-wp base-modal-btn-wp--many">
              <button
                v-if="getPropData('isEditForm')"
                @click="updateVehicle()"
                class="button button--mode-primary">
                <span>Изменить</span>
              </button>
              <button
                v-else
                @click="createVehicle()"
                class="button button--mode-primary">
                <span>Создать</span>
              </button>
              <div @click="cancelVehicleCreate()"
                   class="button button--mode-outline">
                <span>Отменить</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="i-modal-btn-wp i-modal-btn-wp--back">
      <div class="task-modal__mobile-actions">
        <button @click="$store.dispatch('baseSideModal/closeModal')"
                class="button button--mode-outline task-modal__mobile-back">
          <icon-arrow-left/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrowLeft from "@/components/Icons/IconArrowLeft.vue";
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Vehicle from "@/models/transport/Vehicle";
import {mapGetters, mapState} from "vuex";
import {showToast, showAlert} from "@/utils/notification";

export default {
  name: "TransportFormModalContent",
  components: {
    IconArrowLeft
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      payload: {
        label: null,
        type: null,
        gov_number: null,
        external_id: null,
        data_source: null,
        structural_unit_id: null
      }
    }
  },
  validations() {
    return {
      payload: {
        label: {required},
        type: {required},
        gov_number: {required}
      }
    }
  },
  computed: {
    transportTypes: () => Vehicle.getTransportTypes(),
    ...mapState({
      vehicle: state => state.autos.auto,
      transportDataSources: state => state.transportDataServers.dataServers,
      structuralUnitsList: state => state.structuralUnits.structuralUnits
    }),
    ...mapGetters({
      getPropData: 'baseSideModal/getPropData',
      structuralUnitName: 'structuralUnits/getStructuralUnitName'
    })
  },
  mounted() {
    this.$store.dispatch('transportDataServers/getAllTransportDataServers');

    if (this.getPropData('isEditForm')) {
      this.$store.dispatch('structuralUnits/getStructuralUnitsList');

      this.payload.label = this.vehicle.attributes.label;
      this.payload.type = this.vehicle.attributes.type;
      this.payload.gov_number = this.vehicle.attributes.gov_number;
      this.payload.external_id = this.vehicle.attributes.external_id;
      this.payload.data_source = this.vehicle.attributes.data_source_id;
      this.payload.structural_unit_id = this.vehicle.attributes.structural_unit.id;
    }
  },
  methods: {
    createVehicle() {
      if (this.v$.$invalid) this.v$.$touch();
      else {
        this.v$.$reset();
        this.$store.dispatch('autos/createAuto', this.payload)
          .then(() => {
            this.$store.dispatch('baseSideModal/goBack');
            this.$store.dispatch('autos/getAutos');
            showToast('Транспорт добавлен', 'success');
          })
          .catch(error => showAlert('Ошибка', error))
      }
    },
    updateVehicle() {
      if (this.v$.$invalid) this.v$.$touch();
      else {
        this.v$.$reset();
        this.$store.dispatch('autos/updateAuto', {
          autoId: this.vehicle.id,
          payload: this.payload
        })
          .then(() => {
            this.$store.dispatch('autos/getAutos')
              .then(() => this.$store.dispatch('baseSideModal/goBack'));
            showToast('Транспорт обновлен', 'success');
          })
          .catch(error => showAlert('Ошибка', error))
      }
    },
    cancelVehicleCreate() {
      this.$store.dispatch('baseSideModal/goBack');
    }
  }
}
</script>

<style scoped>

</style>