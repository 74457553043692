<template>
  <div class="base-table">
    <div
      v-if="tasksList.length"
      class="base-table__container">
      <div class="base-table__header base-table__row--tech-inspection-task">
        <div class="base-table__header-cell">#</div>
        <div class="base-table__header-cell">Дата обхода</div>
        <div class="base-table__header-cell">Обходчик</div>
        <div class="base-table__header-cell">Статус</div>
      </div>

      <div v-for="task in tasksList"
           :key="task.id"
           class="base-table__row base-table__row--tech-inspection-task"
           @click="openTaskModal(task.id)"
      >
        <div class="base-table__column ">
          <div class="base-table__text">{{ task.id }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ formatDate(task.finishedDate) }}</div>
        </div>
        <div class="base-table__column ">
          <div class="base-table__text">{{ task.resource.name }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">
            <div class="technical-inspection-task-state">{{ task.state.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {formatDate} from "@/utils/filters";

export default {
  name: "TechnicalInspectionObjectTasksList",
  props: {
    tasksList: {
      type: Array,
      required: true
    }
  },
  methods: {
    formatDate,
    openTaskModal(taskId) {
      this.$store.commit('technicalInspectionTasks/SET_TASKS_LIST', []);
      this.$store.dispatch('baseSideModal/openModal', {
        modalContentType: 'technicalInspectionTask',
        entityId: taskId
      });
    }
  }
}
</script>

<style scoped>
.technical-inspection-task-state {
  background-color: var(--status-completed);
  color: var(--text-panel);
  padding: 4px 8px;
  border-radius: 8px;
}

.base-table__row--tech-inspection-task {
  grid-template-columns: 1fr repeat(3, 2fr);
}
</style>
