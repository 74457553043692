<template>
  <div class="additional-options-list__inner">
    <div v-for="leak in leaksList"
         :key="leak.id"
         class="added-item added-item--leak">
      <div class="leak__item">
        <div class="added-item__number">
          #{{leak.id}}
        </div>
        <div class="added-item__info">
          <div class="added-item__name">
            <template v-if="!leak.damage">Утечка</template>
            <template v-else>Утечка связана с повреждением #{{leak.damage.id}}: {{leak.damage.defect.name}}</template>
          </div>
          <div class="added-item__additional">
            <div v-if="leak.actualStartDate"
                 class="added-item__additional-time">
              {{formatDateTime(leak.actualStartDate)}}
            </div>
            <div class="added-item__additional-time">-</div>
            <div v-if="leak.actualEndDate"
                 class="added-item__additional-time">
              {{ formatDateTime(leak.actualEndDate)}}
            </div>
          </div>
        </div>
        <div class="added-item__right">
          <button
            v-if="!leak.damage && isIssueActive"
            @click="showAddDamageModal(leak)"
            type="button"
            class="button button--mode-outline button--mode-skinny">
            Добавить повреждение
          </button>
          <div class="leak__buttons">
            <div v-if="leak.actualEndDate"
                 class="leak__volume">Потери: {{ leak.volume.toFixed(2) }} м<sup>3</sup>
            </div>
            <div v-if="!leak.actualEndDate"
                 @click="finishLeak(leak.id)"
                 @mouseenter="isButtonHovered = true"
                 @mouseleave="isButtonHovered = false"
                 class="leak__state">
              <span v-if="isButtonHovered">Закончить</span>
              <span v-else>
                <div class="leak__state-indicator" />
                Активна
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="leak__damages-list">
        <div
          v-for="damage in leak.damagesList"
          :key="damage.id"
          class="leak__damage">
          <div class="added-item__info">
            <icon-flash/>
          </div>
          <div class="added-item__info">
            <div class="added-item__name">
              {{ damage.defect.name }}

              <div v-if="damage.isDefectWithWormholes"
                   class="added-item__name--inline">
                {{ damage.defectParams.wormhole_count }} шт.
              </div>
              <div v-else-if="damage.isDefectWithFlaw || damage.isDefectWithBreak"
                   class="added-item__name--inline">
                {{ damage.defectParams.width }}x{{ damage.defectParams.height }}мм
              </div>
              <div v-else-if="damage.isDefectWithHole"
                   class="added-item__name--inline">
                Ø{{ damage.defectParams.diameter }}мм
              </div>
            </div>
            <div class="added-item__additional">
              <div v-if="damage.startDate"
                   class="added-item__additional-time">
                {{formatDateTime(damage.startDate)}}
              </div>
              <div class="added-item__additional-time">-</div>
              <div v-if="damage.endDate"
                   class="added-item__additional-time">
                {{ formatDateTime(damage.endDate)}}
              </div>
            </div>
          </div>
          <div class="added-item__right">
            <div
              v-if="isIssueActive"
              @click="showAddDamageModal(leak, damage)"
            >
              <icon-pencil class="svg added-item__tool added-item__tool--size-20" />
            </div>
            <div
              v-if="isIssueActive"
              @click="deleteDamage(leak.id, damage.id)"
            >
              <icon-trash class="svg added-item__tool"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="isIssueActive"
      @click="showAddLeaksModal()"
      class="button button--mode-outline">
      <icon-plus/>
      <span>Добавить новую</span>
    </div>
  </div>
</template>

<script>
import {modalAdaptiveWidth} from "@/utils/helpers";
import LeaksForm from "@/components/ServiceDesk/Issues/IssueComponents/Leaks/LeaksForm";
import {showAlert, showToast} from "@/utils/notification";
import IconPlus from "@/components/Icons/IconPlus.vue";
import {formatDateTime} from "@/utils/filters";
import DamagesForm from "@/components/ServiceDesk/Issues/IssueComponents/Damages/DamagesForm.vue";
import IconFlash from "@/components/Icons/IconFlash.vue";
import IconTrash from "@/components/Icons/IconTrash.vue";
import IconPencil from "@/components/Icons/IconPencil.vue";
import moment from "moment";
export default {
  name: "LeaksList",
  components: {IconPencil, IconTrash, IconFlash, IconPlus},
  props: {
    issueId: {
      type: Number,
      required: true
    },
    leaksList: {
      type: Array,
      required: true
    },
    isIssueActive: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isButtonHovered: false,
      damagePopupName: 'damage-popup'
    }
  },
  methods: {
    formatDateTime,
    showAddDamageModal(leak, damage = {}) {
      this.$modal.show(
        DamagesForm,
        {
          'leak': leak,
          'damage': damage,
          'popupName': this.damagePopupName,
          'createDamage': this.createDamage,
          'updateDamage': this.updateDamage
        },
        {
          name: this.damagePopupName,
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(1144),
          scrollable: true
        }
      )
    },
    showAddLeaksModal() {
      this.$modal.show(
        LeaksForm,
        {
          'issue-id': this.issueId,
          'popupName': 'issue-leaks-modal'
        },
        {
          name: 'issue-leaks-modal',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460),
          scrollable: true
        }
      )
    },
    finishLeak(leakId) {
      this.$store.dispatch('leaks/finishLeak', {
        issueId: this.issueId,
        payload: {
          leak_id: leakId,
          actual_end_date: moment().format('YYYY-MM-DD HH:mm')
        }
      })
          .then(() => {
            this.$store.dispatch('issueModal/getLeaksList', this.issueId);
            showToast('Утечка завершена', 'success');
          })
          .catch(error => showAlert('Ошибка', error))
    },
    createDamage(leakId, payload) {
      this.$store.dispatch('damages/createDamage', {
        issueId: this.issueId,
        leakId,
        payload
      })
        .then(() => {
          this.$store.dispatch('issueModal/getLeaksList', this.issueId);
          this.$modal.hide(this.damagePopupName);
          showToast('Повреждение добавлено', 'success');
        })
        .catch(error => showAlert('Ошибка', error));
    },
    updateDamage(leakId, damageId, payload) {
      this.$store.dispatch('damages/updateDamage', {
        issueId: this.issueId,
        leakId,
        damageId,
        payload
      })
        .then(() => {
          this.$store.dispatch('issueModal/getLeaksList', this.issueId);
          this.$modal.hide(this.damagePopupName);
          showToast('Повреждение обновлено', 'success');
        })
        .catch(error => showAlert('Ошибка', error));
    },
    deleteDamage(leakId, damageId) {
      this.$store.dispatch('damages/deleteDamage', {
        issueId: this.issueId,
        leakId,
        damageId
      })
        .then(() => {
          this.$store.dispatch('issueModal/getLeaksList', this.issueId);
          showToast('Повреждение удалено', 'success');
        })
        .catch(error => showAlert('Ошибка', error));
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers/index" as *;

.added-item--leak {
  display: flex;
  flex-direction: column;
  align-items: normal;
}

.leak {
  &__item, &__damage {
    display: grid;
    grid-template-columns: auto 1fr auto;
    justify-content: flex-start;
    align-items: center;
    column-gap: 32px;
    width: 100%;
  }

  &__damage {
    margin-top: 12px;
    padding-top: 12px;
    padding-left: 12px;
    border-top: 1px solid var(--border-main);
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 24px;
  }

  &__volume {
    padding: 6px 12px;
    border-radius: 8px;
    background-color: var(--panel-bg);

    @include font-style(600, 14px, 16px, var(--text-primary))
  }

  &__state {
    border: 1px solid var(--border-main);
    border-radius: 8px;
    padding: 6px 12px;
    cursor: pointer;

    @include font-style(400, 14px, 16px, var(--text-primary));

    & span {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  &__state-indicator {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--bg-accent);
  }
}
</style>
