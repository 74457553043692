<template>
  <div class="history__wrapper">
    <div class="history__header">История изменений</div>

    <ul class="history__list">
      <li v-for="historyItem in historyList"
          :key="historyItem.id"
          class="history__item">
        <div class="history__message">{{historyItem.message}}</div>
        <div class="history__info">
          {{historyItem.userName}} {{ formatDateTime(historyItem.historyDate) }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {formatDateTime} from "@/utils/filters";

export default {
  name: "HistoryList",
  props: {
    historyList: {
      type: Array,
      required: true
    }
  },
  methods: {formatDateTime}
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers/index" as *;

.history {
    &__header {
      margin-bottom: 32px;

      @include font-style(500, 20px, 28px, var(--text-primary));
    }

  &__list {
    list-style: none;
    padding: 0;
  }

  &__item {
    position: relative;
    padding-left: 24px;
    padding-bottom: 22px;
    border-left: 2px solid var(--border-main);
    margin-bottom: 2px;

    &::before {
      content: "";
      width: 8px;
      height: 8px;

      position: absolute;
      top: 0px;
      left: -5px;

      background-color: var(--bg-secondary);
      border-radius: 50%;
    }

    &:last-child {
      border: none;
      margin-left: 2px;
    }
  }

  &__message {
    margin-bottom: 4px;

    @include font-style(400, 16px, 16px, var(--text-primary));
  }

  &__info {
    @include font-style(400, 14px, 16px, var(--text-secondary));
  }
}
</style>
