<template>
  <div class="tasks-filter__wrapper">
    <div class="filter__button tasks-filter__button"
         :class="{'opened': isFilterModalVisible}"
         @click="isFilterModalVisible = true"
         data-modal-toggle="base-filter-modal">
      <icon-settings2 />
      <span>Фильтры</span>
    </div>

    <base-filter-modal
      :is-visible="isFilterModalVisible"
      @toggle-modal="isVisible => this.isFilterModalVisible = isVisible">
      <div class="filter__modal-header">
        <div class="filter__modal-name">Фильтры</div>
        <div @click="resetFilters()"
             class="filter__modal-reset">Сбросить все</div>
      </div>
      <div class="filter__modal-form">
        <div>
          <label class="input-label">Дата создания</label>
          <base-date-picker
            v-model="filter.date"
            value-format="yyyy-MM-dd"
            placeholder="Выберите дату"
          />
        </div>
        <div>
          <label class="input-label">Тип задания</label>
          <v-select
            class="vue-select-input"
            v-model="filter.type"
            :options="taskTypes"
            :reduce="type => type.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите из списка"
          />
        </div>
      </div>
      <div class="filter__modal-buttons">
        <div @click="submitFilter()"
             class="button">Применить</div>
        <div @click="isFilterModalVisible = false"
             class="button button--mode-outline">Закрыть</div>
      </div>
    </base-filter-modal>

  </div>
</template>

<script>
import IconSettings2 from "@/components/Icons/IconSettings2";
import BaseFilterModal from "@/components/BaseFilterModal";
import {mapState} from "vuex";
import BaseDatePicker from "@/components/BaseDatePicker.vue";

export default {
  name: "TasksListFilter",
  components: {BaseDatePicker, BaseFilterModal, IconSettings2},
  data() {
    return {
      filter: {
        date: null,
        type: null
      },
      isFilterModalVisible: false
    }
  },
  computed: {
    ...mapState({
      taskTypes: state => state.task_types.task_types
    })
  },
  mounted() {
    this.$store.dispatch('task_types/getAllTaskTypes');
  },
  methods: {
    setFilterParamsValues() {
      Object.keys(this.filter).forEach(key => {
        this.$store.commit('tasks/SET_FILTER_PARAM', {
          name: key,
          value: this.filter[key]
        });
      })
    },
    submitFilter() {
      this.$store.commit('tasks/SET_TASKS', []);
      this.setFilterParamsValues();

      this.$store.dispatch('tasks/getTasks');
      this.isFilterModalVisible = false;
    },
    resetFilters() {
      this.$store.commit('tasks/SET_TASKS', []);
      this.filter = {
        date: null,
        type: null
      }
      this.setFilterParamsValues();

      this.$store.dispatch('tasks/getTasks');
      this.isFilterModalVisible = false;
    }
  }
}
</script>

<style scoped lang="scss">
@use "@/assets/scss/helpers/index" as *;

.tasks-filter {
  &__wrapper {
    position: relative;
  }

  &__button {
    width: 484px;
  }
}

@media (max-width: $mobile-big) {
  .tasks-filter__button {
    width: 100%;
    padding: 12px;
  }
}
</style>
