import Question from "@/models/TechnicalInspection/Question";

export const answerByQuestionId = (questionId, answersList) => {
    const answer = answersList.find(answer => answer.questionId === questionId);
    return answer ? answer : null;
}

export const dictionaryAnswer = (dictionaryItems, answer) => {
    return answer ? dictionaryItems.find(item => item.id === answer) : null;
}

export const isAnswerTypeDictionary = answerType => {
    return answerType === Question.ANSWER_DICTIONARY.id;
}
