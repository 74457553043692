<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      Заказать транспорт
      <span @click="$modal.hide('transport-order-form')"
            class="base-popup__close">
        <icon-close />
      </span>
    </div>
    <div class="popup-form-group">
      <div class="form-item">
        <div class="form-item__name">Необходимый тип транспорта</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.transport_type"
            :options="transportTypes"
            :reduce="type => type.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите из списка"
            class="vue-select-input"
          />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Необходимый транспорт</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.transport_id"
            :options="vehicles.filter(vehicle => vehicle.attributes.type === payload.transport_type)"
            :reduce="vehicle => vehicle.id"
            :get-option-label="(option) => option.attributes.label + ' ' + option.attributes.gov_number"
            placeholder="Выберите из списка"
            class="vue-select-input"
          />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Плановая дата начала работ</div>
        <div class="form-item__value">
          <base-date-picker
            v-model="payload.planned_start_date"
            placeholder="Выберите дату и время"
            enable-time-picker
            :format="DATE_TIME_FORMAT"
            value-format="yyyy-MM-dd HH:mm"
            :flow="['calendar', 'time']"
          />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Плановая дата окончания работ</div>
        <div class="form-item__value">
          <base-date-picker
            v-model="payload.planned_end_date"
            placeholder="Выберите дату и время"
            enable-time-picker
            :format="DATE_TIME_FORMAT"
            value-format="yyyy-MM-dd HH:mm"
            :min-date="payload.planned_start_date"
            :flow="['calendar', 'time']"
          />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Комментарий</div>
        <div class="form-item__value">
          <textarea
            v-model="payload.comment"
            class="base-textarea"
            type="text"
            name="material"
            placeholder="Введите комментарий"/>
        </div>
      </div>
    </div>
    <div class="base-popup__footer">
      <div @click="submitForm()"
           class="button">
        Добавить
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import Vehicle from "@/models/transport/Vehicle";
import {showAlert, showToast} from "@/utils/notification";
import IconClose from "@/components/Icons/IconClose";
import BaseDatePicker from "@/components/BaseDatePicker.vue";

export default {
  name: "TransportOrdersForm",
  components: {BaseDatePicker, IconClose},
  props: {
    taskId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      payload: {
        transport_type: null,
        transport_id: null,
        planned_start_date: null,
        planned_end_date: null,
        comment: null
      }
    }
  },
  computed: {
    ...mapState({
      vehicles: state => state.autos.autos,
      task: state => state.task.task
    }),
    transportTypes: () => Vehicle.getTransportTypes(),
    DATE_TIME_FORMAT: () => process.env.VUE_APP_BASE_DATE_TIME_FORMAT
  },
  mounted() {
    this.$store.dispatch('autos/getAllAutos', {state: 1});
  },
  methods: {
    submitForm() {
      this.payload.task_id = this.taskId;
      this.$store.dispatch('transportOrders/createTransportOrder', this.payload)
        .then(() => {
          this.$store.dispatch('taskModal/getTransportOrdersList', this.taskId);
          showToast('Транспорт заказан', 'success');
          this.$modal.hide('transport-order-form');
        })
        .catch(error => showAlert('Ошибка', error));
    },
    notBeforeStartDate(date) {
      let startDate = new Date(this.payload.planned_start_date);
      startDate.setDate(startDate.getDate() - 1);
      return date < startDate;
    }
  }
}
</script>

<style scoped>
.form-item__name {
  width: 250px;
}
</style>
