<template>
  <div class="answer">{{answer ? answer.value : '—'}}</div>
</template>

<script>
export default {
  name: "AnswerText",
  props: {
    answer: {
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers/index" as *;

.answer {
  @include font-style(700, 16px, 24px, var(--text-primary));
}
</style>
