<template>
  <base-popup
    :title="popupTitle"
    :name="popupName"
  >
    <template #body>
      <div class="form-item">
        <div class="form-item__name">Наименование</div>
        <div class="form-item__value">
          <input
            v-model="v$.payload.name.$model"
            class="base-input base-input--bordered"
            :class="{'base-input--error': v$.payload.name.$error}"
            type="text"
            placeholder="Введите наименование">
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Руководитель</div>
        <div class="form-item__value">
          <input
            v-model="v$.payload.head_name.$model"
            class="base-input base-input--bordered"
            :class="{'base-input--error': v$.payload.head_name.$error}"
            type="text"
            placeholder="Введите ФИО">
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Кому подчиняется</div>
        <div class="form-item__value">
          <v-select
            class="vue-select-input"
            v-model="payload.parent_id"
            :options="structuralUnitsList"
            :reduce="structuralUnit => structuralUnit.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите из списка"/>
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Тип</div>
        <div class="form-item__value">
          <v-select
            class="vue-select-input"
            v-model="payload.type_id"
            :options="typesList"
            :reduce="type => type.id"
            :get-option-label="(option) => option.label"
            placeholder="Выберите из списка"
          />
        </div>
      </div>
    </template>

    <template #footer>
      <div
        @click="checkFormType()"
        class="button">
        Сохранить
      </div>
    </template>
  </base-popup>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import BasePopup from "@/components/BasePopup.vue";

export default {
  name: "StructuralUnitsForm",
  components: {BasePopup},
  props: {
    structuralUnit: {
      type: Object,
      required: true
    },
    createStructuralUnit: {
      type: Function,
      default: () => {}
    },
    updateStructuralUnit: {
      type: Function,
      default: () => {}
    },
    popupName: {
      type: String,
      required: true
    },
    structuralUnitsList: {
      type: Array,
      required: true
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      payload: {
        name: null,
        head_name: null,
        parent_id: null,
        type_id: null
      },
      typesList: [
        {id: 1, label: 'Внешнее'},
        {id: 2, label: 'Внутреннее'}
      ]
    }
  },
  validations() {
    return {
      payload: {
        name: {required},
        head_name: {required},
        type_id: {required}
      }
    }
  },
  computed: {
    popupTitle() {
      return !Object.keys(this.structuralUnit).length ?
        'Добавить структурное подразделение' : 'Редактирование структурного подразделения'
    }
  },
  mounted() {
    if (Object.keys(this.structuralUnit).length) {
      const {name, headName, parentId, type} = this.structuralUnit;
      this.payload.name = name;
      this.payload.head_name = headName;
      this.payload.parent_id = parentId;
      this.payload.type_id = type.id;
    }
  },
  methods: {
    checkFormType() {
      this.v$.$touch()
      if (this.v$.$invalid) return false

      if (!Object.keys(this.structuralUnit).length) this.createStructuralUnit(this.payload)
      else this.updateStructuralUnit(this.structuralUnit.id, this.payload)
    }
  }
}
</script>

<style scoped>

</style>
