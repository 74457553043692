<template>
  <div class="i-modal__content-wrapper">
    <div class="i-modal__content">
      <div v-if="Object.keys(issue).length && !issueLoading"
           class="i-modal__inner">
        <div :class="{'base-modal__left-side--hidden': isHistoryVisible}"
             class="base-modal__left-side">
          <div class="base-modal-header base-modal-header--padding-s">
            <span class="base-modal-title">Заявка #{{ issue.id }}</span>
            <div class="base-modal-header__status">
              <div class="application-status-wrapper">
                <div class="application-status"
                     :class="[
                       {'base-table__tag--new': issue.state.id === IssueState.NEW},
                       {'base-table__tag--work': issue.state.id === IssueState.IN_WORK},
                       {'base-table__tag--done': issue.state.id === IssueState.CLOSED},
                       {'base-table__tag--canceled': issue.state.id === IssueState.CANCELED}
                     ]">
                  {{ issue.state.label }}
                </div>
              </div>
              <issue-export :issue="issue"/>
              <div @click="$store.commit('baseSideModal/TOGGLE_MODAL_HORIZONTAL')"
                   class="base-modal-header__history-toggle"
                   :class="{'active': isHistoryVisible}">
                <icon-history-toggle/>
              </div>
            </div>
          </div>
          <div class="base-modal-info base-modal-info--gap-20 base-modal-info--padding-s">
            <div class="base-modal-info__card base-modal-info__card--padding-s">
              <base-collapse>
                <template #toggle="{toggle, isCollapsed}">
                  <div class="base-modal-info__card-title base-modal-info__card-title--loader">
                    <span class="base-modal-info__card-collapse"
                          :class="{'base-modal-info__card-collapse--closed': isCollapsed}"
                          @click="toggle()">
                      Детальная информация
                      <icon-arrow-down/>
                    </span>

                    <div
                      v-if="issue.state.isNew"
                      @click="openEditIssueModal()"
                      class="base-modal-info__card-title-subtext">
                      <icon-pencil/>
                      <span>Редактировать</span>
                    </div>
                  </div>
                </template>

                <template #body>
                  <div>
                    <div class="base-modal-info__item">
                      <div class="base-modal-info__name">
                        <span>Основание</span>
                      </div>
                      <div class="base-modal-info__value">
                        <p class="base-modal-info__value-txt">
                          {{ issue.based_on }}
                        </p>
                      </div>
                    </div>

                    <div class="base-modal-info__item">
                      <div class="base-modal-info__name">
                        <span>Проблема</span>
                      </div>
                      <div class="base-modal-info__value">
                        <p class="base-modal-info__value-text base-modal-info__value-text--space-b">
                          {{ issue.category.name }}
                          <span class="base-modal-info__text-divider">|</span>
                          {{ issue.category.group_name }}
                        </p>
                        <p class="base-modal-info__value-text">{{ issue.description }}</p>
                      </div>
                    </div>

                    <div class="base-modal-info__item">
                      <div class="base-modal-info__name">
                        <span>Адрес</span>
                      </div>
                      <div class="base-modal-info__value">
                        <p class="base-modal-info__value-text">
                          {{ issue.getFullAddress() ? issue.getFullAddress() : 'Адрес не указан' }}
                          <span
                            v-if="!isMapVisible"
                            @click="toggleMapVisible()"
                            class="base-modal-info__value--link">
                            На карте
                          </span>
                          <span
                            v-else
                            @click="toggleMapVisible()"
                            class="base-modal-info__value--link">
                            Скрыть карту
                          </span>
                        </p>

                        <issue-map
                          :issue="issue"
                          :is-map-visible="isMapVisible"
                        />
                      </div>
                    </div>

                    <div class="base-modal-info__item">
                      <div class="base-modal-info__name">
                        <span>Плановый срок</span>
                      </div>
                      <div class="base-modal-info__value">
                        <p class="base-modal-info__value-text">
                          {{ issue.plannedDatesRangeCaption }}
                        </p>
                      </div>
                    </div>

                    <div class="base-modal-info__item">
                      <div class="base-modal-info__name">
                        <span>Связанные обращения</span>
                      </div>
                      <div class="base-modal-info__value">
                        <related-appeals-form :issue-id="issue.id"/>
                        <related-appeals-list
                          :appeals-list="issue.linkedAppealsList"
                          :issue-id="issue.id"/>
                      </div>
                    </div>

                    <div class="base-modal-info__item">
                      <div class="base-modal-info__name">
                        <span>Связанные заявки</span>
                      </div>
                      <div class="base-modal-info__value">
                        <related-issues-list :related-issues-list="issue.relatedIssuesList"/>
                        <div @click="openCreateIssueModal({
                               relatedIssueId: issue.id
                             })"
                             class="create-related-issue-btn">
                          Создать связанную
                        </div>
                      </div>
                    </div>

                    <div class="base-modal-info__item">
                      <div class="base-modal-info__name">
                        <span>Структ. подразделение</span>
                      </div>
                      <div class="base-modal-info__value">
                        <p class="base-modal-info__value-txt">
                          {{ issue.structuralUnit.name }}
                        </p>
                      </div>
                    </div>

                    <div class="base-modal-info__item">
                      <div class="base-modal-info__name">
                        <span>Создал</span>
                      </div>
                      <div class="base-modal-info__value">
                        <p class="base-modal-info__value-txt">
                          {{ firstHistoryItem.userName }},
                          <span>{{ formatDateTime(firstHistoryItem.historyDate) }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </template>
              </base-collapse>
            </div>

            <div
              v-if="issue.attachments.length"
              class="issue-files-list">
              <div class="issue-files-list__title">Фото и видео</div>
              <files-list
                :files-list="issue.attachments"
                :max-length="10"
              />
            </div>

            <div class="tabs">
              <div class="tabs__header tabs__header--block">
                <div
                  @click="activeTab = 0"
                  :class="{'active': activeTab === 0}"
                  class="tabs__header-item">Задания
                </div>
                <div
                  @click="activeTab = 2"
                  :class="{'active': activeTab === 2}"
                  class="tabs__header-item">Утечки
                </div>
                <div
                  @click="activeTab = 3"
                  :class="{'active': activeTab === 3}"
                  class="tabs__header-item">Отключения
                </div>
              </div>

              <div :class="{'active': activeTab === 0}"
                   class="tabs__item">
                <issue-tasks-list
                  :issue-id="issue.id"
                  :tasks-list="issue.tasks"
                  :is-issue-active="issue.state.isActive"
                  :issue-category-group="issue.category.group_id"
                />
              </div>
              <div
                :class="{'active': activeTab === 2}"
                class="tabs__item">
                <leaks-list
                  :leaks-list="leaksList"
                  :issue-id="issue.id"
                  :is-issue-active="issue.state.isActive"
                />
              </div>
              <div
                :class="{'active': activeTab === 3}"
                class="tabs__item">
                <shutdowns-list
                  :issue-id="issue.id"
                  :shutdowns-list="shutdownsList"
                  :is-issue-active="issue.state.isActive"
                />
              </div>
            </div>

            <div v-if="issue.state.id === IssueState.NEW || issue.state.id === IssueState.IN_WORK"
                 class="base-modal-btn-wp">
              <issue-state-actions
                :issue-id="issue.id"
                :issue-state-id="issue.state.id"/>
            </div>

          </div>

        </div>
        <div v-if="isHistoryVisible"
             :class="{'base-modal__right-side--visible': isHistoryVisible}"
             class="base-modal__right-side">
          <history-list :history-list="issueHistory"/>
        </div>
      </div>
      <base-side-modal-skeleton v-if="issueLoading"/>
    </div>
    <div v-if="Object.keys(issue).length"
         class="i-modal-btn-wp i-modal-btn-wp--back">
      <div v-if="isHistoryVisible"
           @click="$store.commit('baseSideModal/TOGGLE_MODAL_HORIZONTAL')"
           class="button button--mode-outline task-modal__history-hide">
        <icon-arrow-down/>
        Вернуться назад
      </div>
      <div v-else
           class="task-modal__mobile-actions">
        <button @click="$store.dispatch('baseSideModal/closeModal')"
                class="button button--mode-outline task-modal__mobile-back">
          <icon-arrow-left/>
        </button>
        <issue-state-actions
          :issue-id="issue.id"
          :issue-state-id="issue.state.id"/>
      </div>

    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import IconArrowDown from "@/components/Icons/IconArrowDown";
import IconArrowLeft from "@/components/Icons/IconArrowLeft";
import IssueStateActions from "@/components/ServiceDesk/Issues/IssueComponents/IssueStateActions";
import IssueState from "@/models/serviceDesk/IssueState";
import IconHistoryToggle from "@/components/Icons/IconHistoryToggle";
import RelatedAppealsForm from "@/components/ServiceDesk/Issues/IssueComponents/RelatedAppeals/RelatedAppealsForm";
import HistoryList from "@/components/ServiceDesk/Tasks/TaskComponents/History/HistoryList";
import RelatedAppealsList from "@/components/ServiceDesk/Issues/IssueComponents/RelatedAppeals/RelatedAppealsList";
import RelatedIssuesList from "@/components/ServiceDesk/Issues/IssueComponents/RelatedIssues/RelatedIssuesList";
import IssueMap from "@/components/ServiceDesk/Issues/IssueMap";
import IssueTasksList from "@/components/ServiceDesk/Issues/IssueComponents/IssueTasksList/IssueTasksList";
import ShutdownsList from "@/components/ServiceDesk/Issues/IssueComponents/Shutdowns/ShutdownsList";
import BaseSideModalSkeleton from "@/components/BaseSideModalSkeleton.vue";
import sideModalControls from "@/mixins/sideModalControls";
import IssueExport from "@/components/ServiceDesk/Issues/IssueExport.vue";
import IconPencil from "@/components/Icons/IconPencil.vue";
import FilesList from "@/components/ServiceDesk/CommonComponents/FilesList/FilesList.vue";
import BaseCollapse from "@/components/BaseCollapse.vue";
import LeaksList from "@/components/ServiceDesk/Issues/IssueComponents/Leaks/LeaksList.vue";
import {formatDateTime} from "@/utils/filters";

export default {
  name: "IssueModalContent",
  components: {
    LeaksList,
    BaseCollapse,
    FilesList,
    IconPencil,
    IssueExport,
    BaseSideModalSkeleton,
    ShutdownsList,
    IssueTasksList,
    IssueMap,
    RelatedIssuesList,
    RelatedAppealsList,
    HistoryList,
    RelatedAppealsForm,
    IconHistoryToggle,
    IssueStateActions,
    IconArrowLeft,
    IconArrowDown
  },
  mixins: [sideModalControls],
  data() {
    return {
      isMapVisible: false,
      activeTab: 0
    }
  },
  computed: {
    ...mapState({
      issue: state => state.issueModal.issue,
      issueHistory: state => state.issueModal.history,
      shutdownsList: state => state.issueModal.shutdownsList,
      issueLoading: state => state.issueModal.loading,
      isHistoryVisible: state => state.baseSideModal.isModalHorizontal,
      structuralUnitsList: state => state.structuralUnits.structuralUnits,
      emergencyElementsList: state => state.emergencyElements.emergencyElements,
      damagesList: state => state.issueModal.damagesList,
      leaksList: state => state.issueModal.leaksList
    }),
    ...mapGetters({
      firstHistoryItem: 'issueModal/getHistoryFirstItem'
    }),
    IssueState: () => IssueState
  },
  mounted() {
    this.$store.dispatch('structuralUnits/getStructuralUnitsList');
  },
  methods: {
    formatDateTime,
    openEditIssueModal() {
      this.$store.dispatch('baseSideModal/openModal', {
        modalContentType: 'updateIssue',
        propsData: this.issue
      });
    },
    toggleMapVisible() {
      this.isMapVisible = !this.isMapVisible;
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers/index" as *;

.create-related-issue-btn {
  cursor: pointer;
  @include font-style(400, 16px, 24px, var(--text-link));
}

.issue-files-list {
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding: 24px 20px;
  border-radius: 16px;
  background: var(--panel-bg);

  &__title {
    @include font-style(500, 20px, 28px, var(--text-primary));
  }
}
</style>
