<template>
  <div class="billing-form-wrapper">

    <div class="form-item">
      <div class="form-item__name">Норматив водопотребления, м<sup>3</sup>/месяц</div>
      <div class="form-item__value">
        <input
          v-model="filter.normative_value"
          class="base-input base-input--bordered">
      </div>
    </div>


    <div class="form-item">
      <div class="form-item__name">Обеспеченность жилищного фонда, м<sup>2</sup>/чел.</div>
      <div class="form-item__value">
        <input
          v-model="filter.housing_stock"
          class="base-input base-input--bordered">
      </div>
    </div>


    <div class="form-item">
      <div class="form-item__name">Период для анализа</div>
      <div class="form-item__value">
        <base-date-picker
          v-model="filter.normative_year"
          year-picker
          format="yyyy"
          value-format="yyyy"
          placeholder="Выберите период"
          :max-date="new Date()"
        />
      </div>
    </div>

    <div class="form-item-group form-item-group--union-name">
      <div class="form-item-group__name">
        Диапазон отклонения сезонного коэффициента, %
      </div>
      <div class="form-item">
        <div class="form-item__value">
          <input
            v-model.number="filter.season_coefficient_filter[0]"
            placeholder="Минимальное значение"
            class="base-input base-input--bordered">
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__value">
          <input
            v-model.number="filter.season_coefficient_filter[1]"
            placeholder="Максимальное значение"
            class="base-input base-input--bordered">
        </div>
      </div>
    </div>

    <div class="form-item-group form-item-group--union-name">
      <div class="form-item-group__name">
        Диапазон отклонения не сезонного коэффициента, %
      </div>
      <div class="form-item">
        <div class="form-item__value">
          <input
            v-model.number="filter.not_season_coefficient_filter[0]"
            placeholder="Минимальное значение"
            class="base-input base-input--bordered">
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__value">
          <input
            v-model.number="filter.not_season_coefficient_filter[1]"
            placeholder="Максимальное значение"
            class="base-input base-input--bordered">
        </div>
      </div>
    </div>

    <div class="form-item-group">
      <div class="form-item">
        <div class="form-item__name">Сортировка результатов</div>
        <div class="form-item__value">
          <v-select
            class="vue-select-input"
            v-model="filter.sort_by"
            :options="sortByOptions"
            placeholder="Параметр сортировки"
          />
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__value">
          <v-select
            class="vue-select-input"
            v-model="filter.sort_dir"
            :options="sortDirOptions"
            placeholder="Направление сортировки"
          />
        </div>
      </div>
    </div>

    <div class="billing-form-wrapper__buttons">
      <button
        class="button button--mode-primary"
        @click="submitFilter()"
      >
        Применить
        <base-loader
          v-if="loading"
          color-class="color-white"/>
      </button>

      <button
        class="button button--mode-outline"
        @click="clearFilter()"
      >
        <icon-refresh />
        Сбросить
      </button>
    </div>

  </div>
</template>

<script>
import cleanFilter from "@/mixins/cleanFilter";
import IconRefresh from "@/components/Icons/IconRefresh.vue";
import BaseLoader from "@/layout/BaseLoader.vue";
import {mapState} from "vuex";
import {showAlert} from "@/utils/notification";
import BaseDatePicker from "@/components/BaseDatePicker.vue";

export default {
  name: "StandardBuildingFilter2",
  components: {BaseDatePicker, BaseLoader, IconRefresh},
  mixins: [cleanFilter],
  data() {
    return {
      filter: {
        normative_year: null,
        normative_value: 6.59,
        housing_stock: 25,
        season_coefficient_filter: [],
        not_season_coefficient_filter: [],
        sort_by: null,
        sort_dir: null
      },
      sortByOptions: [
        {id: 'season_coefficient', label: 'Сезонный коэффициент'},
        {id: 'not_season_coefficient', label: 'Не сезонный коэффициент'}
      ],
      sortDirOptions: [
        {id: 'asc', label: 'По возрастанию'},
        {id: 'desc', label: 'По убыванию'}
      ]
    }
  },
  computed: {
    ...mapState({
      loading: state => state.standardBuilding.loading
    })
  },
  methods: {
    submitFilter() {
      if (this.validateFilter(this.filter) === false) return false
      let filter = this.clean(this.filter);

      this.$store.commit('standardBuilding/SET_METHOD', 2);
      this.$store.commit('standardBuilding/SET_FILTER_PARAMS', filter);

      let payload = {
        filter: filter,
        page: 1
      }

      this.$store.dispatch('standardBuilding/getBuildingsV2', payload)
        .then(() => this.$store.commit('standardBuilding/SET_TAB_INDEX', 2));
    },
    clearFilter() {
      this.filter.housing_stock = 25;
      this.filter.normative_value = 6.59;
      this.filter.normative_year = null;
      this.filter.season_coefficient_filter = [];
      this.filter.not_season_coefficient_filter = [];
      this.filter.sort_dir = null;
      this.filter.sort_by = null;
    },
    notAfterToday(date) {
      let lastDate = new Date();
      lastDate.setDate(1);
      lastDate.setMonth(lastDate.getMonth() - 1);
      return date > lastDate;
    },
    validateFilter(filter) {
      if (filter.normative_year === null) {
        showAlert('Ошибка', 'Заполните период для анализа', 'warning');
        return false
      }
      if (filter.normative_value === '') {
        showAlert('Ошибка', 'Заполните норматив водопотребления или нажмите "Сбросить"', 'warning');
        return false
      }
      if (filter.housing_stock === '') {
        showAlert('Ошибка', 'Заполните обеспеченность жилищного фонда или нажмите "Сбросить"', 'warning');
        return false
      }
      // eslint-disable-next-line no-bitwise
      if (typeof filter.season_coefficient_filter[0] === 'number' ^
        typeof filter.season_coefficient_filter[1] === 'number') {
        showAlert(
          'Ошибка',
          'Заполните мин. и макс. значения диапазона отклонения сезонного коэффициента',
          'warning'
        );
        return false
      }
      // eslint-disable-next-line no-bitwise
      if (typeof filter.not_season_coefficient_filter[0] === 'number' ^
        typeof filter.not_season_coefficient_filter[1] === 'number') {
        showAlert(
          'Ошибка',
          'Заполните мин. и макс. значения диапазона отклонения не сезонного коэффициента',
          'warning'
        );
        return false
      }
    }
  }
}
</script>

<style scoped>
.tab-title {
  font-size: 22px;
  font-weight: 700;
  color: var(--text-primary);
  margin-bottom: 20px;
}

.loader-block {
  position: absolute;
  right: 20px;
  top: 50px;
}


@media (max-width: 1199px) {
  .range-mb {
    margin-bottom: .5rem;
  }
}
</style>
