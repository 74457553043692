<template>
  <form class="issues-count-report-form">
    <div class="issues-count-report-form__left">
      <v-select
        v-model="reportGroup"
        :options="groups"
        :reduce="report => report.key"
        :get-option-label="(option) => option.name"
        class="vue-select-input"
        placeholder="Выберите группировку"
        @update:modelValue="getReportData"
      />
      <base-date-picker
        v-model="reportPeriod"
        range
        placeholder="Выберите дату"
        :format="reportGroup === 1 ? 'dd.MM.yyyy' : 'MM yyyy'"
        :month-picker="reportGroup !== 1"
        @update:modelValue="getReportData"
      />
    </div>
    <button @click.prevent="getExcel(tableRows)"
            :disabled="!reportDataLoaded"
            class="button button--mode-outline">
      <icon-export/>
      <span>Экспорт</span>
    </button>
  </form>
</template>

<script>
import {mapState} from "vuex";
import IconExport from "@/components/Icons/IconExport.vue";
import {getExcel} from "@/utils/table";
import moment from "moment";
import BaseDatePicker from "@/components/BaseDatePicker.vue";

export default {
  name: "IssuesCountReportForm",
  components: {BaseDatePicker, IconExport},
  props: {
    tableRows: {
      required: true
    }
  },
  data() {
    return {
      groups: [
        {key: 1, name: 'Дни'},
        {key: 2, name: 'Месяцы'}
      ]
    }
  },
  computed: {
    ...mapState({
      reportParams: state => state.reportsData.reportParams,
      reportData: state => state.reportsData.reportData,
      loading: state => state.reportsData.loading
    }),
    reportDataLoaded() {
      return !!this.reportData?.length && !this.loading;
    },
    reportGroup: {
      get() {
        return this.reportParams.group
      },
      set(value) {
        this.$store.commit('reportsData/SET_REPORT_PARAM', {
          key: 'group',
          value: value
        })
      }
    },
    reportPeriod: {
      get() {
        if (this.reportParams.period_start && this.reportParams.period_end)
          return [this.reportParams.period_start, this.reportParams.period_end]
        else return []
      },
      set(value) {
        const [periodStart, periodEnd] = value;
        this.$store.commit('reportsData/SET_REPORT_PARAM', {
          key: 'period_start',
          value: periodStart
        });
        this.$store.commit('reportsData/SET_REPORT_PARAM', {
          key: 'period_end',
          value: periodEnd
        })
      }
    }
  },
  mounted() {
    this.reportGroup = 1;
    const now = moment().format('YYYY-MM-DD');
    this.reportPeriod = [now, now]
    this.getReportData();
  },
  methods: {
    getExcel,
    getReportData() {
      this.$store.dispatch('reportsData/getIssuesCountReportData', this.reportParams);
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers/index" as *;
.issues-count-report-form {
  display: grid;
  grid-template-columns: 660px 144px;
  gap: 20px;
  width: 100%;
  margin-bottom: 32px;
  justify-content: space-between;

  &__left {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    @media (max-width: $tablet) {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: $tablet) {
    grid-template-columns: 1fr;
  }
}
</style>
