<template>
  <div class="i-modal__content-wrapper">
    <div class="i-modal__content">
      <div class="i-modal__inner">
        <div class="base-modal__left-side">
          <div class="base-modal-header base-modal-header--padding-s">
            <span
              v-if="isCreateForm"
              class="base-modal-title">Новое оповещение</span>
            <span
              v-else
              class="base-modal-title">Редактирование оповещения</span>
          </div>
          <div class="base-modal-info base-modal-info--padding-s">
            <div class="notification-form">
              <div class="notification-form__map">
                <base-map
                  :base-layers="baseLayers"
                  :layers="layers"
                  :zoom="zoom"
                  :center="center"
                  :geoman-toolbar="true"
                  @update-drawn-layers="getNotificationAddresses"
                />
              </div>
              <div class="notification-form__inner">
                <div class="form-item-group">
                  <div class="form-item">
                    <div class="form-item__name">Дата и время отключения</div>
                    <div class="form-item__value">
                      <base-date-picker
                        v-model="payload.start_date"
                        placeholder="Выберите дату"
                        enable-time-picker
                        :format="DATE_TIME_FORMAT"
                        value-format="yyyy-MM-dd HH:mm"
                        :minutes-increment="10"
                      />
                    </div>
                  </div>

                  <div class="form-item">
                    <div class="form-item__name">Дата и время включения</div>
                    <div class="form-item__value">
                      <base-date-picker
                        v-model="payload.end_date"
                        placeholder="Выберите дату"
                        enable-time-picker
                        :format="DATE_TIME_FORMAT"
                        value-format="yyyy-MM-dd HH:mm"
                        :minutes-increment="10"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-item">
                  <div class="form-item__name">Начало сообщения</div>
                  <div class="form-item__value">
                    <textarea
                      v-model="payload.message_header"
                      class="base-textarea"
                      type="text"
                      placeholder="Введите сообщение"
                    />
                  </div>
                </div>

                <div class="form-item">
                  <div class="form-item__name">Конец сообщения</div>
                  <div class="form-item__value">
                    <textarea
                      v-model="payload.message_footer"
                      class="base-textarea"
                      type="text"
                      placeholder="Введите сообщение"
                    />
                  </div>
                </div>

                <div class="form-item">
                  <div class="form-item__name form-item__name--with-loader">
                    Адреса для отключения
                    <base-loader v-if="addressesLoading"/>
                  </div>
                  <div class="form-item__value">
                    <textarea
                      v-model="payload.message_body"
                      class="base-textarea"
                      type="text"
                      placeholder="Напишите вручную или выберите на карте"
                    />
                  </div>
                </div>

                <div class="form-item">
                  <div class="form-item__name">Куда отправить</div>
                  <div class="form-item__value">
                    <label class="base-checkbox base-checkbox--squared">
                      <input
                        v-model="payload.channel"
                        :false-value="0"
                        :true-value="1"
                        type="checkbox"
                      >
                      <span>На электронную почту</span>
                    </label>
                  </div>
                </div>

                <div class="notification-form__buttons">
                  <button
                    @click="submitForm()"
                    class="button button--mode-primary">Отправить
                  </button>
                  <button
                    @click="submitForm(true)"
                    class="button button--mode-outline">Сохранить черновик
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="i-modal-btn-wp i-modal-btn-wp--back">
      <div class="task-modal__mobile-actions">
        <button @click="$store.dispatch('baseSideModal/closeModal')"
                class="button button--mode-outline task-modal__mobile-back">
          <icon-arrow-left/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrowLeft from "@/components/Icons/IconArrowLeft.vue";
import {mapState} from "vuex";
import BaseMap from "@/components/BaseMap.vue";
import {showToast, showAlert} from "@/utils/notification";
import BaseLoader from "@/layout/BaseLoader.vue";
import BaseDatePicker from "@/components/BaseDatePicker.vue";

export default {
  name: "ShutdownNotificationFormModalContent",
  components: {
    BaseDatePicker,
    BaseLoader,
    BaseMap,
    IconArrowLeft
  },
  data() {
    return {
      payload: {
        start_date: null,
        end_date: null,
        message_header: null,
        message_body: null,
        message_footer: null,
        channel: 1,
        save_as_draft: 0
      }
    }
  },
  computed: {
    ...mapState({
      notification: state => state.baseSideModal.componentPropsData,
      baseLayers: state => state.baseLayers.baseLayers,
      center: state => state.baseMap.center,
      zoom: state => state.baseMap.zoom,
      layers: state => state.baseMap.layers,
      addressesLoading: state => state.shutdownNotifications.addressesLoading
    }),
    isCreateForm() {
      return !Object.keys(this.notification).length
    },
    DATE_TIME_FORMAT: () => process.env.VUE_APP_BASE_DATE_TIME_FORMAT
  },
  mounted() {
    if (!this.isCreateForm) {
      const {startDate, endDate, messageHeader, messageBody, messageFooter, channel} = this.notification;
      this.payload.start_date = startDate;
      this.payload.end_date = endDate;
      this.payload.message_header = messageHeader;
      this.payload.message_footer = messageFooter;
      this.payload.message_body = messageBody;
      this.payload.channel = channel.id;
    }
  },
  methods: {
    submitForm(saveAsDraft = false) {
      if (this.isCreateForm) this.createNotification(saveAsDraft)
      else this.updateNotification(saveAsDraft)
    },
    createNotification(saveAsDraft) {
      this.payload.save_as_draft = saveAsDraft;
      this.$store.dispatch('shutdownNotifications/createShutdownNotification', this.payload)
        .then(() => {
          this.$store.dispatch('shutdownNotifications/getShutdownNotificationsList');
          this.$store.dispatch('baseSideModal/goBack');

          const toastTitle = saveAsDraft ? 'Оповещение создано как черновик' : 'Оповещение создано и отправлено'
          showToast(toastTitle, 'success');
        })
        .catch(error => showAlert('Ошибка', error));
    },
    updateNotification(saveAsDraft) {
      this.payload.save_as_draft = saveAsDraft;
      this.$store.dispatch('shutdownNotifications/updateShutdownNotification', {
        id: this.notification.id,
        payload: this.payload
      })
        .then(() => {
          this.$store.dispatch('shutdownNotifications/getShutdownNotificationsList');
          this.$store.dispatch('baseSideModal/goBack');

          const toastTitle = saveAsDraft ? 'Оповещение сохранено как черновик' : 'Оповещение сохранено и отправлено'
          showToast(toastTitle, 'success');
        })
        .catch(error => showAlert('Ошибка', error));
    },
    getNotificationAddresses(layers) {
      let payload = {};

      if (!layers.length) return false

      if (layers.length === 1) {
        payload = layers[0].geometry
      } else {
        const layersGeo = layers.map(layer => layer.geometry);

        payload = {
          type: "Polygon",
          coordinates: layersGeo.reduce((accum, current) => [...accum, ...current.coordinates], [])
        };
      }

      this.$store.dispatch('shutdownNotifications/getAddressesByCoordinates', payload)
        .then(addresses => {
          this.payload.message_body = addresses;
        })
        .catch(error => showAlert('Ошибка', error));
    }
  }
}
</script>

<style lang="scss" scoped>
.base-modal__left-side {
  width: 80vw;
  max-width: 80vw;
}

.notification-form {
  display: flex;
  gap: 36px;

  &__map {
    flex: .65;
  }

  &__inner {
    flex: .35;

    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__buttons {
    display: flex;
    gap: 20px;

    & button {
      flex: 1;
    }
  }
}

.base-checkbox {
  & span {
    gap: 8px;
  }
}
</style>