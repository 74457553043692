<template>
  <div class="i-modal-location"
       :class="{'is-open': isOpenMapCard}">
    <div class="i-modal-location__map">

      <base-map
        id-key="-appeal-modal"
        :center="appealCoordinates"
        :zoom="17"
        :base-layers="baseLayers"
        :markers="marker"
        :need-resize="isOpenMapCard"
      />

    </div>
    <button class="button button--mode-icon map-resize-btn"
            @click="isOpenMapCard = !isOpenMapCard">
      <icon-arrow-double-up/>
    </button>
  </div>
</template>

<script>
import BaseMap from "@/components/BaseMap.vue";
import IconArrowDoubleUp from "@/components/Icons/IconArrowDoubleUp.vue";
import {mapState} from "vuex";
import Marker from "@/models/baseMap/Marker";
import Icon from "@/models/baseMap/Icon";

export default {
  name: "AppealModalMap",
  components: {IconArrowDoubleUp, BaseMap},
  props: {
    appealCoordinates: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      marker: [],
      isOpenMapCard: false
    }
  },
  computed: {
    ...mapState({
      baseLayers: state => state.baseLayers.baseLayers,
      loading: state => state.baseLayers.loading
    })
  },
  watch: {
    appealCoordinates: function () {
      this.createMarker();
    }
  },
  mounted() {
    this.createMarker();
  },
  methods: {
    createMarker() {
      this.marker = [new Marker({
        coords: this.appealCoordinates,
        icon: new Icon({
          type: 'icon',
          url: require('@/assets/img/icons/pin-low.svg'),
          size: [36, 36],
          anchor: [18, 36]
        })
      })]
    }
  }
}
</script>

<style scoped>

</style>
