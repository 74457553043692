<template>
  <div class="dashboard">
    <div class="dashboard__column">
      <div v-if="checkPermission('object-monitoring')">
        <router-link to="/telemetry">
          <div class="dashboard-card">
            <div class="dashboard-card-content">
              <img
                class="dashboard-card-icon"
                src="@/assets/img/icons/telemetry.svg"
              >
              <span class="dashboard-card-title">Телеметрия</span>
            </div>
          </div>
        </router-link>
      </div>
      <div v-if="checkPermission('transport-tracking')">
        <router-link to="/tracking">
          <div class="dashboard-card">
            <div class="dashboard-card-content">
              <img
                class="dashboard-card-icon"
                src="@/assets/img/icons/transport.svg"
              >
              <span class="dashboard-card-title">Транспорт</span>
            </div>
          </div>
        </router-link>
      </div>
      <div v-if="checkPermission('billing')">
        <router-link to="/billing-clients">
          <div class="dashboard-card">
            <div class="dashboard-card-content">
              <img
                class="dashboard-card-icon"
                src="@/assets/img/icons/billing.svg"
              >
              <span class="dashboard-card-title">Биллинг</span>
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <div class="dashboard__column">
      <div v-if="checkPermission('service-desk.issues')">
        <router-link to="/issues-list">
          <div class="dashboard-card">
            <div class="dashboard-card-content">
              <img
                class="dashboard-card-icon"
                src="@/assets/img/icons/task-manager.png"
              >
              <span class="dashboard-card-title">Диспетчерская</span>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'DashboardPage',
  computed: {
    ...mapGetters({
      checkPermission: 'auth/checkPermission'
    })
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers/index" as *;

a {
  text-decoration: none !important;
}

.dashboard {
  height: 100%;
  display: grid;
  align-items: center;
  column-gap: 24px;
  grid-template-columns: repeat(3, 1fr);

  &__column {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    gap: 24px;
  }
}

.dashboard-card {
  height: 170px;
  background-color: var(--panel-bg);
  border: 10px solid var(--border-active);
  border-radius: 12px;
}

.dashboard-card-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-card-icon {
  height: 50px;
  width: 50px;
  margin-right: 18px;
}

.dashboard-card-title {
  color: var(--text-primary);
  font-size: 35px;
  font-weight: 700;
  vertical-align: middle;
}

.mt-120 {
  margin-top: 120px;
}

.bg-image {
  background-image: url("@/assets/img/icons/bg-logo-element.svg");
  background-repeat: no-repeat;
  background-position: 100% 100%;
}

.vertical-align:before {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  content: "";
}

.vertical-align-inner {
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 1600px) {
  .dashboard-card-title {
    font-size: 32px;
  }
}

@media (max-width: 1420px) {
  .dashboard-card-title {
    font-size: 30px;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .dashboard-card-title {
    font-size: 32px;
  }

  .dashboard-card-icon {
    display: none;
  }
}

@media (max-width: 1120px) {
  .dashboard-card-title {
    font-size: 32px;
  }
}

@media (max-width: $mobile-big) {
  .dashboard {
    grid-template-columns: 1fr;
    row-gap: 24px;

    &__column {
      grid-template-rows: none;
    }
  }
}
</style>