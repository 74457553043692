<template>
  <div v-if="tasks.length"
       class="issues-tasks">
    <div v-if="completed"
         class="issues-tasks__completed">
      <icon-check-in-round />
      <span class="issues-tasks__ratio">Все задания выполнены</span>
      <span>{{completedCount}}/{{tasks.length}}</span>
    </div>
    <div v-else
         class="issues-tasks__in-process">
      <div class="issues-tasks__row">
        <span class="issues-tasks__ratio">{{completedCount}}/{{tasks.length}}</span>
        <div class="issues-tasks__bar">
          <div :style="{width: completedTasksPercent}"
               class="issues-tasks__bar-progress"/>
        </div>
      </div>
      <ul class="issues-tasks__list">
        <li v-for="task in getMoreOrLessTasks()"
            :key="task.id"
            class="issues-tasks__list-item">
          <span>{{shortenString(task.type_label)}}</span>
          <span>{{task.state.label}}</span>
        </li>
      </ul>
      <span @click.stop="toggleMoreTasks"
            v-if="showMoreButton"
            class="issues-tasks__show-more">
        {{moreButtonText}}
      </span>
    </div>
  </div>
</template>

<script>
import IconCheckInRound from "@/components/Icons/IconCheckInRound.vue";

export default {
  name: 'IssuesTasks',
  components: {IconCheckInRound},
  props: {
    tasks: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isMoreTasks: false
    }
  },
  computed: {
    completed() {
      return this.tasks.every(task => !task.state.isActive);
    },
    completedCount() {
      return this.tasks.reduce((sum, task)=> {
        if (!task.state.isActive) {
          return sum + 1;
        }
        return sum;
      },0)
    },
    completedTasksPercent() {
      return `${Math.round(this.completedCount / this.tasks.length * 100)}%`
    },
    showMoreButton() {
      return this.tasks.length > 3;
    },
    moreButtonText() {
      return this.isMoreTasks ? `Показать меньше` : `Еще ${this.tasks.length - 3} заданий...`
    }
  },
  methods: {
    toggleMoreTasks() {
      this.isMoreTasks = !this.isMoreTasks;
    },
    getMoreOrLessTasks() {
      return this.isMoreTasks ? this.tasks : this.tasks.slice(0, 3)
    },
    shortenString(string) {
      if (string.length > 32) {
        return `${string.slice(0, 32)}...`
      }
      return string;
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers/index" as *;

  .issues-tasks {
    display: flex;
    width: 100%;
  }

  .issues-tasks__ratio {
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: var(--text-primary);
  }

  .issues-tasks__completed {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 12px;
  }

  .issues-tasks__in-process {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 12px;


    .issues-tasks__bar {
      border-radius: 0px 20px 20px 0px;
      background: var(--bg-light-green);
      height: 6px;
      display: flex;
      width: 100%;
      overflow: hidden;
    }
    .issues-tasks__bar-progress {
      background: var(--status-done);
      display: flex;
      height: 6px;
      width: 0;
    }
    .issues-tasks__row {
      display: flex;
      width: 100%;
      align-items: center;
      gap: 12px;
    }
    .issues-tasks__list {
      display: flex;
      width: 100%;
      flex-direction: column;
      list-style: none;
      margin: 0;
      padding: 0;
      gap: 8px;
    }
    .issues-tasks__list-item {
      display: grid;
      width: 100%;
      grid-template-columns: 2fr 1fr;
      gap: 12px;
      align-items: center;
      white-space: nowrap;
      color: var(--text-primary);
    }
    .issues-tasks__show-more {
      @include font-style(400, 14px, 16px, var(--text-secondary));
      background: none;
      border: none;
    }
  }
</style>
