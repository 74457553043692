<template>
  <base-map
    :base-layers="baseLayers"
    :zoom="zoom"
    :center="center"
    :markers="networkObjectMarkers"
    @moveend="getObjects"
    @marker-click="params => this.$emit('network-object-marker-click', params)"
  />
</template>

<script>
import BaseMap from "@/components/BaseMap.vue";
import {mapState} from "vuex";
import Marker from "@/models/baseMap/Marker";
import {createVNode, render} from "vue";
import PersonalListNetworkObjectMapMarker
  from "@/components/Telemetry/PersonalLists/PersonalListNetworkObjectMapMarker.vue";
import Icon from "@/models/baseMap/Icon";

export default {
  name: "TaskCreateMap",
  components: {BaseMap},
  props: {
    selectedObject: {
      required: true
    }
  },
  computed: {
    ...mapState({
      baseLayers: state => state.baseLayers.baseLayers,
      center: state => state.baseMap.center,
      zoom: state => state.baseMap.zoom,
      objectsList: state => state.technicalInspectionTasks.objectsList
    }),
    networkObjectMarkers() {
      return this.objectsList.map(object => {
        const iconContainer = document.createElement('div');
        const iconVNode = createVNode(PersonalListNetworkObjectMapMarker, {
          typeGroup: object._type.groupName,
          isActive: object.id === this.selectedObject
        })
        render(iconVNode, iconContainer);
        const iconHtml = iconContainer.innerHTML;

        const marker = new Marker({
          coords: object.coordinates,
          icon: new Icon({
            type: 'div_icon',
            size: [36, 42],
            anchor: [18, 21],
            html: iconHtml
          }),
          params: {
            objectId: object.id
          }
        })

        render(null, iconContainer);

        return marker;
      })
    }
  },
  beforeUnmount() {
    this.$store.commit('technicalInspectionTasks/SET_OBJECTS_LIST', []);
  },
  methods: {
    getObjects({zoom, bounds}) {
      if (zoom >= process.env.VUE_APP_MIN_REQUEST_ZOOM) {
        this.$store.dispatch('technicalInspectionTasks/getObjectsList', {
          leftBounds: Object.values(bounds._southWest),
          rightBounds: Object.values(bounds._northEast)
        });
      } else {
        this.$store.commit('technicalInspectionTasks/SET_OBJECTS_LIST', []);
      }
    }
  }
}
</script>

<style scoped>

</style>