<template>
  <li
    :id="`submenu-link-${link.name}`"
    :key="link.label"
    :class="linkClasses"
    v-if="processLinkPermission"
  >
    <div
      v-if="deep === 0"
      @click="toggleLink()"
      class="icon-link">
      <div class="icon-link__wrapper">
        <component :is="`${link.icon}`"/>
        <span class="nav-name">{{ link.label }}</span>
      </div>
      <icon-arrow class="arrow"/>
    </div>

    <router-link
      v-if="isSubmenuLink"
      class="sub-menu__link"
      :to="`${link.route}`"
      @click.stop.prevent="toggleCategory(link.route)"
    >
      {{ link.label }}
    </router-link>

    <div
      v-else-if="deep > 0"
      class="sub-menu__link"
      :class="{'active': checkActiveCategory(link.children)}"
      @mouseenter="showChildren()"
      @mouseleave="toggleChildrenVisible()"
      @click.stop="toggleChildrenCollapse()">
      {{ link.label }}

      <icon-arrow
        v-if="link.children && deep > 1"
        class="sub-menu__link-desktop-icon"/>
      <icon-arrow
        class="sub-menu__link-mobile-icon"
        :class="{'sub-menu__link-mobile-icon--rotate': isChildrenCollapsed}"/>
    </div>

    <div v-if="deep === 0"
         class="sub-menu__wrapper">
      <div class="sub-menu__title">
        <span>{{ link.label }}</span>
      </div>
      <div class="sub-menu__line"/>
      <ul class="sub-menu">
        <menu-link
          v-for="childLink in link.children"
          :key="childLink.label"
          :deep="deep + 1"
          :link="childLink"
          :is-collapsed="isCollapsed" />
      </ul>
    </div>

    <div v-if="deep > 0 && link.children"
         :id="`submenu-children-${link.name}`"
         class="sub-menu__children-wrapper"
         :class="submenuWrapperClasses">
      <ul class="sub-menu__children"
          :class="[
            {'sub-menu__children--deep': deep > 1}
          ]">
        <menu-link
          v-for="childLink in link.children"
          :key="childLink.label"
          :deep="deep + 1"
          :link="childLink"
          :is-collapsed="isCollapsed" />
      </ul>
    </div>
  </li>
</template>

<script>
import {mapGetters} from "vuex";
import IconArrow from "@/components/Icons/IconArrow.vue";
import IconSettings from "@/components/Icons/IconSettins.vue";
import IconNetworks from "@/components/Icons/IconNetworks.vue";
import IconBilling from "@/components/Icons/IconBilling.vue";
import IconTruck from "@/components/Icons/IconTruck.vue";
import IconCall from "@/components/Icons/IconCall.vue";
import IconWifi from "@/components/Icons/IconWifi.vue";
import IconEnergy from "@/components/Icons/IconEnergy.vue";
import IconClipboardText from "@/components/Icons/IconClipboardText.vue";


export default {
  name: "MenuLink",
  components: {
    IconArrow,
    IconSettings,
    IconNetworks,
    IconBilling,
    IconTruck,
    IconCall,
    IconWifi,
    IconEnergy,
    IconClipboardText
  },
  props: {
    link: {
      type: Object,
      required: true
    },
    deep: {
      type: Number,
      required: true
    },
    isCollapsed: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isChildrenVisible: false,
      isChildrenCollapsed: true
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: "auth/isAdmin",
      checkPermission: "auth/checkPermission"
    }),
    processLinkPermission() {
      return this.link.typePermission
        ? this.checkPermission(this.link.typePermission) : this.link.children
          ? this.checkChildrenPermission(this.link.children) : this.link.isAdmin === this.isAdmin ||
          this.isAdmin
    },
    linkClasses() {
      return [
        {'n-nav-link': this.deep === 0},
        {'category-open': this.deep === 0 && this.link.isActive},
        {'category-active': this.deep === 0 && this.checkActiveCategory(this.link.children ? this.link.children : [])},
        {'sub-menu__item': this.deep > 0}
      ]
    },
    submenuWrapperClasses() {
      return [
        {'sub-menu__children-wrapper--visible': this.isChildrenVisible},
        {'sub-menu__children-wrapper--collapsed': !this.isChildrenCollapsed},
        {'sub-menu__children-wrapper--deep': this.deep > 1},
        `sub-menu__children-wrapper--deep-${this.deep + 1}`
      ]
    },
    isSubmenuLink() {
      return this.link.route && this.deep > 0
    }
  },
  methods: {
    checkChildrenPermission(children) {
      return children.some(child => {
        return child.typePermission ? this.checkPermission(child.typePermission) :
          child.children ? this.checkChildrenPermission(child.children) : false
      })
    },
    toggleCategory(route) {
      const category = this.link.children ? this.link.children.find(child => child.route === route) : null;
      if (category) category.isActive = !category.isActive;
    },
    showChildren() {
      const windowInnerWidth = document.documentElement.clientWidth;
      if (windowInnerWidth <= 1440) return false;

      this.toggleChildrenVisible();

      if (this.deep === 1 && !this.isCollapsed) {
        const link = document.querySelector(`#submenu-link-${this.link.name}`);
        const childrenWrapper = document.querySelector(`#submenu-children-${this.link.name}`);
        const linkRect = link.getBoundingClientRect();
        childrenWrapper.setAttribute(
          'style',
          `top: ${linkRect.top - (childrenWrapper.offsetHeight / 2)}px`
        );
      }
    },
    toggleLink() {
      if (this.deep === 0) {
        this.link.isActive = !this.link.isActive;

        setTimeout(() => {
          this.handleHeightMenu()
        }, 100)
      }
    },
    toggleChildrenVisible() {
      const windowInnerWidth = document.documentElement.clientWidth;
      if (windowInnerWidth <= 1440) return false;

      this.isChildrenVisible = !this.isChildrenVisible;
    },
    toggleChildrenCollapse() {
      const windowInnerWidth = document.documentElement.clientWidth;
      if (windowInnerWidth <= 1440) this.isChildrenCollapsed = !this.isChildrenCollapsed;
    },
    checkActiveCategory(children) {
      const {path} = this.$route
      return children?.some(item => item.route === path);
    },
    handleHeightMenu() {
      const linksHeight = document.querySelector('.nav-links').clientHeight
      const linkHeight = document.querySelectorAll('.nav-links .n-nav-link')

      const arrayLinkHeight = []
      linkHeight.forEach(link => {
        arrayLinkHeight.push(link.offsetHeight);
      })

      const sumLinkHeight = arrayLinkHeight.reduce(function (previousValue, currentValue) {
        return previousValue + currentValue;
      });

      this.$emit('active-border-menu', linksHeight < sumLinkHeight);
    }
  }
}
</script>

<style scoped>

</style>
