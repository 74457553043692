<template>
  <form class="accident-report-form">
    <base-date-picker
      v-model="reportDate"
      year-picker
      format="yyyy"
      value-format="yyyy"
      placeholder="Выберите дату"
      @update:modelValue="getReportData"
    />
    <button @click.prevent="getExcel(tableRows)"
            class="button button--mode-outline"
            :disabled="!isReportDataLoaded">
      <icon-export/>
      <span>Экспорт</span>
    </button>
  </form>
</template>

<script>
import {mapState} from "vuex";
import IconExport from "@/components/Icons/IconExport.vue";
import {getExcel} from "@/utils/table";
import moment from "moment";
import BaseDatePicker from "@/components/BaseDatePicker.vue";

export default {
  name: "AccidentReportForm",
  components: {BaseDatePicker, IconExport},
  props: {
    tableRows: {
      required: true
    }
  },
  data() {
    return {
      groups: [
        {key: 1, name: 'Год'},
        {key: 2, name: 'Месяц'}
      ]
    }
  },
  computed: {
    ...mapState({
      reportData: state => state.reportsData.reportData,
      reportParams: state => state.reportsData.reportParams,
      loading: state => state.reportsData.loading
    }),
    reportGroup: {
      get() {
        return this.reportParams.group
      },
      set(value) {
        this.$store.commit('reportsData/SET_REPORT_PARAM', {
          key: 'group',
          value: value
        })
      }
    },
    reportDate: {
      get() {
        return moment(this.reportParams.date).format('YYYY')
      },
      set(value) {
        this.$store.commit('reportsData/SET_REPORT_PARAM', {
          key: 'date',
          value: `${value}-01-01`
        })
      }
    },
    isReportDataLoaded() {
      return this.reportData?.length && !this.loading;
    }
  },
  mounted() {
    this.reportGroup = 1;
    this.reportDate = moment().format('YYYY');
    this.getReportData();
  },
  methods: {
    getExcel,
    getReportData() {
      this.$store.dispatch('reportsData/getAccidentReportData', this.reportParams);
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers/index" as *;

.accident-report-form {
  display: grid;
  grid-template-columns: 320px 144px;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 32px;

  @media (max-width: $tablet) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}
</style>
