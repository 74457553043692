<template>
  <div class="additional-options-list__inner">
    <div v-for="shutdown in shutdownsList"
         :key="shutdown.id"
         class="added-item added-item--emergency shutdown">
      <div class="added-item__number">
        #{{shutdown.id}}
      </div>
      <div class="added-item__info">
        <div class="added-item__name">
          Отключение
        </div>
        <div class="added-item__additional">
          <div class="added-item__additional-time">
            {{ formatDateTime(shutdown.planned_start_date)}}
          </div>
          <div class="added-item__additional-time">-</div>
          <div class="added-item__additional-time">
            {{formatDateTime(shutdown.planned_end_date)}}
          </div>
        </div>
      </div>
      <div class="added-item__right">
        <div class="shutdown__buttons">
          <div v-if="shutdown.actual_start_date && !shutdown.actual_end_date"
               class="shutdown__duration">
            <icon-timer-pause/>
            {{getDateDiff(shutdown.actual_start_date, new Date())}}
          </div>
          <div v-else-if="shutdown.actual_start_date && shutdown.actual_end_date"
               class="shutdown__duration">
            <icon-timer-pause/>
            {{getDateDiff(shutdown.actual_start_date, shutdown.actual_end_date)}}
          </div>

          <div v-if="!shutdown.actual_start_date"
               @click="startShutdown(shutdown.id)"
               class="shutdown__state">
            <span>Начать</span>
          </div>
          <div v-else-if="shutdown.actual_start_date && !shutdown.actual_end_date"
               @click="finishShutdown(shutdown.id)"
               @mouseenter="isEndButtonHover = true"
               @mouseleave="isEndButtonHover = false"
               class="shutdown__state">
            <span v-if="isEndButtonHover">
              Закончить
            </span>
            <span v-else>
              <div class="shutdown__state-indicator" />
              В работе
            </span>
          </div>
        </div>
        <div @click="showShutdownModal(shutdown)">
          <icon-pencil class="svg added-item__tool added-item__tool--size-20"/>
        </div>
      </div>
    </div>

    <div
      v-if="isIssueActive"
      @click="showShutdownModal()"
      class="button button--mode-outline">
      <icon-plus/>
      <span>Добавить новое</span>
    </div>
  </div>
</template>

<script>
import IconPlus from "@/components/Icons/IconPlus";
import {modalAdaptiveWidth} from "@/utils/helpers";
import ShutdownsForm from "@/components/ServiceDesk/Issues/IssueComponents/Shutdowns/ShutdownsForm";
import {showAlert, showToast} from "@/utils/notification";
import moment from "moment";
import IconTimerPause from "@/components/Icons/IconTimerPause";
import IconPencil from "@/components/Icons/IconPencil.vue";
import {formatDateTime} from "@/utils/filters";
export default {
  name: "ShutdownsList",
  components: {IconPencil, IconTimerPause, IconPlus},
  props: {
    issueId: {
      type: Number,
      required: true
    },
    shutdownsList: {
      type: Array,
      required: true
    },
    isIssueActive: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isEndButtonHover: false
    }
  },
  methods: {
    formatDateTime,
    showShutdownModal(shutdown = {}) {
      this.$modal.show(
        ShutdownsForm,
        {
          'issue-id': this.issueId,
          'shutdown': shutdown,
          'popupName': 'issue-shutdowns-form'
        },
        {
          name: 'issue-shutdowns-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460),
          scrollable: true
        }
      )
    },
    getDateDiff(start, end) {
      const momentStart = moment(start);
      const momentEnd = moment(end);

      const diff = momentEnd.diff(momentStart);
      return moment.duration(diff).humanize()
    },
    startShutdown(shutdownId) {
      this.$store.dispatch('shutdowns/startShutdown',
        {issue_id: this.issueId, shutdown_id: shutdownId})
        .then(() => {
          showToast('Отключение начато', 'success');
          this.$store.dispatch('issueModal/getShutdownsList', this.issueId);
          this.$store.dispatch('issueModal/getIssueHistory', this.issueId);
        })
        .catch(error => {
          showAlert('Ошибка', error, 'error');
        })
    },
    finishShutdown(shutdownId) {
      this.$store.dispatch('shutdowns/finishShutdown',
        {issue_id: this.issueId, shutdown_id: shutdownId})
        .then(() => {
          showToast('Отключение закончено', 'success');
          this.$store.dispatch('issueModal/getShutdownsList', this.issueId);
          this.$store.dispatch('issueModal/getIssueHistory', this.issueId);
        })
        .catch(error => {
          showAlert('Ошибка', error, 'error');
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers/index" as *;

.shutdown {
  &__buttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 24px;
  }

  &__duration {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;

    border-radius: 8px;
    background-color: var(--panel-bg);
    padding: 6px 12px;

    @include font-style(600, 14px, 16px, var(--text-primary));
  }

  &__state {
    border: 1px solid var(--border-main);
    border-radius: 8px;
    padding: 6px 12px;
    cursor: pointer;

    @include font-style(400, 14px, 16px, var(--text-primary));

    & span {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  &__state-indicator {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--bg-accent);
  }
}

@media (max-width: $mobile-big) {
  .shutdown {
    align-items: start;

    &__buttons {
      gap: 12px;
      justify-content: flex-end;
    }

    & .added-item__info {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
}
</style>
