<template>
  <div class="page-wrapper">
    <base-panel
      title="Пользователи"
      :is-add-button-visible="false"
    />

    <base-functional-line @search-change="searchUsers">
      <users-list-filter />
      <div
        @click="showFormModal()"
        class="button button--mode-primary">
        <icon-plus/>
        <span>Добавить пользователя</span>
      </div>
      <div
        @click="exportUserList()"
        class="button button--mode-outline">
        <icon-export/>
        <span>Экспорт</span>
      </div>
    </base-functional-line>

    <users-list
      :users-list="usersList"
      @show-user-modal="showUserModal"
      @update-sort-by="value => sortBy = value"
      @update-sort-order="value => sortOrder = value"
    />

    <base-content-loaders v-if="loading && !usersList.length"/>

    <base-no-data v-if="!loading && !usersList.length"/>

    <base-paginate
      v-if="meta && meta.last_page > 1"
      :meta="meta"
      action="user/getUsers"
      commit="user/SET_PAGE"
    />
  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel.vue";
import UsersList from "@/components/admin/User/UsersList.vue";
import {mapState} from "vuex";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import BasePaginate from "@/components/BasePaginate.vue";
import {showAlert} from "@/utils/notification";
import BaseFunctionalLine from "@/components/BaseFunctionalLine.vue";
import IconPlus from "@/components/Icons/IconPlus.vue";
import IconExport from "@/components/Icons/IconExport.vue";
import {getExcel} from "@/utils/table";
import {formatDateTime} from "@/utils/filters";
import UsersListFilter from "@/components/admin/User/UsersListFilter.vue";

export default {
  name: "UsersListPage",
  components: {
    UsersListFilter,
    IconExport,
    IconPlus,
    BaseFunctionalLine,
    BasePaginate,
    BaseNoData,
    BaseContentLoaders,
    UsersList,
    BasePanel
  },
  computed: {
    ...mapState({
      usersList: state => state.user.users,
      loading: state => state.user.loading,
      meta: state => state.user.meta,
      fullUsersList: state => state.user.fullUsersList
    }),
    sortBy: {
      get() {
        return this.$store.state.user.sortBy;
      },
      set(value) {
        this.$store.commit('user/SET_SORT_BY', value)
      }
    },
    sortOrder: {
      get() {
        return this.$store.state.user.sortOrder;
      },
      set(value) {
        this.$store.commit('user/SET_SORT_ORDER', value)
      }
    }
  },
  watch: {
    sortBy: function (value, oldValue) {
      if (value !== oldValue) this.$store.dispatch('user/getUsers')
    },
    sortOrder: function () {
      this.$store.dispatch('user/getUsers')
    }
  },
  mounted() {
    this.$store.dispatch('user/getUsers');
    this.$store.dispatch('structuralUnits/getStructuralUnitsList');
  },
  methods: {
    showFormModal(user = {}) {
      this.$store.dispatch('baseSideModal/openModal', {
        modalContentType: 'userForm',
        propsData: user
      });
    },
    showUserModal(userId) {
      this.$store.dispatch('baseSideModal/openModal', {
        modalContentType: 'user',
        entityId: userId
      });
    },
    searchUsers(event) {
      this.$store.commit('user/SET_PAGE', 1);
      this.$store.commit('user/SET_SEARCH_QUERY', event.target.value);
      this.$store.dispatch('user/getUsers')
    },
    exportUserList() {
      this.$store.dispatch('user/getAllUsers')
        .then(() => {
          const rows = [];
          rows.push(['Имя пользователя', 'E-mail', 'Структурное подразделение', 'Роли', 'Статус']);

          for (let user of this.fullUsersList) {
            rows.push([
              user.name,
              user.email,
              user.structuralUnit.name,
              user.roles.map(role => role.attributes.name).join(', '),
              user.blockedAt ? `Заблокирован ${formatDateTime(user.blockedAt)}` : 'Активен'
            ]);
          }

          getExcel(rows, true, 'Справочник пользователей', 'Пользователи');
        })
        .catch(error => showAlert('Ошибка', error))
    }
  }
}
</script>

<style scoped>

</style>
