<template>
  <div class="detected-problem-attachments">
    <div class="detected-problem-attachments__header">
      <div class="detected-problem-attachments__caption">
        <icon-attach-circle />
        <span>Фото материалы</span>
      </div>
      <div class="detected-problem-attachments__add-button">
        <div class="detected-problem-attachments__form">
          <input
            @change="handleFileUpload()"
            id="detectedProblemFile"
            ref="detectedProblemFile"
            type="file"
            accept=".pdf,.png,.jpg,.mpeg,.mp4,.ogg,.webm,.avi"
            class="file-input__hidden-input"
          >
          <label
            for="detectedProblemFile"
            class="detected-problem-attachments__form-label">
            Прикрепить файл
          </label>
        </div>
      </div>
    </div>
    <div>
      <files-list
        :files-list="getCommonFilesList"
        :max-length="7"
        files-gap="11px"
        :is-delete-icon-shown="true"
        @delete-file="deleteFile"/>
    </div>
  </div>
</template>

<script>
import IconAttachCircle from "@/components/Icons/IconAttachCircle.vue";
import FilesList from "@/components/ServiceDesk/CommonComponents/FilesList/FilesList.vue";
import File from "@/models/serviceDesk/File";

export default {
  name: "DetectedProblemAttachments",
  components: {FilesList, IconAttachCircle},
  props: {
    detectedProblemAttachments: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      filesList: [],
      filesForUpload: []
    }
  },
  computed: {
    getCommonFilesList() {
      return [...this.filesList, ...this.detectedProblemAttachments];
    }
  },
  methods: {
    handleFileUpload() {
      const [file] = this.$refs.detectedProblemFile.files;

      this.filesForUpload.push(file);
      this.filesList.push(new File(
        file.name,
        {
          name: file.name,
          path: URL.createObjectURL(file)
        },
        file.name.split('.').pop()
      ))

      this.$emit('update:attachments-list', this.filesForUpload);
    },
    deleteFile(fileId) {
      const uploadedFile = this.detectedProblemAttachments.find(file => file.id === fileId);
      const notUploadedFile = this.filesList.find(file => file.id === fileId);

      if (uploadedFile) this.$emit('delete:detected-problem-attachment', fileId);
      else if (notUploadedFile) {
        const index = this.filesList.findIndex(file => file.id === fileId);
        this.filesList.splice(index, 1);
        this.filesForUpload.splice(index, 1);
        this.$emit('update:attachments-list', this.filesForUpload);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers/index" as *;

.detected-problem-attachments {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__caption {
    display: flex;
    align-items: center;
    gap: 8px;

    & span {
      @include font-style(400, 16px, 24px, var(--text-secondary));
    }
  }

  &__form {
    & input {
      opacity: 0;
      overflow: hidden;
      position: absolute;
      width: 1px;
      height: 1px;
    }

    &-label {
      cursor: pointer;
      margin-bottom: 0;

      @include font-style(400, 16px, 24px, var(--text-link));
    }
  }
}
</style>