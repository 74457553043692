import { createStore } from 'vuex'

import * as auth from "@/store/modules/Auth";
import * as user from "@/store/modules/User";
import * as networkObjects from "@/store/modules/NetworkObjects";
import * as device from "@/store/modules/Device";
import * as sensor from "@/store/modules/Sensor";
import * as layer from "@/store/modules/Layer";
import * as role from "@/store/modules/Role";
import * as autos from "@/store/modules/Auto";
import * as transportData from "@/store/modules/TransportData";
import * as transportDayStat from "@/store/modules/TransportDayStat";
import * as zone from "@/store/modules/Zone";
import * as balanceGroup from "@/store/modules/BalanceGroup";
import * as issues from "@/store/modules/Issues";
import * as issue from "@/store/modules/Issue"
import * as employee from "@/store/modules/Employee";
import * as works from '@/store/modules/Works';
import * as appeals from '@/store/modules/Appeals'
import * as client from '@/store/modules/Client'
import * as building from '@/store/modules/Building'
import * as baseMap from '@/store/modules/BaseMap'
import * as telemetry from '@/store/modules/Telemetry'
import * as balance from '@/store/modules/Balance'
import * as standardBuilding from '@/store/modules/StandardBuilding'
import * as tasks from '@/store/modules/Tasks'
import * as departments from '@/store/modules/Departments'
import * as task_types from '@/store/modules/TaskTypes'
import * as task from '@/store/modules/Task'
import * as files from '@/store/modules/Files'
import * as shutdowns from "@/store/modules/Shutdowns";
import * as history from "@/store/modules/History";
import * as comments from '@/store/modules/Comments'
import * as leaks from "@/store/modules/Leaks";
import * as reportsData from '@/store/modules/ReportsData'
import * as emergencyElements from '@/store/modules/EmergencyElements'
import * as workShift from '@/store/modules/WorkShift'
import * as projectConfig from '@/store/modules/ProjectConfig'
import * as issueTypes from '@/store/modules/IssueTypes'
import * as plannedIssues from '@/store/modules/PlannedIssues'
import * as engineeringNetworks from '@/store/modules/EngineeringNetworks'
import * as networkElementTypes from '@/store/modules/NetworkElementTypes'
import * as networkElements from '@/store/modules/NetworkElements'
import * as transportDataServers from '@/store/modules/TransportDataServers'
import * as transportDataServerTypes from '@/store/modules/TransportDataServerTypes'
import * as appealsStatistic from '@/store/modules/AppealsStatistic'
import * as issuesStatistic from '@/store/modules/IssuesStatistic'
import * as mechanicalEquipments from '@/store/modules/MechanicalEquipments'
import * as taskMechanicalEquipments from '@/store/modules/TaskMechanicalEquipments'
import * as serviceDeskCategories from '@/store/modules/ServiceDeskCategories'
import * as departmentBrigades from '@/store/modules/DepartmentBrigades'
import * as networkObjectTypes from '@/store/modules/NetworkObjectTypes'
import * as networkEquipments from '@/store/modules/NetworkEquipments'
import * as networkEquipmentTypes from '@/store/modules/NetworkEquipmentTypes'
import * as networkEquipmentParameters from '@/store/modules/NetworkEquipmentParameters'
import * as waterFlows from '@/store/modules/WaterFlows'
import * as logbookPage from '@/store/modules/LogbookPage'
import * as archivePage from '@/store/modules/ArchivePage'
import * as resourceStatistic from '@/store/modules/ResourceStatistic'
import * as userSubscriptions from '@/store/modules/UserSubscriptions'
import * as consumables from '@/store/modules/Consumables'
import * as transportOrders from '@/store/modules/TransportOrders'
import * as networkObjectPage from '@/store/modules/NetworkObjectPage'
import * as baseLayers from '@/store/modules/BaseLayers'
import * as sidebar from '@/store/modules/Sidebar'
import * as modal from '@/store/modules/Modal'
import * as energyConsumptionObjects from '@/store/modules/EnergyConsumptionObjects';
import * as electricMeters from '@/store/modules/ElectricMeters';
import * as electricMetersReadings from '@/store/modules/ElectricMetersReadings';
import * as energyConsumptionLogbook from '@/store/modules/EnergyConsumptionLogbook';
import * as structuralUnits from '@/store/modules/StructuralUnits';
import * as scadaPage from '@/store/modules/ScadaPage';
import * as semanticProviders from '@/store/modules/SemanticProviders';
import * as networkSpread from '@/store/modules/NetworkSpread';
import * as operationalSchemeData from '@/store/modules/OperationalSchemeData';
import * as technicalServiceWorks from '@/store/modules/TechnicalServiceWorks';
import * as baseSideModal from '@/store/modules/BaseSideModal';
import * as appealModal from '@/store/modules/AppealModal';
import * as issueModal from '@/store/modules/IssueModal';
import * as taskModal from '@/store/modules/TaskModal';
import * as networksLength from '@/store/modules/NetworksLength';
import * as appVersion from '@/store/modules/AppVersion';
import * as taskConsumptions from '@/store/modules/TaskConsumptions';
import * as detectedProblems from '@/store/modules/DetectedProblems';
import * as resources from '@/store/modules/Resources'
import * as defects from '@/store/modules/Defects'
import * as damages from '@/store/modules/Damages'
import * as shutdownNotifications from '@/store/modules/ShutdownNotifications';
import * as telemetryPersonalLists from '@/store/modules/TelemetryPersonalLists';
import * as weather from '@/store/modules/Weather';
import * as violations from '@/store/modules/Violations'
import * as dictionaries from '@/store/modules/Dictionaries'
import * as questions from '@/store/modules/Questions'
import * as technicalInspectionTasks from '@/store/modules/TechnicalInspectionTasks'
import * as suggestions from '@/store/modules/Suggestions'
import * as technicalInspectionObjects from '@/store/modules/TechnicalInspectionObjects'
import * as resourceModal from '@/store/modules/ResourceModal'
import * as warehouses from '@/store/modules/Warehouses'
import * as technicalInspectionStatistic from '@/store/modules/TechnicalInspectionStatistic'

const store = createStore({
    strict: true,
    modules: {
        auth,
        user,
        networkObjects,
        device,
        sensor,
        layer,
        role,
        autos,
        transportData,
        transportDayStat,
        zone,
        balanceGroup,
        issues,
        issue,
        employee,
        works,
        appeals,
        client,
        building,
        baseMap,
        telemetry,
        balance,
        standardBuilding,
        tasks,
        departments,
        task_types,
        task,
        files,
        history,
        shutdowns,
        comments,
        leaks,
        reportsData,
        emergencyElements,
        workShift,
        projectConfig,
        issueTypes,
        plannedIssues,
        engineeringNetworks,
        networkElementTypes,
        networkElements,
        transportDataServers,
        transportDataServerTypes,
        appealsStatistic,
        issuesStatistic,
        mechanicalEquipments,
        serviceDeskCategories,
        departmentBrigades,
        taskMechanicalEquipments,
        networkObjectTypes,
        networkEquipmentTypes,
        waterFlows,
        networkEquipments,
        networkEquipmentParameters,
        logbookPage,
        archivePage,
        resourceStatistic,
        userSubscriptions,
        consumables,
        taskConsumptions,
        networkObjectPage,
        transportOrders,
        baseLayers,
        sidebar,
        modal,
        energyConsumptionObjects,
        electricMeters,
        electricMetersReadings,
        energyConsumptionLogbook,
        scadaPage,
        structuralUnits,
        semanticProviders,
        networkSpread,
        operationalSchemeData,
        technicalServiceWorks,
        baseSideModal,
        appealModal,
        issueModal,
        taskModal,
        networksLength,
        appVersion,
        detectedProblems,
        resources,
        defects,
        damages,
        shutdownNotifications,
        telemetryPersonalLists,
        weather,
        violations,
        dictionaries,
        questions,
        technicalInspectionTasks,
        suggestions,
        technicalInspectionObjects,
        resourceModal,
        warehouses,
        technicalInspectionStatistic
    }
})

export default store;
