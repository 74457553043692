<template>
  <div class="page-wrapper">
    <base-panel
      title="Статистика по ресурсам"
      :is-add-button-visible="false"
    />

    <div class="resource-statistic-date-picker">
      <base-date-range-picker @updateDateRange="setDateRange($event)"/>
      <div class="resource-statistic-date-picker__input">
        <base-date-picker
          v-model="reportPeriod"
          format="dd.MM.yyyy"
          value-format="yyyy-MM-dd"
          placeholder="Выберите период"
          range
          @update:modelValue="getResourcesStatistic"
        />
      </div>
    </div>

    <base-content-loaders v-if="loading" />

    <base-no-data v-if="completedTasksCount === 0 && plannedTasksCount === 0" />

    <div
      v-if="(completedTasksCount > 0 || plannedTasksCount > 0) && !loading"
      class="inspection-resource-statistic"
    >
      <div
        v-if="completedTasksCount > 0"
        class="inspection-resource-statistic__chart"
      >
        <div class="inspection-resource-statistic__chart-title">Выполненные задания</div>
        <div class="inspection-resource-statistic__chart-inner">
          <apexchart
            type="donut"
            height="400px"
            :options="createChartOptions"
            :series="createChartSeries"
          />
        </div>
      </div>

      <div class="inspection-resource-statistic__table">
        <div class="base-table base-table__sub-header">
          <div class="base-table__container">
            <div class="base-table__header base-table--inspection-resources">
              <div class="base-table__header-cell">Исполнитель</div>
              <div class="base-table__header-cell">Запланировано</div>
              <div class="base-table__header-cell">Выполнено</div>
            </div>

            <div class="base-table__header base-table--inspection-resources">
              <div class="base-table__header-cell">Итого</div>
              <div class="base-table__header-cell base-table__header-cell--bold-600">{{plannedTasksCount}}</div>
              <div class="base-table__header-cell base-table__header-cell--bold-600">{{completedTasksCount}}</div>
            </div>

            <div
              v-for="stat in filteredResourceStatistic"
              :key="stat.resource.id"
              class="base-table__row base-table--inspection-resources"
            >
              <div class="base-table__column">
                <div class="base-table__text">{{ stat.resource.name }}</div>
              </div>
              <div class="base-table__column">
                <div class="base-table__text">{{ stat.planned_tasks_count }}</div>
              </div>
              <div class="base-table__column">
                <div class="base-table__text">{{ stat.completed_tasks_count }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel.vue";
import BaseDateRangePicker from "@/components/BaseDateRangePicker.vue";
import {mapGetters, mapState} from "vuex";
import VueApexCharts from "vue3-apexcharts";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import moment from "moment";
import BaseNoData from "@/components/BaseNoData.vue";
import BaseDatePicker from "@/components/BaseDatePicker.vue";

export default {
  name: "TechnicalInspectionResourcesStatisticPage",
  components: {
    BaseDatePicker,
    BaseNoData,
    BaseContentLoaders,
    BaseDateRangePicker,
    BasePanel,
    'apexchart': VueApexCharts
  },
  data() {
    return {
      reportPeriod: []
    }
  },
  computed: {
    ...mapState({
      loading: state => state.technicalInspectionStatistic.loading
    }),
    ...mapGetters({
      resourcesStatistic: 'technicalInspectionStatistic/getResourceStatisticWithCompletePercent',
      filteredResourceStatistic: 'technicalInspectionStatistic/getFilteredResourceStatistic',
      completedTasksCount: 'technicalInspectionStatistic/getResourceCompletedTasksCount',
      plannedTasksCount: 'technicalInspectionStatistic/getResourcePlannedTasksCount'
    }),
    createChartOptions() {
      return {
        legend: {
          show: true,
          position: 'bottom',
          formatter: function (seriesName, opts) {
            return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex] + '%']
          }
        },
        labels: this.resourcesStatistic
          .filter(item => item.completed_tasks_count > 0)
          .map(item => item.resource.name),
        tooltip: {
          enabled: false
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: 'Всего'
                }
              }
            }
          }
        }
      }
    },
    createChartSeries() {
      return this.resourcesStatistic
        .filter(item => item.completed_tasks_count > 0)
        .map(item => item.completed_tasks_count_percent)
    }
  },
  mounted() {
    this.reportPeriod = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
    this.getResourcesStatistic();
  },
  beforeUnmount() {
    this.$store.commit('technicalInspectionStatistic/SET_RESOURCES_STATISTIC', []);
  },
  methods: {
    setDateRange(range) {
      this.reportPeriod = [range.start, range.end];
      this.getResourcesStatistic();
    },
    getResourcesStatistic() {
      const [start, end] = this.reportPeriod;
      this.$store.dispatch('technicalInspectionStatistic/getResourcesStatistic', {
        start_period: moment(start).format('YYYY-MM-DD 00:00'),
        end_period: moment(end).format('YYYY-MM-DD 23:59')
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers/index" as *;

.resource-statistic-date-picker {
  display: flex;
  gap: 24px;
  margin-bottom: 36px;

  &__input {
    width: 320px;
  }
}

.inspection-resource-statistic {
  display: flex;
  flex-direction: column;
  gap: 36px;

  &__chart {
    padding: 24px;
    border-radius: 16px;
    border: 1px solid var(--border-main);
    background-color: var(--bg-main);

    &-title {
      @include font-style(500, 20px, 28px, var(--text-primary));
      margin-bottom: 16px;
    }
  }
}

.base-table--inspection-resources {
  grid-template-columns: repeat(3, 1fr);
}
</style>
