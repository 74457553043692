<template>
  <div class="page-wrapper">
    <base-panel
      title="Задания"
      :is-add-button-visible="false"/>
    <base-functional-line>
      <tasks-list-filter/>
    </base-functional-line>

    <div class="base-tables-container">
      <div class="base-table__tabs">
        <div class="base-table__tab"
             :class="{'active': activeFilterState === 0}"
             @click="filterTasksList(0)">Все
        </div>
        <div class="base-table__tab"
             :class="{'active': activeFilterState === TaskState.NEW}"
             @click="filterTasksList(TaskState.NEW)">Новые
        </div>
        <div class="base-table__tab"
             :class="{'active': activeFilterState === TaskState.IN_WORK}"
             @click="filterTasksList(TaskState.IN_WORK)">В работе
        </div>
      </div>

      <tasks-list
        v-if="tasksList.length"
        :tasks-list="tasksList" />

      <base-content-loaders v-if="contentLoading"/>

      <div class="no-result-message"
           v-if="noContent">
        Задания не найдены
      </div>
    </div>

  </div>
</template>

<script>
import TasksList from "@/components/ServiceDesk/Tasks/TasksList";
import TasksListFilter from "@/components/ServiceDesk/Tasks/TasksListFilter";
import {mapState} from "vuex";
import BasePanel from "@/components/BasePanel";
import BaseFunctionalLine from "@/components/BaseFunctionalLine";
import BaseContentLoaders from "@/components/BaseContentLoaders";
import TaskState from "@/models/serviceDesk/TaskState";

export default {
  name: "TasksListPage",
  components: {
    BaseContentLoaders,
    BaseFunctionalLine,
    BasePanel,
    TasksListFilter,
    TasksList
  },
  data() {
    return {
      timing: null,
      activeFilterState: 0,
      selectedTask: {},
      isModalActive: false
    }
  },
  computed: {
    ...mapState({
      tasksList: state => state.tasks.tasks,
      loading: state => state.tasks.loading,
      filterParams: state => state.tasks.filterParams
    }),
    contentLoading() {
      return !this.tasksList.length && this.loading
    },
    noContent() {
      return !this.tasksList.length && !this.loading
    },
    TaskState: () => TaskState
  },
  mounted() {
    this.getTasks();

    this.timing = setInterval(this.getTasks, process.env.VUE_APP_SERVICE_DESK_GET_DATA_TIMING)
  },
  beforeUnmount() {
    this.$store.commit('tasks/SET_TASKS', []);
    clearInterval(this.timing);

    this.$store.dispatch('baseSideModal/closeModal');
  },
  methods: {
    getTasks() {
      this.$store.dispatch('tasks/getTasks');
    },
    filterTasksList(taskState) {
      this.activeFilterState = taskState;
      this.$store.commit('tasks/SET_TASKS', []);

      if (taskState > 0) {
        this.$store.commit('tasks/SET_FILTER_PARAM', {
          name: 'state',
          value: taskState
        });
      } else {
        this.$store.commit('tasks/SET_FILTER_PARAM', {
          name: 'state',
          value: null
        });
      }

      this.$store.dispatch('tasks/getTasks');
    }
  }
}
</script>

<style scoped>
.no-result-message {
  font-size: 24px;
  text-align: center;
}
</style>
