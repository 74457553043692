<template>
  <base-popup
    title="Смена пароля"
    :name="popupName"
  >
    <template #body>
      <div class="form-item">
        <div class="form-item__name">Новый пароль</div>
        <div class="form-item__value">
          <input
            type="password"
            v-model="v$.payload.password.$model"
            class="base-input base-input--bordered"
            :class="{'base-input--error': v$.payload.password.$error}"
            placeholder="Введите пароль"
            autocomplete="new-password"
          >
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Пароль еще раз</div>
        <div class="form-item__value">
          <input
            type="password"
            v-model="v$.payload.password_confirmation.$model"
            class="base-input base-input--bordered"
            :class="{'base-input--error': v$.payload.password_confirmation.$error}"
            placeholder="Повторите пароль"
            autocomplete="new-password"
          >
        </div>
      </div>

      <div
        v-if="!v$.payload.password_confirmation.sameAsPassword"
        class="error-message">Пароли не совпадают
      </div>
    </template>

    <template #footer>
      <div @click="submitForm()"
           class="button">
        Сохранить
      </div>
    </template>
  </base-popup>
</template>

<script>
import BasePopup from "@/components/BasePopup.vue";
import { useVuelidate } from '@vuelidate/core'
import { required, sameAs } from '@vuelidate/validators'
import {showToast, showAlert} from "@/utils/notification";

export default {
  name: "UserChangePasswordForm",
  components: {BasePopup},
  props: {
    popupName: {
      type: String,
      required: true
    },
    userId: {
      type: Number,
      required: true
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      payload: {
        password: null,
        password_confirmation: null
      }
    }
  },
  validations() {
    return {
      payload: {
        password: {required},
        password_confirmation: {
          required,
          sameAsPassword: sameAs('password')
        }
      }
    }
  },
  methods: {
    submitForm() {
      this.v$.$touch()
      if (this.v$.$invalid) return false

      this.$store.dispatch('user/changeUserPassword', {
        userId: this.userId,
        payload: this.payload
      })
        .then(() => {
          this.$modal.hide(this.popupName);
          showToast('Пароль изменен', 'success')
        })
        .catch(error => showAlert('Ошибка', error))
    }
  }
}
</script>

<style scoped>

</style>