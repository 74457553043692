<template>
  <div
    class="base-date-picker"
    :class="{error: 'base-date-picker--error'}"
  >
    <vue-date-picker
      v-model="processModelValue"
      locale="ru"
      :auto-apply="autoApply"
      :format="format"
      :model-type="valueFormat"
      :range="range"
      :time-picker="timePicker"
      :month-picker="monthPicker"
      :quarter-picker="quarterPicker"
      :year-picker="yearPicker"
      :enable-time-picker="enableTimePicker"
      :flow="flow"
      :placeholder="placeholder"
      :start-date="startDate"
      :start-time="startTime"
      :min-date="minDate"
      :max-date="maxDate"
      :min-time="minTime"
      :max-time="maxTime"
      :enable-minutes="enableMinutes"
      :minutes-increment="minutesIncrement"
      :multi-dates="multiDates"
      @cleared="$emit('cleared')"
    >
      <template #quarter="{ value }">
        <span>{{ formatQuarter(value) }}</span>
      </template>
    </vue-date-picker>
  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import moment from "moment";

export default {
  name: "BaseDatePicker",
  components: {
    VueDatePicker
  },
  props: {
    modelValue: {},
    autoApply: {
      type: Boolean,
      default: true
    },
    format: {
      type: String,
      default: 'dd.MM.yyyy'
    },
    valueFormat: {
      type: String,
      default: 'yyyy-MM-dd'
    },
    range: {
      type: Boolean,
      default: false
    },
    timePicker: {
      type: Boolean,
      default: false
    },
    monthPicker: {
      type: Boolean,
      default: false
    },
    quarterPicker: {
      type: Boolean,
      default: false
    },
    yearPicker: {
      type: Boolean,
      default: false
    },
    enableTimePicker: {
      type: Boolean,
      default: false
    },
    flow: {
      type: Array,
      default: () => []
    },
    startDate: {
      type: Date,
      default: new Date()
    },
    startTime: {
      type: Object,
      default: () => {
        return {hours: 0, minutes: 0}
      }
    },
    placeholder: {
      type: String,
      default: 'Выберите дату'
    },
    minDate: {
      type: Date,
      default: null
    },
    maxDate: {
      type: Date,
      default: null
    },
    minTime: {
      type: Object,
      default: null
    },
    maxTime: {
      type: Object,
      default: null
    },
    error: {
      type: Boolean,
      default: false
    },
    enableMinutes: {
      type: Boolean,
      default: true
    },
    minutesIncrement: {
      type: Number,
      default: 1
    },
    multiDates: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    processModelValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    formatQuarter(quarter) {
      return `${moment(quarter).format('MMMM')} — ${moment(quarter).add(2, 'M').format('MMMM')}`
    }
  }
}
</script>

<style scoped>
</style>