<template>
  <div class="billing-form-wrapper">
    <div class="form-item">
      <div class="form-item__name">По адресу</div>
      <base-suggestions
        v-model="full_address"
        type="address"
        placeholder="Начните вводить адрес"
        @change="setAddress"
      />
    </div>

    <div class="form-item">
      <div class="form-item__name">Расчётный месяц</div>
      <div class="form-item__value">
        <base-date-picker
          v-model="filter.filter_period"
          placeholder="Выберите период"
          format="MM yyyy"
          month-picker
          @cleared="filter.filter_period = null"
        />
      </div>
    </div>

    <div class="form-item">
      <div class="form-item__name">По источнику водоснабжения</div>
      <div class="form-item__value">
        <v-select
          class="vue-select-input"
          v-model="filter.source"
          :options="sourcesList"
          placeholder="Выберите из списка"
        />
      </div>

    </div>

    <div class="form-item-group">
      <div class="form-item">
        <div class="form-item__name">По зоне водоснабжения</div>
        <div class="form-item__value">
          <base-select
            v-model="filter.zone_ws_id"
            :options="zonesByNetworkType(1)"
            :reduce="zone => zone.attributes.sys_id"
            :get-option-label="option => option.attributes.name"
            track-by="attributes"
          />
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">По зоне водоотведения</div>
        <div class="form-item__value">
          <base-select
            v-model="filter.zone_wd_id"
            :options="zonesByNetworkType(2)"
            :reduce="zone => zone.attributes.sys_id"
            :get-option-label="option => option.attributes.name"
            track-by="attributes"
          />
        </div>
      </div>
    </div>

    <div class="form-item-group">
      <div class="form-item">
        <div class="form-item__name">По объёму потребления ресурса, м<sup>3</sup></div>
        <div class="form-item__value">
          <input
            id="consValue-min"
            v-model.number="filter.consumption_values[0]"
            class="base-input base-input--bordered"
            placeholder="Мин. значение"
            type="text"
          >
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__value">
          <input
            id="consValue-max"
            v-model.number="filter.consumption_values[1]"
            class="base-input base-input--bordered"
            placeholder="Макс. значение"
            type="text"
          >
        </div>
      </div>
    </div>

    <div class="form-item-group">
      <div class="form-item">
        <div class="form-item__name">По графику подачи</div>
        <div class="form-item__value">
          <base-date-picker
            v-model="filter.supply_am"
            time-picker
            format="H:mm"
            range
            value-format="H"
            placeholder="Утро"
            :enable-minutes="false"
            :min-time="{hours: 0, minutes: 0}"
            :max-time="{hours: 13, minutes: 0}"
            :start-time="[{hours: 0, minutes: 0}, {hours: 0, minutes: 0}]"
            @cleared="filter.supply_am = []"
          />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__value">
          <base-date-picker
            v-model="filter.supply_pm"
            time-picker
            format="H:mm"
            range
            value-format="H"
            placeholder="Вечер"
            :enable-minutes="false"
            :min-time="{hours: 12, minutes: 0}"
            :max-time="{hours: 24, minutes: 0}"
            :start-time="[{hours: 12, minutes: 0}, {hours: 12, minutes: 0}]"
            @cleared="filter.supply_pm = []"
          />

        </div>
      </div>
    </div>

    <div class="form-item-group">
      <div class="form-item">
        <div class="form-item__name">Несоответствие ТУ ВС, %</div>
        <div class="form-item__value">
          <input
            v-model.number="filter.consumption_coef[0]"
            class="base-input base-input--bordered"
            placeholder="Мин. значение"
          >
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__value">
          <input
            v-model.number="filter.consumption_coef[1]"
            class="base-input base-input--bordered"
            placeholder="Макс. значение"
          >
        </div>
      </div>
    </div>

    <div class="form-item-group">
      <div class="form-item">
        <div class="form-item__name">Несоответствие ТУ ВО, %</div>
        <div class="form-item__value">
          <input
            v-model.number="filter.drainage_coef[0]"
            class="base-input base-input--bordered"
            placeholder="Мин. значение"
          >
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__value">
          <input
            v-model.number="filter.drainage_coef[1]"
            class="base-input base-input--bordered"
            placeholder="Макс. значение"
          >
        </div>
      </div>
    </div>

    <div class="form-item-group">
      <div class="form-item">
        <div class="form-item__name">Несоответствие водоснабжения / водоотведения, %</div>
        <div class="form-item__value">
          <input
            v-model.number="filter.fact_coef[0]"
            class="base-input base-input--bordered"
            placeholder="Мин. значение"
          >
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__value">
          <input
            v-model.number="filter.fact_coef[1]"
            class="base-input base-input--bordered"
            placeholder="Макс. значение"
          >
        </div>
      </div>
    </div>

    <div class="form-item">
      <label class="base-checkbox base-checkbox--squared">
        <input
          id="Illegal"
          v-model="filter.illegal"
          :false-value="0"
          :true-value="1"
          type="checkbox"
        >
        <span>Незаконные подключения</span>
      </label>
      <label class="base-checkbox base-checkbox--squared">
        <input
          id="technicalConditions"
          v-model="filter.technical_conditions"
          :false-value="0"
          :true-value="1"
          type="checkbox"
        >
        <span>По несогласованным / не верифицированным ТУ</span>
      </label>
      <label class="base-checkbox base-checkbox--squared">
        <input
          id="leaks"
          v-model="filter.leaks"
          :false-value="0"
          :true-value="1"
          type="checkbox"
        >
        <span>Утечки</span>
      </label>
      <label class="base-checkbox base-checkbox--squared">
        <input
          id="accident"
          v-model="filter.accident"
          :false-value="0"
          :true-value="1"
          type="checkbox"
        >
        <span>Аварийность</span>
      </label>
      <label class="base-checkbox base-checkbox--squared">
        <input
          id="consumption_not_compare"
          v-model="filter.consumption_not_compare"
          :false-value="0"
          :true-value="1"
          type="checkbox"
        >
        <span>
          Фактическое потребление больше потребления по ТУ
        </span>
      </label>
      <label class="base-checkbox base-checkbox--squared">
        <input
          id="drainage_not_compare"
          v-model="filter.drainage_not_compare"
          :false-value="0"
          :true-value="1"
          type="checkbox"
        >
        <span>
          Фактическое отведение больше чем отведение по ТУ
        </span>
      </label>
      <label class="base-checkbox base-checkbox--squared">
        <input
          id="fact_not_compare"
          v-model="filter.fact_not_compare"
          :false-value="0"
          :true-value="1"
          type="checkbox"
        >
        <span>
          Фактическое потребление не соответствует фактическому отведению
        </span>
      </label>
    </div>

    <div class="form-item-group">
      <div class="form-item">
        <div class="form-item__name">Сортировка результатов</div>
        <div class="form-item__value">
          <v-select
            class="vue-select-input"
            v-model="filter.sort_by"
            :options="sortByOptions"
            :reduce="sort => sort.id"
            :get-option-label="(option) => option.label"
            placeholder="Параметр сортировки"
          />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__value">
          <v-select
            class="vue-select-input"
            v-model="filter.sort_dir"
            :options="sortDirOptions"
            :reduce="sort => sort.id"
            :get-option-label="(option) => option.label"
            placeholder="Направление сортировки"
          />
        </div>
      </div>
    </div>

    <div class="billing-form-wrapper__buttons">
      <button
        class="button button--mode-primary"
        @click="submitFilter()"
      >
        Применить
        <loader v-if="loader" />
      </button>

      <button
        class="button button--mode-outline"
        @click="clearFilter()"
      >
        <icon-refresh />
        Сбросить
      </button>
    </div>
  </div>
</template>

<script>
import Loader from "@/layout/BaseLoader.vue";
import {mapGetters} from "vuex";
import IconRefresh from "@/components/Icons/IconRefresh.vue";
import {showAlert} from "@/utils/notification";
import BaseSelect from "@/components/BaseSelect.vue";
import BaseSuggestions from "@/components/BaseSuggestions.vue";
import BaseDatePicker from "@/components/BaseDatePicker.vue";

export default {
  name: "BillingBuildingsFilter",
  components: {BaseDatePicker, BaseSuggestions, BaseSelect, IconRefresh, Loader},
  data() {
    return {
      full_address: '',
      filter: {
        region: '',
        filter_period: null,
        city: '',
        settlement: '',
        street: '',
        house: '',
        block: '',
        zone_ws_id: null,
        zone_wd_id: null,
        source: '',
        consumption_values: [],
        consumption_coef: [],
        drainage_coef: [],
        fact_coef: [],
        supply_am: [],
        supply_pm: [],
        consumption_not_compare: 0,
        drainage_not_compare: 0,
        fact_not_compare: 0,
        illegal: 0,
        technical_conditions: 0,
        accident: 0,
        leaks: 0,
        sort_dir: null,
        sort_by: null
      },
      loader: false,
      sourcesList: [
        {id: 'ТГВ', label: 'ТГВ'},
        {id: 'ВЗУ', label: 'ВЗУ'}
      ],
      sortByOptions: [
        {id: 'fact_ratio', label: 'Коэффициент факт. водопотребления к факт. водоотведению'},
        {id: 'consumption_ratio', label: 'Коэффициент факт. водопотребления к ТУ водопотребления'},
        {id: 'drainage_ratio', label: 'Коэффициент факт. водоотведения к ТУ водоотведения'},
        {id: 'fact_consumption', label: 'Факт. водопотребление'},
        {id: 'normative_consumption', label: 'ТУ водопотребления'},
        {id: 'fact_drainage', label: 'Факт. водоотведение'},
        {id: 'normative_drainage', label: 'ТУ водоотведения'}
      ],
      sortDirOptions: [
        {id: 'asc', label: 'По возрастанию'},
        {id: 'desc', label: 'По убыванию'}
      ]
    }
  },
  computed: {
    ...mapGetters({
      zonesByNetworkType: 'zone/getZonesByNetworkType'
    }),
    cleanFilter: function () {
      return filter => {
        let payload = JSON.parse(JSON.stringify(filter))
        for (let propName in payload) {
          if (payload[propName] === null
            || payload[propName] === 0
            || payload[propName] === undefined
            || payload[propName] === ""
            || payload[propName].length === 0) {
            delete payload[propName]
          }
          if (typeof payload[propName] === 'object') {
            if (payload[propName][0] === '' && payload[propName][1] === '') {
              delete payload[propName]
            } else {
              payload[propName][0] = (parseInt(payload[propName][0]) + 100) / 100
              payload[propName][1] = (parseInt(payload[propName][1]) + 100) / 100
            }
          }
        }
        return payload
      }
    },
    validateForm: function () {
      return () => {
        let payload = JSON.parse(JSON.stringify(this.filter));
        // eslint-disable-next-line no-bitwise
        if (typeof payload.consumption_values[0] == 'number' ^ typeof payload.consumption_values[1] == 'number') {
          showAlert('Ошибка', 'Заполните поле мин. и макс. значение фильтра по объёму потребления ресурса', 'error');
          return false;
        }
        // eslint-disable-next-line no-bitwise
        if (typeof payload.consumption_coef[0] == 'number' ^ typeof payload.consumption_coef[1] == 'number') {
          showAlert('Ошибка', 'Заполните мин. и макс. значения для фильтра по несоответствию ТУ ВС', 'error');
          return false;
        }
        // eslint-disable-next-line no-bitwise
        if (typeof payload.drainage_coef[0] == 'number' ^ typeof payload.drainage_coef[1] == 'number') {
          showAlert('Ошибка', 'Заполните мин. и макс. значения для фильтра по несоответствию ТУ ВО', 'error');
          return false;
        }
        // eslint-disable-next-line no-bitwise
        if (typeof payload.fact_coef[0] == 'number' ^ typeof payload.fact_coef[0] == 'number') {
          showAlert('Ошибка',
            'Заполните мин. и макс. значения для фильтра по несоответствию водоснабжения / водоотведения', 'error');
          return false;
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch("zone/getAllZones");
  },
  methods: {
    submitFilter() {
      if (this.validateForm() === false) return false;

      let filter = {
        params: this.cleanFilter(this.filter),
        page: 1
      }

      this.loader = true;
      this.$store.commit('building/SET_FILTER_PARAMS', this.cleanFilter(this.filter));
      this.$store.dispatch("building/getBuildings")
        .then(() => {
          this.loader = false;
          this.$store.commit('building/SET_TAB_INDEX', 1)
          this.$emit("data-complete", filter)
        })
        .catch(() => this.loader = false)
    },
    clearFilter() {
      this.full_address = '';
      this.filter.region = '';
      this.filter.city = '';
      this.filter.settlement = '';
      this.filter.street = '';
      this.filter.house = '';
      this.filter.block = '';
      this.filter.zone_ws_id = null;
      this.filter.zone_wd_id = null;
      this.filter.consumption_values = [];
      this.filter.illegal = 0;
      this.filter.technical_conditions = 0;
      this.filter.accident = 0;
      this.filter.leaks = 0;
      this.filter.consumption_not_compare = 0;
      this.filter.drainage_not_compare = 0;
      this.filter.fact_not_compare = 0;
      this.filter.source = '';
      this.filter.supply_am = [];
      this.filter.supply_pm = [];
      this.filter.consumption_coef = [];
      this.filter.drainage_coef = [];
      this.filter.fact_coef = [];
      this.filter.sort_by = null;
      this.filter.sort_dir = null;
    },
    setAddress(event) {
      if (typeof event === 'object') {
        this.filter.region = event.data.region;
        this.filter.city = event.data.city;
        this.filter.settlement = event.data.settlement;
        this.filter.street = event.data.street;
        this.filter.house = event.data.house;
        this.filter.block = event.data.block;
      }
    }
  }
}
</script>

<style scoped>
.base-checkbox span {
  gap: 8px;
}
</style>
