<template>
  <div class="scada-map">
    <base-map
      :center="center"
      :zoom="zoom"
      :base-layers="baseLayers"
      :markers="markers"
    />
  </div>
</template>

<script>
import BaseMap from "@/components/BaseMap";
import {mapGetters, mapState} from "vuex";
import Marker from "@/models/baseMap/Marker";
import Icon from '@/models/baseMap/Icon';
import ScadaMapIcon from "@/components/Telemetry/Scada/ScadaMapIcon";
import {createVNode, render, watchEffect} from "vue";
import ScadaMapPopupContent from "@/components/Telemetry/Scada/ScadaMapPopupContent";

export default {
  name: "ScadaMap",
  components: {BaseMap},
  data() {
    return {
      markers: []
    }
  },
  computed: {
    ...mapState({
      center: state => state.baseMap.center,
      zoom: state => state.baseMap.zoom,
      baseLayers: state => state.baseLayers.baseLayers,
      networkEquipments: state => state.telemetry.networkEquipments
    }),
    ...mapGetters({
      networkObjectDangerStatus: 'telemetry/getNetworkObjectDangerStatus',
      networkObjectEquipments: 'telemetry/getNetworkEquipmentByNetworkObjectId',
      networkEquipmentWithParametersValues: 'telemetry/getNetworkEquipmentsWithParameterValues',
      getNetworkObjectsContainEquipments: 'telemetry/getNetworkObjectsContainEquipments'
    })
  },
  mounted() {
    this.createMapMarkers();

    watchEffect(() => {
      this.createMapMarkers();
    });
  },
  methods: {
    createMapMarkers() {
      const markers = [];

      this.getNetworkObjectsContainEquipments
          .filter(object => this.networkObjectEquipments(object.id).length)
          .forEach(object => {
            const iconContainer = document.createElement('div');
            const popupContainer = document.createElement('div');

            const iconVNode = createVNode(ScadaMapIcon, {
              isDanger: this.networkObjectDangerStatus(object.id),
              iconGroup: object._type.groupName
            })

            render(iconVNode, iconContainer);
            const iconHtml = iconContainer.innerHTML;

            const popupVNode = createVNode(ScadaMapPopupContent, {
              networkEquipments: this.networkEquipmentWithParametersValues(object.id)
            })

            render(popupVNode, popupContainer);
            const popupHtml = popupContainer.innerHTML

            const marker = new Marker({
              coords: object.coordinates,
              accumulation: true,
              icon: new Icon({
                type: 'div_icon',
                size: [36, 42],
                anchor: [18, 21],
                html: iconHtml
              }),
              popup_content: popupHtml
            })

            render(null, iconContainer);
            render(null, popupContainer);

            markers.push(marker)
          })

      this.markers = markers;
    }
  }
}
</script>

<style lang="scss">
.scada-map {
  height: calc(100vh - 230px);
  position: relative;
  z-index: 9;

  & .leaflet-popup-close-button {
    display: none;
  }

  & .leaflet-popup-tip-container {
    display: none;
  }

  & .leaflet-popup-content-wrapper {
    background: none !important;
    backdrop-filter: none !important;
    box-shadow: none !important;
    opacity: 1;
  }

  & .leaflet-popup-content {
    margin: 0;
  }
}
</style>
