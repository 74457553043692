<template>
  <base-popup
    :title="popupTitle"
    :name="popupName"
  >
    <template v-slot:body>
      <div class="form-item">
        <div class="form-item__name">Тип</div>
        <div class="form-item__value">
          <v-select
            class="vue-select-input"
            :class="{'vue-select-input--error': v$.payload.type_id.$error}"
            v-model="v$.payload.type_id.$model"
            :options="networkEquipmentTypes"
            :reduce="type => type.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите тип сетевого оборудования"/>
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Сетевой объект</div>
        <div class="form-item__value">
          <v-select
            class="vue-select-input"
            :class="{'vue-select-input--error': v$.payload.network_object_id.$error}"
            v-model="v$.payload.network_object_id.$model"
            :options="networkObjects"
            :reduce="networkObject => networkObject.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите сетевой объект"/>
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Наименование</div>
        <div class="form-item__value">
          <input
            v-model="v$.payload.name.$model"
            class="base-input base-input--bordered"
            :class="{'base-input--error': v$.payload.name.$error}"
            type="text"
            placeholder="Введите наименование">
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Серийный номер</div>
        <div class="form-item__value">
          <input
            v-model="v$.payload.serial.$model"
            class="base-input base-input--bordered"
            :class="{'base-input--error': v$.payload.serial.$error}"
            type="text"
            placeholder="Введите серийный номер">
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Инвентарный номер</div>
        <div class="form-item__value">
          <input
            v-model="payload.inventory_number"
            class="base-input base-input--bordered"
            type="text"
            placeholder="Введите инвентарный номер">
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Срок службы по паспорту (в днях)</div>
        <div class="form-item__value">
          <input
            v-model="v$.payload.durability.$model"
            class="base-input base-input--bordered"
            :class="{'base-input--error': v$.payload.durability.$error}"
            type="text"
            placeholder="Введите срок службы">
        </div>
      </div>

      <div class="form-item form-item--row">
        <div class="form-item">
          <div class="form-item__name">Дата изготовления</div>
          <div class="form-item__value">
            <base-date-picker
              v-model="v$.payload.build_date.$model"
              :error="v$.payload.build_date.$error"
              placeholder="Выберите дату"
              format="dd.MM.yyyy"
              value-format="yyyy-MM-dd"
            />
          </div>
        </div>

        <div class="form-item">
          <div class="form-item__name">Дата установки</div>
          <div class="form-item__value">
            <base-date-picker
              v-model="v$.payload.installation_date.$model"
              :error="v$.payload.installation_date.$error"
              placeholder="Выберите дату"
              format="dd.MM.yyyy"
              value-format="yyyy-MM-dd"
            />
          </div>
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Тип расположения</div>
        <div class="form-item__value">
          <v-select
            class="vue-select-input"
            :class="{'vue-select-input--error': v$.payload.location_type.$error}"
            v-model="v$.payload.location_type.$model"
            :options="locationTypesList"
            :reduce="type => type.id"
            :get-option-label="(option) => option.label"
            placeholder="Выберите тип расположения"/>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div
        @click="checkFormType()"
        class="button">
        Сохранить
      </div>
    </template>

  </base-popup>
</template>

<script>
import {mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import BasePopup from "@/components/BasePopup.vue";
import BaseDatePicker from "@/components/BaseDatePicker.vue";

export default {
  name: "EquipmentsForm",
  components: {BaseDatePicker, BasePopup},
  props: {
    networkObjectId: {
      type: Number,
      required: true
    },
    equipment: {
      required: true
    },
    popupName: {
      type: String,
      required: true
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      payload: {
        type_id: null,
        network_object_id: null,
        name: null,
        serial: null,
        durability: null,
        build_date: null,
        installation_date: null,
        location_type: null,
        inventory_number: null
      },
      locationTypesList: [
        {id: 1, label: 'Вход'},
        {id: 2, label: 'Внутри'},
        {id: 3, label: 'Выход'}
      ]
    }
  },
  validations() {
    return {
      payload: {
        type_id: {required},
        network_object_id: {required},
        name: {required},
        serial: {required},
        durability: {required},
        build_date: {required},
        installation_date: {required},
        location_type: {required}
      }
    }
  },
  computed: {
    ...mapState({
      networkObjects: state => state.networkObjects.networkObjects,
      networkEquipmentTypes: state => state.networkEquipmentTypes.networkEquipmentTypes
    }),
    popupTitle() {
      return !Object.keys(this.equipment).length ?
        'Добавить сетевое оборудование' : 'Редактирование сетевого оборудования'
    }
  },
  mounted() {
    this.$store.dispatch('networkEquipmentTypes/getNetworkEquipmentTypes');
    this.$store.dispatch('networkObjects/getAllNetworkObjects');

    this.payload.network_object_id = this.networkObjectId;

    if (Object.keys(this.equipment).length) {
      const {
        typeId,
        networkObjectId,
        name,
        serial,
        durability,
        buildDate,
        installationDate,
        locationTypeId,
        inventoryNumber
      } = this.equipment;

      this.payload.type_id = typeId;
      this.payload.network_object_id = networkObjectId;
      this.payload.name = name;
      this.payload.serial = serial;
      this.payload.durability = durability;
      this.payload.build_date = buildDate;
      this.payload.installation_date = installationDate;
      this.payload.location_type = locationTypeId;
      this.payload.inventory_number = inventoryNumber;
    }
  },
  methods: {
    checkFormType() {
      this.v$.$touch()
      if (this.v$.$invalid) return false

      if (Object.keys(this.equipment).length) this.updateNetworkEquipment()
      else this.createNetworkEquipment()
    },
    createNetworkEquipment() {
      this.$store.dispatch('networkEquipments/createNetworkEquipment', this.payload)
        .then(() => {
          this.$store.dispatch('networkObjectPage/getNetworkObjectEquipments', this.networkObjectId);
          this.$modal.hide(this.popupName);
          showToast('Сетевое оборудование добавлено', 'success');
        })
        .catch(error => showAlert('Ошибка', error));
    },
    updateNetworkEquipment() {
      this.$store.dispatch('networkEquipments/updateNetworkEquipment', {
        equipmentId: this.equipment.id,
        payload: this.payload
      })
        .then(() => {
          this.$store.dispatch('networkObjectPage/getNetworkObjectEquipments', this.networkObjectId);
          this.$modal.hide(this.popupName);
          showToast('Сетевое оборудование обновлено', 'success');
        })
        .catch(error => showAlert('Ошибка', error));
    }
  }
}
</script>

<style scoped>

</style>
