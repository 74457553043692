<template>
  <div class="emergency-element-popup">
    <div
      @click="$emit('show-modal', emergencyElement)"
      class="emergency-element-popup__header">
      #{{ emergencyElement.id }} {{ emergencyElement.type.name }}
    </div>
    <div class="emergency-element-popup__footer">
      <span>{{ emergencyElement.countIncidents }}</span> {{ createIncidentsLabel }}
    </div>
  </div>
</template>

<script>
export default {
  name: "EmergencyElementsReportPopup",
  props: {
    emergencyElement: {
      type: Object
    }
  },
  computed: {
    createIncidentsLabel() {
      const words = ['инцидент', 'инцидента', 'инцидентов'];
      let number = this.emergencyElement.countIncidents

      number = Math.abs(number) % 100;
      let n1 = number % 10;
      if (number > 10 && number < 20) { return words[2]; }
      if (n1 > 1 && n1 < 5) { return words[1]; }
      if (n1 == 1) { return words[0]; }
      return words[2];
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
