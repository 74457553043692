<template>
  <div class="issue-tasks-list">
    <div class="issue-tasks-list__progress">
      <div class="issue-tasks-list__progress-caption">
        <icon-tasks/>
        <div class="issue-tasks-list__progress-title">
          Прогресс выполнения
          <span>{{getClosedIssuesCount}}/{{getTasksExpectCanceledLength}}</span>
        </div>
      </div>
      <div class="issue-tasks-list__bar">
        <div class="issues-tasks__bar">
          <div :style="{width: completedTasksPercent}"
               class="issues-tasks__bar-progress"/>
        </div>
      </div>
    </div>

    <div class="additional-options-list__inner">
      <div v-for="task in getFilteredTasksList"
           :key="task.id"
           @click="openTaskModal(task.id)"
           class="added-item added-item--flex"
           :class="{'done': !task.state.isActive}">
        <div class="added-item__info">
          <div class="added-item__name">{{ task.type_label }}</div>
          <div class="added-item__additional">
            <div v-if="task.actual_start_date"
                 class="added-item__additional-time">
              {{ formatDateTime(task.actual_start_date) }}
            </div>
            <div v-if="task.actual_start_date"
                 class="added-item__additional-time">-</div>
            <div v-if="task.actual_end_date"
                 class="added-item__additional-time">
              {{ formatDateTime(task.actual_end_date) }}
            </div>
          </div>
        </div>
        <div class="task-resource">
          {{ nullFilter(task.brigade_name)}}
        </div>
        <div class="task-remaining">
          <div v-show="task.state.isActive && task.planned_end_date">
            Осталось:
            <span
              class="task-remaining__time"
              :class="{'task-remaining__time--danger': getTaskTimeUntilEnd(task.planned_end_date) < 0}">
              {{getTaskTimeUntilEnd(task.planned_end_date)}} ч.
            </span>
          </div>
        </div>
        <div class="added-item__right">
          {{task.state.label}}
        </div>
      </div>
    </div>

    <div
      @click="toggleCanceledTasksVisible()"
      class="issue-tasks-list__toggle-canceled-issues">
      <span v-if="!isCanceledTasksVisible">Показать отмененные</span>
      <span v-else>Скрыть отмененные</span>
    </div>

    <div
      v-if="isIssueActive"
      @click="showAddTaskModal()"
      class="button button--mode-outline">
      <icon-plus/>
      <span>Добавить новое</span>
    </div>
  </div>
</template>

<script>
import TaskState from "@/models/serviceDesk/TaskState";
import IssueTasksForm from "@/components/ServiceDesk/Issues/IssueComponents/IssueTasksList/IssueTasksForm";
import {modalAdaptiveWidth} from "@/utils/helpers";
import {mapGetters, mapState} from "vuex";
import sideModalControls from "@/mixins/sideModalControls";
import IconTasks from "@/components/Icons/IconTasks.vue";
import IconPlus from "@/components/Icons/IconPlus.vue";
import {formatDateTime, nullFilter} from "@/utils/filters";
import moment from "moment";

export default {
  name: "IssueTasksList",
  components: {IconPlus, IconTasks},
  mixins: [sideModalControls],
  props: {
    tasksList: {
      type: Array,
      required: true
    },
    issueId: {
      type: Number,
      required: true
    },
    isIssueActive: {
      type: Boolean,
      required: true
    },
    issueCategoryGroup: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isCanceledTasksVisible: false
    }
  },
  computed: {
    TaskState: () => TaskState,
    ...mapState({
      departmentsList: state => state.departments.departments
    }),
    ...mapGetters({
      taskTypesList: 'task_types/getActiveTaskTypesByCategoryGroup'
    }),
    getTasksExpectCanceledLength() {
      return this.tasksList.filter(task => !task.state.isCanceled).length;
    },
    getClosedIssuesCount() {
      return this.tasksList
        .filter(task => !task.state.isActive && !task.state.isCanceled).length
    },
    completedTasksPercent() {
      return `${Math.round(this.getClosedIssuesCount / this.getTasksExpectCanceledLength * 100)}%`
    },
    getFilteredTasksList() {
      return this.isCanceledTasksVisible ? this.tasksList : this.tasksList.filter(task => !task.state.isCanceled);
    }
  },
  methods: {
    nullFilter,
    formatDateTime,
    showAddTaskModal() {
      this.$modal.show(
        IssueTasksForm,
        {
          'task-types-list': this.taskTypesList(this.issueCategoryGroup),
          'departments-list': this.departmentsList,
          'issue-id': this.issueId
        },
        {
          name: 'issue-tasks-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        }
      )
    },
    toggleCanceledTasksVisible() {
      this.isCanceledTasksVisible = !this.isCanceledTasksVisible;
    },
    getTaskTimeUntilEnd(plannedEndDate) {
      return moment(plannedEndDate).diff(moment(), 'hours')
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers/index" as *;

.added-item {
  cursor: pointer;
}

.issue-tasks-list {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__progress {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &-caption {
      display: flex;
      gap: 10px;
    }

    &-title {
      display: flex;
      align-items: center;
      gap: 10px;

      @include font-style(600, 16px, 24px, var(--text-primary));

      & span {
        @include font-style(400, 14px, 16px, var(--text-primary));
      }
    }
  }

  &__toggle-canceled-issues {
    cursor: pointer;
    @include font-style(400, 16px, 24px, var(--text-secondary));
  }
}

.issues-tasks__bar {
  border-radius: 0px 20px 20px 0px;
  background-color: var(--bg-light-green);
  height: 6px;
  display: flex;
  width: 100%;
  overflow: hidden;
}
.issues-tasks__bar-progress {
  border-radius: 20px;
  background: var(--status-done);
  display: flex;
  height: 6px;
  width: 0;
}

.added-item__info {
  flex: 3;
}

.task-resource {
  flex: 2;
  color: var(--text-primary);
}

.task-remaining {
  flex: 1.5;

  @include font-style(400, 14px, 16px, var(--text-secondary));

  &__time {
    color: var(--text-primary);

    &--danger {
      color: var(--border-validation);
    }
  }
}

.added-item__right {
  flex: 0.7;
}
</style>
