import {createRouter, createWebHistory} from 'vue-router';
import store from "@/store/index"
import vuexStore from "@/store";
import middlewarePipeline from "@/router/middlewarePipeline";
import {nextTick} from "vue";
import routes from "@/router/routes";

const router = createRouter({
    history: createWebHistory(),
    routes: routes
});

router.beforeEach((to, from, next) => {
    const windowInnerWidth = document.documentElement.clientWidth;
    if (windowInnerWidth <= 1440) vuexStore.commit('sidebar/CHANGE_SIDEBAR_STATE', true);
    vuexStore.dispatch('baseSideModal/closeModal');

    const {middleware, permission} = to.meta;
    const context = {to, from, next, store, permission};

    if (!middleware) {
        return next();
    }
    middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    });
});

router.afterEach(async to => {
    await nextTick(() => {
        document.title = to.meta.title;
    });
});

export default router;
