<template>
  <div class="scada-map-popup">
    <div v-for="networkEquipment in networkEquipments"
         :key="networkEquipment.id"
         class="scada-map-popup__accordion">
      <div data-toggle-accordion="accordion-content-id"
           class="scada-map-popup__accordion-title">
        <div class="scada-map-popup__accordion-caption">{{networkEquipment.name}}</div>
        <img src="@/assets/img/telemetry/accordion-arrow.svg"
             alt="свернуть"
             class="scada-map-popup__accordion-control">
      </div>
      <div id="accordion-content-id"
           class="scada-map-popup__accordion-content opened">
        <div class="scada-map-popup__parameters-row">
          <div>T1 / T2</div>
          <div>
            <span class="scada-map-popup__parameter-value"
                  :class="{'danger': isValueDanger(networkEquipment.parameters, 'T1')}">
              {{getParameterValueByName(networkEquipment.parameters, 'T1')}}
            </span>
            <span> / </span>
            <span class="scada-map-popup__parameter-value"
                  :class="{'danger': isValueDanger(networkEquipment.parameters, 'T2')}">
              {{getParameterValueByName(networkEquipment.parameters, 'T2')}}
            </span>
          </div>
        </div>
        <div class="scada-map-popup__parameters-row">
          <div>P1 / P2</div>
          <div>
            <span class="scada-map-popup__parameter-value"
                  :class="{'danger': isValueDanger(networkEquipment.parameters, 'P1')}">
              {{getParameterValueByName(networkEquipment.parameters, 'P1')}}
            </span>
            <span> / </span>
            <span class="scada-map-popup__parameter-value"
                  :class="{'danger': isValueDanger(networkEquipment.parameters, 'P2')}">
              {{getParameterValueByName(networkEquipment.parameters, 'P2')}}
            </span>
          </div>
        </div>
        <div class="scada-map-popup__parameters-row">
          <div>G1 / G2</div>
          <div>
            <span class="scada-map-popup__parameter-value"
                  :class="{'danger': isValueDanger(networkEquipment.parameters, 'G1')}">
              {{getParameterValueByName(networkEquipment.parameters, 'G1')}}
            </span>
            <span> / </span>
            <span class="scada-map-popup__parameter-value"
                  :class="{'danger': isValueDanger(networkEquipment.parameters, 'G2')}">
              {{getParameterValueByName(networkEquipment.parameters, 'G2')}}
            </span>
          </div>
        </div>
        <div class="scada-map-popup__parameters-row">
          <div>Q</div>
          <div>
            <span class="scada-map-popup__parameter-value"
                  :class="{'danger': isValueDanger(networkEquipment.parameters, 'Q')}">
              {{getParameterValueByName(networkEquipment.parameters, 'Q')}}
            </span>
          </div>
        </div>
        <div class="scada-map-popup__parameters-row">
          <div>∆G</div>
          <div>
            <span class="scada-map-popup__parameter-value"
                  :class="{'danger': isValueDanger(networkEquipment.parameters, '∆G')}">
              {{getParameterValueByName(networkEquipment.parameters, '∆G')}}
            </span>
          </div>
        </div>
        <div class="scada-map-popup__parameters-row">
          <div>∆Q</div>
          <div>
            <span class="scada-map-popup__parameter-value"
                  :class="{'danger': isValueDanger(networkEquipment.parameters, '∆Q')}">
              {{getParameterValueByName(networkEquipment.parameters, '∆Q')}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ParameterValue from "@/models/telemetry/ParameterValue";
import {isValueOutOfProfile} from "@/utils/helpers";
import store from "@/store";

export default {
  name: "ScadaMapPopupContent",
  props: {
    networkEquipments: {
      type: Array
    }
  },
  computed: {
    ParameterValue: () => ParameterValue
  },
  methods: {
    isValueOutOfProfile,
    getParameterValueByName(parameters, name) {
      const parameter = parameters.find(parameter => parameter.shortName === name)
      return parameter ? parameter.parameterValue.value : '—'
    },
    isValueDanger(parameters, name) {
      const parameter = parameters.find(parameter => parameter.shortName === name);
      return parameter ? isValueOutOfProfile(
        parameter.parameterValue.value,
        store.getters['telemetry/getParameterProfile'](parameter.id)
      ) : false
    }
  }
}
</script>

<style lang="scss">
@use "@/assets/scss/helpers/index" as *;

.scada-map-popup {
  display: inline-block;

  padding: 8px;

  border-radius: 4px;
  border: 1px solid var(--border-dark-grey);
  background: var(--bg-sidebar);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.25);

  &__accordion:not(:first-child) {
    padding-top: 8px;
  }

  &__accordion-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    min-width: 100px;

    padding-bottom: 4px;
    border-bottom: 1px solid var(--border-dark-grey);
  }

  &__accordion-caption {
    @include font-style(400, 10px, 12px, var(--text-placeholder));
  }

  &__accordion-control {
    cursor: pointer;
  }

  &__accordion-content {
    display: none;
    flex-direction: column;
    gap: 4px;

    padding-top: 4px;
    padding-bottom: 4px;

    @include transition();

    &.opened {
      display: flex;
    }
  }

  &__parameters-row {
    display: grid;
    grid-template-columns: 40px 1fr;
    gap: 6px;

    & div:first-child {
      @include font-style(600, 10px, 12px, var(--text-placeholder));
    }

    & div:last-child {
      @include font-style(600, 10px, 12px, var(--text-panel));
    }
  }

  &__parameter-value {
    &.danger {
      color: var(--border-validation);
      border-radius: 4px;
      padding: 0 1px 0 3px;
      background-color: rgba(255, 58, 129, 0.20);
    }
  }
}
</style>