<template>
  <div class="i-modal__content-wrapper">
    <div class="i-modal__content">
      <div v-if="Object.keys(networkObject).length"
           class="i-modal__inner">
        <div class="base-modal__left-side base-modal__left-side--hidden">
          <div class="base-modal-header base-modal-header--padding-s">
            <span class="base-modal-title">{{ networkObject.name }}</span>
          </div>

          <div class="base-modal-info base-modal-info--gap-24 base-modal-info--padding-s">
            <div class="base-modal-info__card">
              <base-collapse>
                <template #toggle="{toggle, isCollapsed}">
                  <div class="base-modal-info__card-title base-modal-info__card-title--loader">
                    <span class="base-modal-info__card-collapse"
                          :class="{'base-modal-info__card-collapse--closed': isCollapsed}"
                          @click="toggle()">
                      Детальная информация
                      <icon-arrow-down/>
                    </span>
                  </div>
                </template>

                <template #body>
                  <network-object-details
                    :type-label="networkObject.getTypeLabel()"
                    :category-name="networkObject._type.name"
                    :category-group-name="networkObject._type.groupName"
                    :phone="networkObject.phone"
                    :structural-units-names="networkObject.structuralUnitsNames"
                    :address="networkObject.address"
                    :coordinates="networkObject.coordinates"
                  />
                </template>
              </base-collapse>
            </div>

            <div class="base-modal-info__card">
              <base-collapse>
                <template #toggle="{toggle, isCollapsed}">
                  <div class="base-modal-info__card-title base-modal-info__card-title--loader">
                    <span class="base-modal-info__card-collapse"
                          :class="{'base-modal-info__card-collapse--closed': isCollapsed}"
                          @click="toggle()">
                      Последний обход
                      <icon-arrow-down/>
                    </span>
                  </div>
                </template>

                <template #body>
                  <div class="last-task">
                    <div class="base-modal-info__item">
                      <div class="base-modal-info__name">
                        <span>Дата обхода</span>
                      </div>
                      <div class="base-modal-info__value">
                        <p class="base-modal-info__value-txt">
                          {{ formatDateTime(networkObject.lastTask.finishedDate) }}
                        </p>
                      </div>
                    </div>

                    <div class="base-modal-info__item">
                      <div class="base-modal-info__name">
                        <span>Обходчик</span>
                      </div>
                      <div class="base-modal-info__value">
                        <p class="base-modal-info__value-txt">{{ networkObject.lastTask.resource.name }}</p>
                      </div>
                    </div>

                    <div
                      v-if="networkObject.lastTask.attachments.length"
                      class="task-files-list">
                      <div class="task-files-list__title">Фото и видео</div>
                      <files-list
                        :files-list="networkObject.lastTask.attachments"
                        :max-length="10"
                      />
                    </div>

                    <div class="tabs">
                      <div class="tabs__header tabs__header--block">
                        <div
                          @click="lastTaskActiveTab = 0"
                          :class="{'active': lastTaskActiveTab === 0}"
                          class="tabs__header-item">Чек-лист
                        </div>

                        <div
                          @click="lastTaskActiveTab = 1"
                          :class="{'active': lastTaskActiveTab === 1}"
                          class="tabs__header-item">Дефекты и нарушения
                        </div>
                      </div>

                      <div :class="{'active': lastTaskActiveTab === 0}"
                           class="tabs__item">
                        <questions-list
                          :questions-list="questionsList"
                          :answers-list="networkObject.lastTask.answers"
                        />
                      </div>

                      <div :class="{'active': lastTaskActiveTab === 1}"
                           class="tabs__item">
                        <violations-list :violations-list="networkObject.lastTask.violations"/>
                      </div>
                    </div>

                  </div>
                </template>
              </base-collapse>
            </div>

            <div class="tabs">
              <div class="tabs__header tabs__header--block">
                <div
                  @click="activeTab = 0"
                  :class="{'active': activeTab === 0}"
                  class="tabs__header-item">Обходы
                </div>
              </div>

              <div
                :class="{'active': activeTab === 0}"
                class="tabs__item">
                <div class="technical-inspection-tasks">
                  <div class="technical-inspection-tasks__range-picker">
                    <base-date-range-picker @updateDateRange="setRange($event)" />
                    <base-date-picker
                      v-model="range"
                      format="dd.MM.yyyy"
                      value-format="yyyy-MM-dd"
                      placeholder="Выберите период"
                      range
                      @update:modelValue="getTechnicalInspectionTasks"
                    />
                  </div>

                  <technical-inspection-object-tasks-list :tasks-list="technicalInspectionTasksList" />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div v-if="Object.keys(networkObject).length"
         class="i-modal-btn-wp i-modal-btn-wp--back">
      <div class="task-modal__mobile-actions">
        <button @click="$store.dispatch('baseSideModal/closeModal')"
                class="button button--mode-outline task-modal__mobile-back">
          <icon-arrow-left/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCollapse from "@/components/BaseCollapse.vue";
import NetworkObjectDetails from "@/components/Telemetry/admin/NetworkObject/NetworkObjectDetails.vue";
import IconArrowLeft from "@/components/Icons/IconArrowLeft.vue";
import IconArrowDown from "@/components/Icons/IconArrowDown.vue";
import {mapState} from "vuex";
import {formatDateTime} from "@/utils/filters";
import FilesList from "@/components/ServiceDesk/CommonComponents/FilesList/FilesList.vue";
import QuestionsList from "@/components/TechnicalInspection/Tasks/QuestionsList.vue";
import ViolationsList from "@/components/TechnicalInspection/Tasks/ViolationsList.vue";
import BaseDateRangePicker from "@/components/BaseDateRangePicker.vue";
import {TechnicalInspectionTaskState} from "@/models/TechnicalInspection/TechnicalInspectionTaskState";
import TechnicalInspectionObjectTasksList
  from "@/components/TechnicalInspection/NetworkObjects/TechnicalInspectionObjectTasksList.vue";
import BaseDatePicker from "@/components/BaseDatePicker.vue";

export default {
  name: "TechnicalInspectionObjectModalContent",
  components: {
    BaseDatePicker,
    TechnicalInspectionObjectTasksList,
    BaseDateRangePicker,
    ViolationsList,
    QuestionsList,
    FilesList,
    IconArrowDown,
    IconArrowLeft,
    NetworkObjectDetails,
    BaseCollapse
  },
  data() {
    return {
      lastTaskActiveTab: 0,
      activeTab: 0,
      range: []
    }
  },
  computed: {
    ...mapState({
      networkObject: state => state.technicalInspectionObjects.object,
      questionsList: state => state.questions.questionsList,
      technicalInspectionTasksList: state => state.technicalInspectionTasks.tasksList
    })
  },
  beforeUnmount() {
    this.$store.commit('technicalInspectionTasks/SET_TASKS_LIST', []);
  },
  methods: {
    formatDateTime,
    setRange(date) {
      this.range = [date.start, date.end];
      this.getTechnicalInspectionTasks();
    },
    getTechnicalInspectionTasks() {
      const [start, end] = this.range;

      this.$store.dispatch('technicalInspectionTasks/getTasksList', {
        status: TechnicalInspectionTaskState.FINISHED,
        object_id: this.networkObject.id,
        start_fin_period: start,
        end_fin_period: end
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers/index" as *;

.task-files-list {
  display: flex;
  flex-direction: column;
  gap: 20px;

  border-radius: 16px;
  background: var(--panel-bg);

  &__title {
    @include font-style(500, 20px, 28px, var(--text-primary));
  }
}

.last-task {
  & .task-files-list {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.technical-inspection-tasks {
  &__range-picker {
    display: flex;
    gap: 24px;
    margin-bottom: 20px;
  }
}
</style>
