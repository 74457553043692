<template>
  <div ref="filterModalEl"
       class="base-filter-modal">
    <div class="base-filter-modal__body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseFilterModal",
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filterModalEl: null
    }
  },
  watch: {
    isVisible: function (isVisible) {
      this.toggleModal(isVisible)
    }
  },
  mounted() {
    document.addEventListener('click', this.closeModal)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeModal)
  },
  methods: {
    toggleModal(isVisible) {
      if (isVisible) this.$refs.filterModalEl.classList.add('is-active')
      else this.$refs.filterModalEl.classList.remove('is-active')
    },
    closeModal(e) {
      if ((!e.target.closest('[data-modal-toggle]') &&
          !e.target.closest('.base-filter-modal') &&
          !e.target.closest('.mx-datepicker-main') &&
          !e.target.closest('.filter__button') &&
          !e.target.closest('.vs__selected')) || e.target.closest('[data-modal-close]')) {
        this.$refs.filterModalEl.classList.remove('is-active');
        this.$emit('toggle-modal', false);
      }
    }
  }
}
</script>

<style scoped>

</style>
