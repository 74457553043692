<template>
  <component
    v-if="!isLoadingProjectConfigurations"
    :is="layout">
    <router-view/>
  </component>
</template>

<script>
import MainTemplate from "@/layout/MainTemplate";
import LoginTemplate from "@/layout/LoginTemplate";
import {mapGetters} from "vuex";

export default {
  name: 'App',
  components: {MainTemplate, LoginTemplate},
  computed: {
    ...mapGetters({
      isLoadingProjectConfigurations: 'projectConfig/isLoading'
    }),
    layout() {
      return (this.$route.meta?.layout || 'Main') + '-Template'
    }
  },
  mounted() {
    this.$store.dispatch("projectConfig/getProjectConfig");
  }
}
</script>

<style lang="scss">
@import 'assets/scss/main.scss';
</style>
