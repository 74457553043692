<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      Добавить расход воды
      <span @click="$modal.hide('water-flow-form')"
            class="base-popup__close">
        <icon-close />
      </span>
    </div>
    <div class="popup-form-group">
      <div class="form-item">
        <div class="form-item__name">Расходованный объем воды, м3</div>
        <div class="form-item__value form-item__value--column form-item__value--items-left">
          <input
            v-mask
            v-model="v$.payload.volume.$model"
            :class="{'base-input--error': v$.payload.volume.$error}"
            class="base-input base-input--bordered"
            name="material"
            placeholder="Введите объем воды">

          <div
            v-if="v$.payload.volume.$error && !v$.payload.volume.minValue"
            class="base-input__label base-input__label--error">
            <div>Значение должно быть больше 0</div>
          </div>
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Статья списания</div>
        <div class="form-item__value">
          <v-select
            v-model="v$.payload.type.$model"
            :options="waterFlowTypes"
            :reduce="type => type.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите из списка"
            :class="{'vue-select-input--error': v$.payload.type.$error}"
            class="vue-select-input"
          />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Дата расхода</div>
        <div class="form-item__value">
          <base-date-picker
            v-model="v$.payload.released_at.$model"
            :minutes-increment="15"
            placeholder="Выберите дату"
            format="dd.MM.yyyy HH:mm"
            value-type="YYYY-MM-DD HH:mm"
            :error="v$.payload.released_at.$error"
          />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Комментарий</div>
        <div class="form-item__value">
          <textarea
            v-model="payload.comment"
            class="base-textarea"
            type="text"
            name="material"
            placeholder="Введите комментарий"
          />
        </div>
      </div>
    </div>
    <div class="base-popup__footer">
      <div @click="submitForm()"
           class="button">
        Добавить
      </div>
    </div>
  </div>
</template>

<script>
import {showAlert, showToast} from "@/utils/notification";
import IconClose from "@/components/Icons/IconClose";
import Inputmask from "bitweb-inputmask";
import { useVuelidate } from '@vuelidate/core'
import { required, minValue } from '@vuelidate/validators'
import BaseDatePicker from "@/components/BaseDatePicker.vue";

export default {
  name: "WaterFlowsForm",
  components: {BaseDatePicker, IconClose},
  directives: {
    mask: {
      bind(el) {
        const mask = new Inputmask({
          alias: 'numeric',
          groupSeparator: '',
          radixPoint: '.',
          digits: 2,
          digitsOptional: false,
          allowMinus: false,
          rightAlign: 0,
          min: 0,
          max: 99999999.99
        });

        mask.mask(el);

        el.addEventListener('keyup', () => {
          const event = new Event('input', { bubbles: true });
          el.dispatchEvent(event);
        })
      }
    }
  },
  props: {
    taskId: {
      type: Number,
      required: true
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      payload: {
        volume: null,
        type: null,
        released_at: null,
        comment: null
      },
      waterFlowTypes: [
        {id: 1, name: 'Реализация воды'},
        {id: 2, name: 'Собственные нужды'},
        {id: 3, name: 'Технологический сброс'}
      ]
    }
  },
  validations() {
    return {
      payload: {
        volume: {
          required,
          minValue: minValue(0.01)
        },
        type: {required},
        released_at: {required}
      }
    }
  },
  methods: {
    submitForm() {
      this.v$.$touch()
      if (this.v$.$invalid) return false

      this.$store.dispatch('waterFlows/addWaterFlow', {
        taskId: this.taskId,
        payload: this.payload
      })
        .then(() => {
          this.$modal.hide('water-flow-form');
          this.$store.dispatch('taskModal/getWaterFlowsList', this.taskId);
          showToast('Расход воды добавлен', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
    }
  }
}
</script>

<style scoped>
.form-item__name {
  width: 240px;
}
</style>
