<template>
  <div class="page-wrapper">
    <base-panel
      title="Статистика по объектам"
      :is-add-button-visible="false"
    />

    <div class="resource-statistic-date-picker">
      <base-date-range-picker @updateDateRange="setDateRange($event)"/>
      <div class="resource-statistic-date-picker__input">
        <base-date-picker
          v-model="reportPeriod"
          format="dd.MM.yyyy"
          value-format="yyyy-MM-dd"
          placeholder="Выберите период"
          range
          @update:modelValue="getObjectsStatistic"
        />
      </div>
    </div>

    <base-content-loaders v-if="loading"/>

    <base-no-data v-if="!objectsStatistic.length && !loading"/>

    <div
      v-if="objectsStatistic.length"
      class="inspection-objects-statistic"
    >
      <div class="base-table base-table__sub-header">
        <div class="base-table__container">
          <div class="base-table__header base-table--inspection-objects">
            <div class="base-table__header-cell">Объект</div>
            <div class="base-table__header-cell">Всего обходов</div>
            <div class="base-table__header-cell">Найдено нарушений</div>
            <div class="base-table__header-cell">Устранено нарушений</div>
          </div>

          <div class="base-table__header base-table--inspection-objects">
            <div class="base-table__header-cell">Итого</div>
            <div class="base-table__header-cell base-table__header-cell--bold-600">{{ tasksCount }}</div>
            <div class="base-table__header-cell base-table__header-cell--bold-600">{{ violationsCount }}</div>
            <div class="base-table__header-cell base-table__header-cell--bold-600">{{ resolvedViolationsCount }}</div>
          </div>


          <div
            v-for="stat in objectsStatistic"
            :key="stat.object.id"
            class="base-table__row base-table--inspection-objects"
          >
            <div class="base-table__column base-table__column--emergency-elements">
              <div class="base-table__text">{{ stat.object.name }}</div>
              <div class="base-table__text--secondary">{{ stat.object.address }}</div>
            </div>
            <div class="base-table__column">
              <div class="base-table__text">{{ stat.tasks_count }}</div>
            </div>
            <div class="base-table__column">
              <div class="base-table__text">{{ stat.violations_count }}</div>
            </div>
            <div class="base-table__column">
              <div class="base-table__text">{{ stat.resolved_violations_count }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel.vue";
import BaseDateRangePicker from "@/components/BaseDateRangePicker.vue";
import moment from "moment/moment";
import {mapState, mapGetters} from "vuex";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import BaseDatePicker from "@/components/BaseDatePicker.vue";

export default {
  name: "TechnicalInspectionObjectsStatisticPage",
  components: {BaseDatePicker, BaseNoData, BaseContentLoaders, BaseDateRangePicker, BasePanel},
  data() {
    return {
      reportPeriod: []
    }
  },
  computed: {
    ...mapState({
      objectsStatistic: state => state.technicalInspectionStatistic.objectsStatistic,
      loading: state => state.technicalInspectionStatistic.loading
    }),
    ...mapGetters({
      tasksCount: 'technicalInspectionStatistic/getObjectsTasksCount',
      violationsCount: 'technicalInspectionStatistic/getObjectsViolationsCount',
      resolvedViolationsCount: 'technicalInspectionStatistic/getObjectsResolvedViolationsCount'
    })
  },
  beforeUnmount() {
    this.$store.commit('technicalInspectionStatistic/SET_OBJECTS_STATISTIC', []);
  },
  mounted() {
    this.reportPeriod = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
    this.getObjectsStatistic();
  },
  methods: {
    setDateRange(range) {
      this.reportPeriod = [range.start, range.end];
      this.getObjectsStatistic();
    },
    getObjectsStatistic() {
      const [start, end] = this.reportPeriod;
      this.$store.dispatch('technicalInspectionStatistic/getObjectsStatistic', {
        start_period: moment(start).format('YYYY-MM-DD 00:00'),
        end_period: moment(end).format('YYYY-MM-DD 23:59')
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.resource-statistic-date-picker {
  display: flex;
  gap: 24px;
  margin-bottom: 36px;

  &__input {
    width: 320px;
  }
}

.base-table--inspection-objects {
  grid-template-columns: 2fr repeat(3, 1fr);
}
</style>
