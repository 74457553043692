import store from "@/store";
import moment from "moment";
export default {
    methods: {
        getParameterValueDateClass(parameterId) {
            const parameterValue = store.getters['telemetry/getParameterValue'](parameterId);

            if (parameterValue === {}) return 'time_error'

            const currentDate = new Date();
            const dayStart = new Date().setHours(0, 0, 0, 0);
            const parameterValueDate = new Date(parameterValue?.readingAt);

            if ((currentDate - parameterValueDate) <= 300000) {
                return 'time_success'
            } else if (dayStart < parameterValueDate && (currentDate - parameterValueDate) >= 300000) {
                return 'time_warn'
            } else if (dayStart > parameterValueDate) {
                return 'time_error'
            }
        },
        formatParameterValueDate(parameterId) {
            const parameterValue = store.getters['telemetry/getParameterValue'](parameterId);

            if (parameterValue === {}) return 'Ошибка'

            const dayStart = new Date().setHours(0, 0, 0, 0);
            const lastValueDate = new Date(parameterValue?.readingAt);

            if (dayStart < lastValueDate) {
                return moment(parameterValue?.readingAt).format("HH:mm");
            } else {
                return moment(parameterValue?.readingAt).format("DD.MM.YYYY");
            }
        },
        getLastParameterValue(parameters) {
            const parameterValue = parameters
                .map(parameter => store.getters['telemetry/getParameterValue'](parameter.id))
                .sort((a, b) => new Date(b.readingAt) - new Date(a.readingAt))
            const [lastValue] = parameterValue;
            return lastValue
        }
    }
}