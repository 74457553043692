<template>
  <base-popup
    :title="popupTitle"
    :name="popupName"
  >
    <template #body>
      <div class="form-item">
        <div class="form-item__name">Наименование</div>
        <div class="form-item__value">
          <input
            v-model="v$.payload.name.$model"
            class="base-input base-input--bordered"
            :class="{'base-input--error': v$.payload.name.$error}"
            type="text"
            placeholder="Введите наименование">
        </div>
      </div>

      <div class="form-item-group">
        <div class="form-item">
          <div class="form-item__name">Мастер</div>
          <div class="form-item__value">
            <v-select
              class="vue-select-input"
              v-model="payload.master_id"
              :options="staffList"
              :reduce="employee => employee.id"
              :get-option-label="(option) => option.name"
              placeholder="Выберите мастера"/>
          </div>
        </div>

        <div class="form-item">
          <div class="form-item__name">Транспорт</div>
          <div class="form-item__value">
            <v-select
              class="vue-select-input"
              v-model="payload.transport_id"
              :options="vehiclesList"
              :reduce="vehicle => vehicle.id"
              :get-option-label="(option) => option.attributes.label"
              placeholder="Выберите транспорт"/>
          </div>
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Подразделение</div>
        <div class="form-item__value">
          <v-select
            class="vue-select-input"
            :class="{'vue-select-input--error': v$.payload.department_id.$error}"
            v-model="v$.payload.department_id.$model"
            :options="departmentsList"
            :reduce="department => department.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите подразделение"/>
        </div>
      </div>

      <div
        v-if="!Object.keys(resource).length"
        class="form-item ">
        <div class="form-item__name">Структурное подразделение</div>
        <div class="form-item__value">
          <v-select
            class="vue-select-input"
            :class="{'vue-select-input--error': v$.payload.structural_unit_id.$error}"
            v-model="v$.payload.structural_unit_id.$model"
            :options="structuralUnitsList"
            :reduce="structuralUnit => structuralUnit.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите структурное подразделение"/>
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name form-item__name--with-loader">
          Идентификатор внешнего склада 1С

          <base-loader v-if="warehousesLoading"/>
        </div>
        <div class="form-item__value">
          <v-select
            class="vue-select-input"
            v-model="payload.external_warehouse_id"
            :options="warehousesList"
            :reduce="warehouse => warehouse.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите склад"
          />
        </div>
      </div>

      <div class="form-item form-item--border-top">
        <div class="form-item__name">Состав ресурса</div>
        <div class="resource-members">
          <resource-members-form-item
            v-for="(v, index) in payload.members"
            :key="index"
            :arr-index="index"
            :full-name="v.full_name"
            :full-name-error="v$.payload.members.$each.$response.$errors[index].full_name"
            :position="v.position"
            :position-error="v$.payload.members.$each.$response.$errors[index].position"
            :category="v.category"
            :electricity-group="v.electricity_group"
            :foreman="v.foreman"
            @full-name="full_name => v.full_name = full_name"
            @position="position => v.position = position"
            @category="category => v.category = category"
            @electricity-group="electricityGroup => v.electricity_group = electricityGroup"
            @foreman="foreman => v.foreman = foreman"
            @remove-member="removeMember"
          />

          <span class="resource-members__add-btn">
            <button
              @click="addMember()"
              class="button button--mode-outline">
              <icon-plus/>
              Добавить
            </button>
          </span>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div
        @click="checkFormType()"
        class="button">
        <span v-if="!Object.keys(resource).length">Создать</span>
        <span v-else>Сохранить</span>
      </div>
    </template>
  </base-popup>
</template>

<script>
import BasePopup from "@/components/BasePopup.vue";
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import {mapState} from "vuex";
import ResourceMembersFormItem from "@/components/ServiceDesk/admin/Resources/ResourceMembersFormItem.vue";
import IconPlus from "@/components/Icons/IconPlus.vue";
import BaseLoader from "@/layout/BaseLoader.vue";

export default {
  name: "ResourcesForm",
  components: {BaseLoader, IconPlus, ResourceMembersFormItem, BasePopup},
  props: {
    resource: {
      type: Object,
      required: true
    },
    createResource: {
      type: Function,
      default: () => {}
    },
    updateResource: {
      type: Function,
      default: () => {}
    },
    popupName: {
      type: String,
      required: true
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      payload: {
        name: null,
        master_id: null,
        transport_id: null,
        department_id: null,
        structural_unit_id: null,
        members: [],
        external_warehouse_id: null
      }
    }
  },
  validations() {
    const validations = {
      payload: {
        name: {required},
        department_id: {required},
        members: {
          $each: helpers.forEach({
            full_name: {required},
            position: {required}
          })
        }
      }
    }

    if (Object.keys(this.resource).length) return validations

    validations.payload.structural_unit_id = {required};
    return validations;
  },
  computed: {
    popupTitle() {
      return !Object.keys(this.resource).length ? 'Создать ресурс' : 'Редактирование ресурса'
    },
    ...mapState({
      staffList: state => state.user.structuralUnitUsersList,
      vehiclesList: state => state.autos.autos,
      departmentsList: state => state.departments.departments,
      structuralUnitsList: state => state.structuralUnits.userStructuralUnitsList,
      warehousesList: state => state.warehouses.warehousesList,
      warehousesLoading: state => state.warehouses.loading
    })
  },
  mounted() {
    if (Object.keys(this.resource).length) {
      const {name, master, transport, department, members, externalWarehouseId} = this.resource;
      this.payload.name = name;
      this.payload.master_id = master?.id;
      this.payload.transport_id = transport?.id;
      this.payload.department_id = department.id;
      this.payload.members = members;
      this.payload.external_warehouse_id = externalWarehouseId;
    }
  },
  methods: {
    addMember() {
      this.payload.members.push({
        full_name: null,
        position: null,
        category: null,
        electricity_group: null,
        foreman: false
      });
    },
    removeMember(index) {
      this.payload.members.splice(index, 1);
    },
    checkFormType() {
      this.v$.$touch()
      if (this.v$.$invalid) return false

      if (!Object.keys(this.resource).length) this.createResource(this.payload)
      else this.updateResource(this.resource.id, this.payload)
    }
  }
}
</script>

<style lang="scss" scoped>
.resource-members {
  display: flex;
  flex-direction: column;

  &__add-btn {
    display: block;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--border-main);

    & .button {
      width: 100%;
    }
  }
}
</style>
