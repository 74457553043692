<template>
  <form class="fuel-and-lubricants-report-form">
    <div class="fuel-and-lubricants-report-form__left">
      <base-date-range-picker @updateDateRange="setDateRange($event)" />
      <base-date-picker
        v-model="reportPeriod"
        range
        placeholder="Выберите период"
        @update:modelValue="getReportData"
      />
    </div>
    <button @click.prevent="getExcel(tableRows)"
            :disabled="!reportDataLoaded"
            class="button button--mode-outline">
      <icon-export/>
      <span>Экспорт</span>
    </button>
  </form>
</template>

<script>
import {mapState} from "vuex";
import IconExport from "@/components/Icons/IconExport.vue";
import BaseDateRangePicker from "@/components/BaseDateRangePicker.vue";
import {getExcel} from "@/utils/table";
import moment from "moment";
import BaseDatePicker from "@/components/BaseDatePicker.vue";

export default {
  name: "FuelAndLubricantsReportForm",
  components: {BaseDatePicker, BaseDateRangePicker, IconExport},
  props: {
    tableRows: {
      required: true
    }
  },
  computed: {
    ...mapState({
      reportParams: state => state.reportsData.reportParams,
      reportData: state => state.reportsData.reportData,
      loading: state => state.reportsData.loading
    }),
    reportDataLoaded() {
      return !!this.reportData?.length && !this.loading;
    },
    reportPeriod: {
      get() {
        const {period_start, period_end} = this.reportParams;
        if (period_start && period_end) return [period_start, period_end]
        else return []
      },
      set(value) {
        const [startDate, endDate] = value;
        this.$store.commit('reportsData/SET_REPORT_PARAM', {
          key: 'period_start',
          value: startDate
        });
        this.$store.commit('reportsData/SET_REPORT_PARAM', {
          key: 'period_end',
          value: endDate
        });
      }
    }
  },
  mounted() {
    const now = moment().format('YYYY-MM-DD');
    const reportParams = {period_start: now, period_end: now};
    this.$store.commit('reportsData/SET_REPORT_PARAMS', reportParams);
    this.getReportData();
  },
  methods: {
    getExcel,
    getReportData() {
      this.$store.dispatch('reportsData/getFuelAndLubricantsReportData', this.reportParams);
    },
    setDateRange(range) {
      const reportParams = {period_start: range.start, period_end: range.end};
      this.$store.commit('reportsData/SET_REPORT_PARAMS', reportParams);
      this.getReportData()
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers/index" as *;
.fuel-and-lubricants-report-form {
  display: grid;
  grid-template-columns: 771px 144px;
  gap: 20px;
  width: 100%;
  margin-bottom: 32px;
  justify-content: space-between;

  .base-date-range-picker {
    justify-self: flex-start;
  }

  &__left {
    display: grid;
    grid-template-columns: 1fr 320px;
    gap: 20px;
    justify-content: center;

    @media (max-width: $tablet) {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: $tablet) {
    grid-template-columns: 1fr;
  }
}
</style>
