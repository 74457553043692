<template>
  <div class="base-table">
    <div class="base-table__container">
      <div class="base-table__header base-table__header--appeals">
        <div class="base-table__header-cell">ID</div>
        <div @click="isAscSort = !isAscSort; sortAppeals()"
             class="base-table__header-cell base-table__header-cell-sort"
             :class="{'toggled': !isAscSort}">
          Дата
          <icon-sort/>
        </div>
        <div class="base-table__header-cell">Проблема</div>
        <div class="base-table__header-cell">Адрес</div>
        <div class="base-table__header-cell">Источник</div>
        <div class="base-table__header-cell">Статус</div>
        <div v-if="appealState === 'new'"
             class="base-table__header-cell"/>
        <div v-else
             class="base-table__header-cell">Заявка
        </div>
        <div class="base-table__header-cell"/>
      </div>

      <div v-for="appeal in appealsList"
           :key="appeal.id"
           class="base-table__row base-table__row--appeals"
           @click="openAppealModal(appeal.id)">
        <div class="base-table__column base-table__column--number">
          <div class="base-table__num">{{ appeal.id }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text ">{{ formatDateTime(appeal.created_at) }}</div>
        </div>
        <div class="base-table__column">
          <div
            v-if="appeal.category"
            class="base-table__text base-table__tooltip-wrapper base-table__indicator">
            <span class="base-table__indicator-circle"
                  :style="{'background-color': appeal.category.group_color}"/>
            {{ appeal.category.name }}

            <div class="base-table__tooltip">
              {{appeal.category.group_name}}
            </div>
          </div>
          <div v-else>—</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ appeal.getFullAddress() }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ appeal.getSourceLabel() }}</div>
        </div>
        <div class="base-table__column base-table__column--state">
          <div class="base-table__tag"
               :class="[
                 {'base-table__tag--new': appeal.state === AppealState.NEW},
                 {'base-table__tag--work': appeal.state === AppealState.IN_WORK},
                 {'base-table__tag--done': appeal.state === AppealState.CLOSED},
                 {'base-table__tag--canceled': appeal.state === AppealState.CANCELED}
               ]">
            {{ appeal.getStateLabel() }}
          </div>
        </div>
        <div class="base-table__column">
          <div v-if="appeal.state === AppealState.NEW"
               class="base-table__buttons">
            <div @click.stop="openCreateIssueModal({
                   relatedAppealId: appeal.id
                 })"
                 class="base-table__button base-table__button--dashed">Создать</div>
            <div @click.stop="showLinkAppealModal(appeal.id)"
                 class="base-table__button base-table__button--dashed">Связать</div>
          </div>
          <div v-else
               @click="openIssueModal(appeal.issue_id)"
               class="base-table__join-link">
            <icon-link/>
            <div class="base-table__join-counter">#{{appeal.issue_id}}</div>
          </div>
        </div>
        <div class="base-table__column base-table__column--info">
          <span v-if="appeal.message"
                class="base-table__short-info">
            <icon-comment class="base-table__short-info-icon"/>
            <span class="base-table__short-info-counter">1</span>

            <div class="base-table__short-info-tooltip">
              <div class="base-table__short-info-tooltip-inner">
                {{ appeal.message }}
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import IconSort from "@/components/Icons/IconSort";
import AppealState from "@/models/callCenter/AppealState";
import IconComment from "@/components/Icons/IconComment";
import IconLink from "@/components/Icons/IconLink";
import LinkAppealForm from "@/components/ServiceDesk/Issues/LinkAppealForm";
import {modalAdaptiveWidth} from "@/utils/helpers";
import sideModalControls from "@/mixins/sideModalControls";
import {formatDateTime} from "@/utils/filters";

export default {
  name: "AppealsList",
  components: {IconLink, IconComment, IconSort},
  mixins: [sideModalControls],
  props: {
    appealState: {
      type: String,
      default: 'all'
    },
    appealsList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isAscSort: true
    }
  },
  computed: {
    AppealState: () => AppealState
  },
  methods: {
    formatDateTime,
    sortAppeals() {
      let sortDir;
      if (this.isAscSort) sortDir = 'asc'
      else sortDir = 'desc'

      this.$store.commit('appeals/SET_FILTER_PARAM', {
        key: 'sort_dir',
        value: sortDir
      });

      this.$store.dispatch('appeals/getAppeals', 1);
    },
    showLinkAppealModal(appealId) {
      this.$modal.show(
        LinkAppealForm,
        {
          'appeal-id': appealId
        },
        {
          name: 'link-appeal-modal',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(600),
          scrollable: true
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers/index" as *;

.base-table__header--appeals {
  grid-template-columns: 4.07% 8.13% 12.2% 17.41% 8.81% 8.13% 14.91% 1fr;
}

.base-table__row--appeals {
  grid-template-columns: 4.07% 8.13% 12.2% 17.41% 8.81% 8.13% 14.91% 1fr;
}

@media (max-width: $mobile-big) {
  .base-table__row--appeals {
    grid-template-columns: none;
    gap: 12px;
  }
}
</style>
