<template>
  <div @click="toggleCollapse()"
       class="base-table__row-collapse">
    <div class="base-table__row-collapse-content base-table__row--balance">
      <div class="base-table__column">
        <div class="base-table__collapse">
          <icon-plus2 v-if="isCollapsed"/>
          <icon-minus v-else/>
        </div>
      </div>
      <div class="base-table__column">
        <div class="base-table__text">{{ moment(period.period).format('MMMM') }}</div>
      </div>
      <div class="base-table__column">
        <div class="base-table__text">{{ period.sumSupply() }}</div>
      </div>
      <div class="base-table__column">
        <div class="base-table__text">{{ period.sumConsumption() }}</div>
      </div>
      <div class="base-table__column">
        <div class="base-table__text">{{ period.calculateLossesInPercent() }}</div>
      </div>
      <div class="base-table__column">
        <div class="base-table__text">{{ getLossesInRubles(period.supply, period.calculateLosses()) }}</div>
      </div>
    </div>
    <div v-show="!isCollapsed"
         class="resource-collapse">
      <div class="balance-supply-sources">
        <div class="balance-supply-sources__title">Источники:</div>
        <div
          v-for="device in period.supply"
          :key="device.id"
          class="balance-supply-sources__item">
          <div>{{ getDeviceName(device.device_id) }} -</div>
          <div>{{ device.value.toFixed(0) }} м<sup>3</sup></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconPlus2 from "@/components/Icons/IconPlus2.vue";
import IconMinus from "@/components/Icons/IconMinus.vue";
import moment from "moment";

export default {
  name: "BalanceResultRowItem",
  components: {IconMinus, IconPlus2},
  props: {
    period: {
      type: Object,
      required: true
    },
    getLossesInRubles: {
      type: Function
    },
    getDeviceName: {
      type: Function
    }
  },
  data() {
    return {
      isCollapsed: true
    }
  },
  methods: {
    moment,
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers/index" as *;

.balance-supply-sources {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  margin-bottom: 12px;

  &__title {
    @include font-style(400, 16px, 24px, var(--text-primary));
  }

  &__item {
    display: flex;

    @include font-style(400, 14px, 16px, var(--text-primary));
  }
}

.base-table__row--balance {
  grid-template-columns: 45px 10% 12% 16% 14% 21%;
}
</style>