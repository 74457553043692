<template>
  <div
    @click="isFocused = !isFocused"
    @mouseleave="isFocused = false; isCalcMinMaxActive = false"
    class="equipment-profile-input">
    <input
      class="base-input"
      :value="value"
      @input="$emit('update:modelValue', $event.target.value)"
      @keypress="isNumber($event)"
    >

    <div
      v-if="isBaseProfile && isFocused"
      class="equipment-profile-input__actions">
      <div
        @click.stop="isCalcMinMaxActive = !isCalcMinMaxActive"
        class="equipment-profile-input__calc"
        :class="{'equipment-profile-input__calc--active': isCalcMinMaxActive}"
      >
        <icon-chart/>
      </div>
      <div
        @click="copyValuesForNextLine()"
        class="equipment-profile-input__fill-lines">
        <icon-arrow-down-circle/>
      </div>
    </div>

    <div
      v-if="isCalcMinMaxActive"
      class="equipment-profile-input__percent">
      <input
        v-model="percent"
        class="base-input"
        placeholder="Введите %"
        ref="calcMinMaxInput"
        @keypress="isNumber($event)"
        @keyup.enter="updateMinMax()"
      >

      <div
        @click.stop="updateMinMax()"
        class="equipment-profile-input__percent-submit">
        <icon-tick-square/>
      </div>
    </div>

  </div>
</template>

<script>
import IconChart from "@/components/Icons/IconChart.vue";
import IconArrowDownCircle from "@/components/Icons/IconArrowDownCircle.vue";
import IconTickSquare from "@/components/Icons/IconTickSquare.vue";
import {isNumber} from "@/utils/helpers";

export default {
  name: "EquipmentProfileInput",
  components: {IconTickSquare, IconArrowDownCircle, IconChart},
  props: {
    modelValue: {
      default: null
    },
    isBaseProfile: {
      type: Boolean,
      default: false
    },
    profileHour: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      isFocused: false,
      isCalcMinMaxActive: false,
      percent: null
    }
  },
  watch: {
    isCalcMinMaxActive: function (val) {
      this.$emit('percents-field-active', val, this.profileHour)
      if (val) {
        setTimeout(() => {
          this.$refs.calcMinMaxInput.focus()
        }, 100)
      }
    }
  },
  methods: {
    isNumber,
    updateMinMax() {
      this.$emit("update-min-max", {
        percent: this.percent,
        hour: this.profileHour
      });

      this.isCalcMinMaxActive = false;
      this.percent = null;
    },
    copyValuesForNextLine() {
      this.$emit('copy-values-for-next-line', this.profileHour);
    }
  }
}
</script>

<style lang="scss">
.equipment-profile-input {
  width: 199px;
  padding: 10px 12px;

  display: flex;
  gap: 10px;

  position: relative;

  &__actions {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__calc {
    cursor: pointer;

    &--active {
      & svg path {
        stroke: var(--ico-active);
      }
    }
  }

  &__fill-lines {
    cursor: pointer;
  }

  &__undo {
    cursor: pointer;
  }

  &__percent {
    position: absolute;
    left: 0;
    top: 100%;
    background-color: var(--panel);
    border: 1px solid var(--border-active);
    width: 199px;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    z-index: 1;
    box-shadow: 0px 6px 20px 0px rgba(6, 5, 50, 0.1);

    &-submit {
      cursor: pointer;

      & svg path {
        stroke: var(--ico);
      }
    }

    & input {
      height: 16px;
      background-color: var(--panel);
    }
  }
}
</style>