<template>
  <div class="related-issues">
    <div v-for="issue in relatedIssuesList"
         :key="issue.id"
         class="related-issues__item">
      <div @click="openIssueModal(issue.id)"
           class="related-issues__caption">
        {{issue.getCaption()}}
      </div>
    </div>
  </div>
</template>

<script>
import sideModalControls from "@/mixins/sideModalControls";

export default {
  name: "RelatedIssuesList",
  mixins: [sideModalControls],
  props: {
    relatedIssuesList: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers/index" as *;
.related-issues {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__item:last-child {
    margin-bottom: 16px;
  }

  &__caption {
    cursor: pointer;
    @include font-style(400, 16px, 24px, var(--text-link))
  }
}
</style>
