<template>
  <div class="page-wrapper">
    <base-panel
      title="Оперативная схема"
      :is-add-button-visible="false"
      :loading="loading"/>

    <operational-scheme-map :bus="bus" />
  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel";
import OperationalSchemeMap from "@/components/ServiceDesk/OperationalScheme/OperationalSchemeMap";
import {mapState} from "vuex";
import IssueState from "@/models/serviceDesk/IssueState";
import Vue from 'vue';
import moment from "moment";

export default {
  name: "OperationalSchemePage",
  components: {OperationalSchemeMap, BasePanel},
  data() {
    return {
      dataTiming: null,
      bus: new Vue()
    }
  },
  computed: {
    ...mapState({
      loading: state => state.networkElements.loading,
      issueModalState: state => state.operationalSchemeData.isIssueModalActive
    }),
    isIssueModalActive: {
      get() {
        return this.issueModalState
      },
      set(value) {
        this.$store.commit('operationalSchemeData/SET_ISSUE_MODAL_STATE', value);
      }
    }
  },
  mounted() {
    this.$store.dispatch('baseMap/getLayers');
    this.$store.dispatch("autos/getAllAutos");

    this.fetchData();
    this.dataTiming = setInterval(this.fetchData, process.env.VUE_APP_OPERATION_DATA_TIMING);
  },
  beforeUnmount() {
    clearInterval(this.dataTiming);
  },
  methods: {
    fetchData() {
      this.$store.dispatch('operationalSchemeData/getOperationalData');
      this.$store.dispatch('transportData/transportData');
      this.$store.dispatch('issues/getAllIssues', {
        issue_states: [
          IssueState.NEW,
          IssueState.IN_WORK
        ].join()
      });
      this.$store.dispatch('resources/getAvailableResourcesList', {
        check_date: moment().format('YYYY-MM-DD')
      })

      this.bus.$emit('update-layers');
    }
  }
}
</script>

<style scoped>

</style>
