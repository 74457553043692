<template>
  <div class="base-calendar">
    <full-calendar-component
      ref="calendar"
      :options="calendarOptions"
    >
      <template v-slot:eventContent="arg">
        <slot name="eventItem"
              :eventData="arg.event"/>
      </template>
    </full-calendar-component>
  </div>
</template>

<script>
import FullCalendarComponent from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import ruLocale from '@fullcalendar/core/locales/ru'
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import moment from "moment";

export default {
  name: "BaseCalendar",
  components: {FullCalendarComponent},
  props: {
    events: {
      type: Array,
      required: true
    },
    initialView: {
      type: String,
      default: 'dayGridMonth'
    },
    showEventTooltip: {
      type: Boolean,
      default: true
    },
    contentHeight: {
      type: String,
      default: 'auto'
    }
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: this.initialView,
        locale: ruLocale,
        timeZone: 'UTC',
        displayEventTime: false,
        events: null,
        contentHeight: this.contentHeight,
        datesSet: this.getCurrentCalendarDates,
        dateClick: this.handleDateClick,
        eventDidMount: info => {
          if (this.showEventTooltip) {
            tippy(info.el, {
              content: info.event.title
            })
          }
        }
      }
    }
  },
  watch: {
    events: function (events) {
      this.setEvents(events);
    }
  },
  methods: {
    setEvents(events) {
      this.calendarOptions.events = events;
    },
    handleDateClick(arg) {
      this.$emit('date-click', arg.date);
    },
    getCurrentCalendarDates(dateInfo) {
      const calendarRange = {
        period_start: moment(dateInfo.start).format('YYYY-MM-DD'),
        period_end: moment(dateInfo.end).format('YYYY-MM-DD')
      };

      this.$store.commit("workShift/SET_FILTER_PARAMS", calendarRange);
      this.$emit('update-calendar-range', calendarRange);
    }
  }
}
</script>

<style scoped>

</style>