<template>
  <div
    class="transport-map-icon"
    :class="iconClass">
    <icon-car v-if="vehicle.isPassengerVehicle()"/>
    <icon-special-equipment v-else-if="vehicle.isSpecialVehicle()"/>
    <icon-truck v-else-if="vehicle.isTruck()"/>
    <icon-water-carrier v-else-if="vehicle.isWaterCarrier()"/>

    <div class="transport-map-icon__label"
         :class="{'transport-map-icon__label--visible': isMarkersLabelVisible}">
      <div class="transport-map-icon__label-name">
        <span @click="openTransportModal(vehicle.id)">{{ vehicle.attributes.label }}</span>
        <span>{{ vehicle.attributes.gov_number }}</span>
      </div>
      <div class="transport-map-icon__label-speed">
        <transport-speed
          :transport-speed="vehicleSpeed"
          :id="vehicle.id"
          :white-speed="true"/>
      </div>
    </div>
  </div>
</template>

<script>
import Vehicle from "@/models/transport/Vehicle";
import IconCar from "@/components/Icons/IconCar.vue";
import IconSpecialEquipment from "@/components/Icons/IconSpecialEquipment.vue";
import IconWaterCarrier from "@/components/Icons/IconWaterCarrier.vue";
import IconTruck from "@/components/Icons/IconTruck2.vue";
import store from "@/store";
import TransportSpeed from "@/components/Transport/TransportSpeed.vue";

export default {
  name: "TransportMapIcon",
  components: {TransportSpeed, IconTruck, IconWaterCarrier, IconSpecialEquipment, IconCar},
  props: {
    vehicle: {
      type: Vehicle,
      default: () => {
      }
    }
  },
  computed: {
    iconClass() {
      const speedStatus = store.getters['transportData/getVehicleSpeedStatus'](this.vehicle.id)
      if (speedStatus === 'success') return 'transport-map-icon--success'
      else if (speedStatus === 'warning') return 'transport-map-icon--warning'
      else if (speedStatus === 'danger') return 'transport-map-icon--danger'
      else return 'transport-map-icon--normal'
    },
    isMarkersLabelVisible() {
      return store.state.autos.isMarkersLabelVisible
    },
    vehicleSpeed() {
      return store.getters["transportData/getSpeed"](this.vehicle.id);
    }
  },
  methods: {
    openTransportModal(vehicleId) {
      store.dispatch('baseSideModal/openModal', {
        modalContentType: 'transport',
        entityId: vehicleId
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers/index" as *;

.transport-map-icon {
  position: relative;
  width: 36px;
  height: 36px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background-color: var(--panel);

  outline: 1px solid var(--panel);
  border: 2px solid var(--text-link);

  &--success {
    border-color: var(--status-done);
  }

  &--warning {
    border-color: var(--status-delay);
  }

  &--danger {
    border-color: var(--border-validation);
  }

  &--normal {
    border-color: var(--border-grey);
  }

  & svg {
    width: 20px;
    height: 20px;
  }

  &__label {
    position: absolute;
    left: 32px;
    top: -16px;

    display: none;
    flex-direction: column;
    gap: 4px;

    padding: 4px;
    border-radius: 4px;
    border: 1px solid var(--border-dark-grey);
    background: var(--bg-sidebar);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.20);

    &--visible {
      display: flex;
    }
  }

  &__label-name {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 20px;

    & span:first-child {
      cursor: pointer;
      text-wrap: nowrap;
      @include font-style(400, 10px, 12px, var(--text-panel));
    }

    & span:last-child {
      text-wrap: nowrap;
      @include font-style(600, 10px, 12px, var(--text-tertiary));
    }
  }

  &__label-speed {
    display: none;
  }

  &:hover .transport-map-icon__label-speed {
    display: block;
  }
}
</style>
