<template>
  <div class="i-modal__content-wrapper">
    <div class="i-modal__content">
      <div v-if="Object.keys(department).length && !loading"
           class="i-modal__inner">
        <div class="base-modal__left-side base-modal__left-side--hidden">
          <div class="base-modal-header">
            <span class="base-modal-title">{{department.name}}</span>
            <div class="base-modal-header__status base-modal-header__status--select">
              <v-select
                v-model="departmentStatus"
                :options="['Активно', 'Не активно']"
                @update:modelValue="changeDepartmentStatus"
                class="vue-select-input vue-select-input--hide-x"
                placeholder="Выберите из списка"
              />
            </div>
          </div>
          <div class="base-modal-info">
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <span>Наименование</span>
              </div>
              <div class="base-modal-info__value">
                <p class="base-modal-info__value-txt">{{ department.name }}</p>
              </div>
            </div>

          </div>
        </div>
      </div>
      <base-side-modal-skeleton v-if="loading" />
    </div>
    <div v-if="Object.keys(department).length"
         class="i-modal-btn-wp i-modal-btn-wp--back">
      <div class="task-modal__mobile-actions">
        <button @click="$store.dispatch('baseSideModal/closeModal')"
                class="button button--mode-outline task-modal__mobile-back">
          <icon-arrow-left/>
        </button>
      </div>

    </div>
  </div>
</template>

<script>
import BaseSideModalSkeleton from "@/components/BaseSideModalSkeleton.vue";
import IconArrowLeft from "@/components/Icons/IconArrowLeft.vue";
import {mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";

export default {
  name: "DepartmentContentModal",
  components: {
    IconArrowLeft,
    BaseSideModalSkeleton
  },
  data() {
    return {
      departmentStatus: 0
    }
  },
  computed: {
    ...mapState({
      department: state => state.departments.department,
      loading: state => state.departments.departmentInfoLoading
    })
  },
  watch: {
    department: function () {
      this.departmentStatus = this.department.getActivityLabel();
    }
  },
  methods: {
    changeDepartmentStatus() {
      if (this.departmentStatus === 'Активно') this.activateDepartment()
      else this.deactivateDepartment();
    },
    activateDepartment() {
      this.$store.dispatch('departments/activateDepartment', this.department.id)
          .then(() => {
            showToast('Подразделение переведено в статус "Активно"', 'success');
            this.$store.commit('departments/TOGGLE_DEPARTMENT_STATUS', this.department.id);
            this.departmentStatus = 'Активно';
          })
          .catch(error => showAlert('Ошибка', error, 'error'));
    },
    deactivateDepartment() {
      this.$store.dispatch('departments/deactivateDepartment', this.department.id)
          .then(() => {
            showToast('Подразделение переведено в статус "Не активно"', 'success');
            this.$store.commit('departments/TOGGLE_DEPARTMENT_STATUS', this.department.id);
            this.departmentStatus = 'Не активно';
          })
          .catch(error => showAlert('Ошибка', error, 'error'));
    }
  }
}
</script>

<style scoped>

</style>
