import * as API from './API';
import TechnicalInspectionTask from "@/models/TechnicalInspection/TechnicalInspectionTask";
import {getError} from "@/utils/helpers";
import TechnicalInspectionTaskHistory from "@/models/TechnicalInspection/TechnicalInspectionTaskHistory";
import QuestionAnswer from "@/models/TechnicalInspection/QuestionAnswer";
import TaskViolation from "@/models/TechnicalInspection/TaskViolation";

export default {
    fetchTasksList(filterParams) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/technical-inspection/tasks`, {params: filterParams})
                .then(response => resolve(response.data.data
                    .map(item => new TechnicalInspectionTask({id: item.id, ...item.attributes}))))
                .catch(error => reject(getError(error)))
        })
    },
    fetchTask(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/technical-inspection/tasks/${id}`)
                .then(response => resolve(new TechnicalInspectionTask(
                    {id: response.data.data.id, ...response.data.data.attributes})))
                .catch(error => reject(getError(error)))
        })
    },
    createTask(payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`/technical-inspection/tasks`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    returnToWorkTask(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/technical-inspection/tasks/${id}/return-to-work`)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    finishTask(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/technical-inspection/tasks/${id}/finish`)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    cancelTask(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/technical-inspection/tasks/${id}/cancel`)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    fetchTaskHistory(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/technical-inspection/tasks/${id}/history`)
                .then(response => resolve(response.data.data
                    .map(item => new TechnicalInspectionTaskHistory({id: item.id, ...item.attributes}))))
                .catch(error => reject(getError(error)))
        })
    },
    fetchTaskAnswers(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/technical-inspection/tasks/${id}/answers`)
                .then(response => resolve(response.data.data
                    .map(item => new QuestionAnswer({id: item.id, ...item.attributes}))))
                .catch(error => reject(getError(error)))
        })
    },
    fetchTaskViolationsList(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/technical-inspection/tasks/${id}/violations`)
                .then(response => resolve(response.data.data
                    .map(item => new TaskViolation({id: item.id, ...item.attributes}))))
                .catch(error => reject(getError(error)))
        })
    }
}
