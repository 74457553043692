<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      Обнаруженная проблема {{detectedProblem ? detectedProblem.id : ''}}
      <span @click="$modal.hide('detected-problems-form')"
            class="base-popup__close">
        <icon-close/>
      </span>
    </div>
    <div class="popup-form-group">
      <problem-category-selector
        :category_id="payload.category_id"
        :group="group_id"
        @update:category_id="categoryId => payload.category_id = categoryId"
        @update:group_id="groupId => group_id = groupId"/>

      <div class="form-item">
        <div class="form-item__name">Адрес</div>
        <div class="form-item__value">
          <address-selector
            :address-string="addressString"
            @update:address-string="value => this.addressString = value"
            @set-address="setAddress"
          />
        </div>

        <manual-address-form
          :is-form-name-visible="false"
          :address_flat="payload.address_flat"
          :address_block="payload.address_block"
          :address_house="payload.address_house"
          :address_street="payload.address_street"
          :address_settlement="payload.address_settlement"
          :address_city="payload.address_city"
          :address_region="payload.address_region"
          @update:address_flat="value => payload.address_flat = value"
          @update:address_block="value => payload.address_block = value"
          @update:address_house="value => payload.address_house = value"
          @update:address_street="value => payload.address_street = value"
          @update:address_settlement="value => payload.address_settlement = value"
          @update:address_city="value => payload.address_city = value"
          @update:address_region="value => payload.address_region = value"
        />
      </div>


      <defected-problem-map
        @update:coords="({lat, lng}) => {
          payload.address_lat = lat;
          payload.address_lon = lng;
        }"/>

      <div class="form-item">
        <div class="form-item__name">Комментарий</div>
        <div class="form-item__value">
          <textarea
            type="text"
            v-model="payload.message"
            class="base-textarea"
            placeholder="Введите комментарий"
          />
        </div>
      </div>

      <div class="form-item--border-top">
        <detected-problem-attachments
          :detected-problem-attachments="detectedProblemAttachments"
          @delete:detected-problem-attachment="deleteDetectedProblemAttachment"
          @update:attachments-list="files => this.uploadFilesList = files"/>
      </div>

    </div>
    <div class="base-popup__footer base-popup__footer--flex">
      <div
        @click="submitForm()"
        class="button">
        Сохранить
      </div>
      <div
        @click="$modal.hide('detected-problems-form')"
        class="button button--mode-outline">
        Отменить
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from "@/components/Icons/IconClose.vue";
import ProblemCategorySelector from "@/components/ServiceDesk/Issues/ProblemCategorySelector.vue";
import AddressSelector from "@/components/ServiceDesk/CommonComponents/AddressSelector/AddressSelector.vue";
import DefectedProblemMap from "@/components/ServiceDesk/Tasks/TaskComponents/DetectedProblems/DetectedProblemMap.vue"
import DetectedProblemAttachments
  from "@/components/ServiceDesk/Tasks/TaskComponents/DetectedProblems/DetectedProblemAttachments.vue";
import {showToast, showAlert, showConfirmationModal} from "@/utils/notification";
import ManualAddressForm from "@/components/ServiceDesk/ManualAddressForm.vue";
import emitter from "@/utils/emitter";

export default {
  name: "DetectedProblemsForm",
  components: {
    ManualAddressForm,
    DetectedProblemAttachments,
    DefectedProblemMap,
    AddressSelector,
    ProblemCategorySelector,
    IconClose
},
  props: {
    taskId: {
      type: Number
    },
    detectedProblem: {
      default: null
    }
  },
  data() {
    return {
      payload: {
        category_id: null,
        message: null,
        address_lat: null,
        address_lon: null,
        address_region: null,
        address_city: null,
        address_settlement: null,
        address_street: null,
        address_house: null,
        address_block: null,
        address_flat: null
      },
      detectedProblemAttachments: [],
      uploadFilesList: [],
      addressString: '',
      group_id: null
    }
  },
  mounted() {
    if (this.detectedProblem) {
      const {category, message, address, attachments} = this.detectedProblem;
      this.group_id = category.group_id;
      this.payload.category_id = category.id;
      this.payload.message = message;
      const [lat, lon] = address.geometry.coordinates;
      this.payload.address_lat = lat;
      this.payload.address_lon = lon;
      this.payload.address_region = address.region;
      this.payload.address_city = address.city;
      this.payload.address_settlement = address.settlement;
      this.payload.address_street = address.street;
      this.payload.address_house = address.house;
      this.payload.address_block = address.block;
      this.payload.address_flat = address.flat;
      this.addressString = address.fullAddress;
      this.detectedProblemAttachments = attachments.concat();

      emitter.$emit('fit-bounds-map', {
        coords: [lat, lon],
        qc_geo: 1
      })
    }
  },
  methods: {
    submitForm() {
      if (this.detectedProblem) this.updateDetectedProblem()
      else this.createDetectedProblem()
    },
    createDetectedProblem() {
      this.$store.dispatch('detectedProblems/createDetectedProblem', {
        taskId: this.taskId,
        payload: this.payload
      })
        .then(detectedProblemId => {
          if (this.uploadFilesList.length) {
            this.addDetectedProblemAttachments(detectedProblemId);
          }
          showToast('Обнаруженная проблема добавлена', 'success');
          this.$store.dispatch('taskModal/getDetectedProblemsList', this.taskId);
          this.$modal.hide('detected-problems-form');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    updateDetectedProblem() {
      this.$store.dispatch('detectedProblems/updateDetectedProblem', {
        taskId: this.taskId,
        detectedProblemId: this.detectedProblem.id,
        payload: this.payload
      })
        .then(() => {
          if (this.uploadFilesList.length) {
            this.addDetectedProblemAttachments(this.detectedProblem.id);
          }
          showToast('Обнаруженная проблема обновлена', 'success');
          this.$store.dispatch('taskModal/getDetectedProblemsList', this.taskId);
          this.$modal.hide('detected-problems-form');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    addDetectedProblemAttachments(detectedProblemId) {
      let formData = new FormData();
      for (let x = 0; x < this.uploadFilesList.length; x++) {
        formData.append(`attachments[${x}][file]`, this.uploadFilesList[x]);
        formData.append(`attachments[${x}][description]`, this.uploadFilesList[x].name);
      }

      this.$store.dispatch('detectedProblems/addDetectedProblemAttachments', {
        taskId: this.taskId,
        detectedProblemId,
        payload: formData
      })
        .then(() => {
          this.uploadFilesList = [];
          this.$store.dispatch('taskModal/getDetectedProblemsList', this.taskId);
        })
        .catch(error => showAlert('Ошибка', error))
    },
    setAddress({region, city, settlement, street, house, block, flat, lat, lon, qc_geo}) {
      this.payload.address_lat = lat;
      this.payload.address_lon = lon;
      this.payload.address_region = region;
      this.payload.address_city = city;
      this.payload.address_settlement = settlement;
      this.payload.address_street = street;
      this.payload.address_house = house;
      this.payload.address_block = block;
      this.payload.address_flat = flat;

      emitter.$emit('fit-bounds-map', {
        coords: [this.payload.address_lat, this.payload.address_lon],
        qc_geo: qc_geo
      })
    },
    deleteDetectedProblemAttachment(attachmentId) {
      showConfirmationModal('Вы уверены, что хотите удалить прикреплённый файл?')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('detectedProblems/removeDetectedProblemAttachment', {
              taskId: this.taskId,
              detectedProblemId: this.detectedProblem.id,
              attachmentId
            })
              .then(() => {
                this.$store.dispatch('taskModal/getDetectedProblemsList', this.taskId);
                const index = this.detectedProblemAttachments.findIndex(attachment => attachment.id === attachmentId);
                this.detectedProblemAttachments.splice(index, 1);
                showToast('Файл удалён', 'success');
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    }
  }
}
</script>

<style scoped>
.base-popup__wrapper {
  padding: 12px;
}
</style>
