<template>
  <div class="cascade-select">
    <div
      class="cascade-select__input-wrapper"
      :class="{'cascade-select__input-wrapper--active': isOptionsVisible}"
      @click="openInputOptions()"
    >
      <div class="cascade-select__input-inner">
        <input
          id="cascade-select-input"
          class="cascade-select__input"
          :class="{'cascade-select__input--placeholder-hidden': modelValue}"
          v-model="searchQuery"
          :placeholder="placeholder"
          autocomplete="off"
        >

        <span
          class="cascade-select__selected-value"
          :class="[
            {'cascade-select__selected-value--focus': isOptionsVisible},
            {'cascade-select__selected-value--hidden': searchQuery.length}
          ]"
        >
          {{optionLabel}}
        </span>
      </div>

      <div class="cascade-select__actions">
        <div
          class="cascade-select__actions__close"
          v-if="modelValue"
          @click.stop="updateValue(null)"><icon-close/></div>
        <div
          class="cascade-select__actions-arrow"
          :class="{'rotateZ-180deg': isOptionsVisible}">
          <icon-arrow-down/>
        </div>

      </div>
    </div>

    <div
      class="cascade-select__groups-list"
      :class="{'cascade-select__groups-list--visible': isOptionsVisible}"
    >
      <div
        v-for="(optionsGroup, index) in filteredOptionsGroups"
        :key="`group_${index}`"
        @click="toggleGroup(optionsGroup.id)"
        class="cascade-select__group"
        :class="{'cascade-select__group--hidden': isGroupSelected(optionsGroup.id)}"
      >
        <div
          class="cascade-select__group-caption"
          :class="{'cascade-select__group-caption--selected': selectedGroup === optionsGroup.id}">
          <div class="cascade-select__group-title">{{ optionsGroup.name }}</div>
          <div class="cascade-select__group-toggle">
            <icon-arrow-right/>
          </div>
        </div>
        <div
          class="cascade-select__group-options"
          :class="{'cascade-select__group-options--active': selectedGroup === optionsGroup.id}"
        >
          <div
            v-for="(option, index) in optionsGroup.options"
            :key="`option_${index}`"
            class="cascade-select__option"
            :class="{'cascade-select__option--selected': modelValue === option.id}"
            @click.stop="updateValue(option.id, true)"
          >
            {{ option.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrowRight from "@/components/Icons/IconArrowRight.vue";
import IconClose from "@/components/Icons/IconClose.vue";
import IconArrowDown from "@/components/Icons/IconArrowDown.vue";

export default {
  name: "BaseCascadeSelect",
  components: {IconArrowDown, IconClose, IconArrowRight},
  props: {
    optionsGroups: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      default: 'Выберите из списка'
    },
    modelValue: {
      default: null
    }
  },
  data() {
    return {
      isOptionsVisible: false,
      selectedGroup: null,
      searchQuery: ""
    }
  },
  computed: {
    optionLabel() {
      if (this.selectedGroup && this.modelValue) {
        const group = this.optionsGroups.find(group => group.id === this.selectedGroup);
        const option = group.options.find(option => option.id === this.modelValue);
        return option ? option.name : 'Имя не найдено';
      }
      return ''
    },
    filteredOptionsGroups() {
      return this.searchQuery.length ? this.optionsGroups
        .map(group => {
          const filteredOptions = group.options.filter(option => option.name.toLowerCase()
            .includes(this.searchQuery.toLowerCase()))
          return {
            ...group,
            options: filteredOptions
          }
        })
        .filter(group => group.options.length) : this.optionsGroups;
    }
  },
  watch: {
    isOptionsVisible: function (value) {
      if (!value) {
        if (this.selectedGroup && !this.value) {
          this.selectedGroup = null;
          this.searchQuery = "";
        }
      }
    },
    selectedGroup: function (value) {
      if (!value) this.updateValue(null);
    },
    searchQuery: function (query) {
      if (!query.length && !this.modelValue) this.selectedGroup = null;
    },
    filteredOptionsGroups: function (value) {
      if (value.length === 1) {
        const [group] = value;
        this.selectedGroup = group.id
      }
    }
  },
  mounted() {
    document.addEventListener('click', e => {
      if (!e.target.closest(`.cascade-select__groups-list`) &&
        !e.target.closest(`.cascade-select__actions`) &&
        !e.target.closest(`.cascade-select__selected-value`) &&
        !e.target.closest('.cascade-select__input')) {
        this.isOptionsVisible = false;
      }
    })
  },
  methods: {
    updateValue(optionId, closeOptions = false) {
      if (closeOptions) this.isOptionsVisible = false;
      this.searchQuery = "";
      this.$emit('update:modelValue', optionId);
    },
    openInputOptions() {
      this.isOptionsVisible = true;
    },
    toggleGroup(groupId) {
      this.selectedGroup = this.selectedGroup === null ? groupId : null;
    },
    isGroupSelected(groupId) {
      if (this.selectedGroup) {
        return this.selectedGroup !== groupId;
      } else return false
    }
  }
}
</script>

<style scoped>
</style>