<template>
  <div class="network-object-readings__archive">
    <div class="network-object-readings__title">
      Архив показателей

      <div class="network-object-readings__archive-form">
        <base-date-switcher
          :days-past-limit="7"
          @date-input="changeArchiveDate"
        />

        <v-select
          v-model="networkEquipmentId"
          :options="equipmentsList"
          :reduce="equipment => equipment.id"
          :get-option-label="(option) => option.name"
          class="vue-select-input"
          placeholder="Выберите из списка"
          @update:modelValue="changeArchiveEquipment"
        />
      </div>
    </div>

    <div class="network-object-readings__archive-result">
      <div v-if="parametersValues.length && !loading"
           class="base-chart"
           id="apexcharts-line"
      >
        <div class="base-chart__header">
          <div class="tags-select">
            <div v-for="chartType in createParametersChartData()"
                 :key="chartType.id"
                 @click="currentChartType = (chartType.parameterName)"
                 class="tags-select__item"
                 :class="{
                   'selected': currentChartType === chartType.parameterName,
                 }">
              {{ chartType.parameterName }}
            </div>
          </div>
        </div>
        <apexchart
          v-for="chartItem in createParametersChartData().filter((chart)=> {
            return chart.parameterName === currentChartType
          })"
          :key="chartItem.id"
          :options="chartItem.options"
          :series="chartItem.series"
          height="400"
          type="line"
          width="100%"
        />
      </div>

      <base-no-data v-if="!parametersValues.length && !loading" />

      <base-content-loaders v-if="loading"/>
    </div>
  </div>
</template>

<script>
import BaseDateSwitcher from "@/components/BaseDateSwitcher.vue";
import {mapGetters, mapState} from "vuex";
import VueApexCharts from "vue3-apexcharts";
import BaseNoData from "@/components/BaseNoData.vue";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import moment from "moment";

export default {
  name: "NetworkObjectModalArchiveReadings",
  components: {BaseContentLoaders, BaseNoData, BaseDateSwitcher, 'apexchart': VueApexCharts},
  props: {
    equipmentsList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      date: moment().format("YYYY-MM-DD"),
      networkEquipmentId: null,
      currentChartType: null
    }
  },
  computed: {
    ...mapState({
      parametersValues: state => state.archivePage.parametersValues,
      loading: state => state.archivePage.loading
    }),
    ...mapGetters({
      networkEquipmentParameters: 'networkEquipments/getNetworkEquipmentParameters'
    })
  },
  mounted() {
    if (this.equipmentsList.length) this.networkEquipmentId = this.equipmentsList[0].id;
    this.getEquipmentMomentaryArchive();
  },
  methods: {
    processDate(date) {
      const start_period = new Date(date);
      start_period.setHours(0, 0, 0, 0);

      const end_period = new Date(date);
      end_period.setHours(23, 59, 0, 0);

      return {start_period, end_period};
    },
    changeArchiveDate(date) {
      this.date = date;
      this.getEquipmentMomentaryArchive();
    },
    changeArchiveEquipment() {
      this.getEquipmentMomentaryArchive();
    },
    getEquipmentMomentaryArchive() {
      const parameters = this.networkEquipmentParameters(this.networkEquipmentId);
      if (parameters.length) this.currentChartType = parameters[0].name;

      this.$store.dispatch('archivePage/getNetworkEquipmentArchive', {
        networkEquipmentId: this.networkEquipmentId,
        params: this.processDate(this.date),
        routeGroup: 'momentary'
      })
    },
    createParametersChartData: function () {
      return this.networkEquipmentParameters(this.networkEquipmentId)
        .map(parameter => {
          return {
            id: parameter.id,
            parameterName: parameter.name,
            options: {
              chart: {
                type: 'area',
                height: 400,
                zoom: {
                  enabled: false
                },
                fontFamily: 'Lato',
                foreColor: '#9192AD'
              },
              legend: {
                fontSize: '12px'
              },
              colors: ['#6375D4'],
              dataLabels: {
                enabled: false
              },
              grid: {
                borderColor: 'rgba(0, 0, 0, 0.20)',
                strokeDashArray: 2,
                position: 'back'
              },
              stroke: {
                curve: 'straight'
              },
              xaxis: {
                categories: this.parametersValues
                  .filter(item => item.parameterId === parameter.id)
                  .map(item => item.label),
                labels: {
                  show: true,
                  rotateAlways: false
                }
              },
              fill: {
                type: "gradient",
                gradient: {
                  shade: "light",
                  type: "vertical",
                  shadeIntensity: 1,
                  opacityFrom: 0.9,
                  opacityTo: 0.2,
                  stops: [0, 90, 100]
                }
              }
            },
            series: [
              {
                name: parameter.name,
                type: 'area',
                data: this.parametersValues
                  .filter(item => item.parameterId === parameter.id)
                  .map(item => item.value.toFixed(2)),
                fill: {
                  type: 'pattern',
                  pattern: {
                    style: 'verticalLines',
                    width: 6,
                    height: 6,
                    strokeWidth: 2
                  }
                }
              }
            ]
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
