<template>
  <div class="i-modal__content-wrapper">
    <div class="i-modal__content">
      <div v-if="Object.keys(task).length && !taskLoading"
           class="i-modal__inner">
        <div :class="{'base-modal__left-side--hidden': isHistoryVisible}"
             class="base-modal__left-side">
          <div class="base-modal-header">
            <span class="base-modal-title">Задание #{{ task.id }}</span>
            <div class="base-modal-header__status">
              <div class="application-status-wrapper">
                <div class="application-status"
                     :class="[
                       {'base-table__tag--new': task.state.id === TaskState.NEW},
                       {'base-table__tag--work': task.state.id === TaskState.IN_WORK},
                       {'base-table__tag--done': task.state.id === TaskState.CLOSED},
                       {'base-table__tag--canceled': task.state.id === TaskState.CANCELED},
                       {'base-table__tag--suspended': task.state.id === TaskState.SUSPENDED},
                       {'base-table__tag--planned': task.state.id === TaskState.ON_THE_WAY},
                       {'base-table__tag--review': task.state.id === TaskState.REVIEW}
                     ]">
                  {{ task.state.label }}
                </div>
              </div>
              <div @click="$store.commit('baseSideModal/TOGGLE_MODAL_HORIZONTAL')"
                   class="base-modal-header__history-toggle"
                   :class="{'active': isHistoryVisible}">
                <icon-history-toggle/>
              </div>
            </div>
          </div>
          <div class="base-modal-info">
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-clipboard/>
                <span>Что нужно сделать</span>
              </div>
              <div class="base-modal-info__value">
                <p class="base-modal-info__value-txt">{{ task.type_label }}</p>
              </div>
            </div>
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-tag-cross/>
                <span>Подразделение</span>
              </div>
              <div class="base-modal-info__value">
                <p class="base-modal-info__value-txt">{{ task.department? task.department.name : '-' }}</p>
              </div>
            </div>
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-link/>
                <span>Взаимосвязаны</span>
              </div>
              <div class="base-modal-info__value">
                <span
                  @click="openIssueModal(task.issue.id)"
                  class="base-modal-info__value-txt base-modal-info__value--connection">
                  #{{ task.issue.id }}, {{ task.issue.getFullAddress() }}
                </span>
              </div>
            </div>
            <div
              v-if="isPlanningVisible()"
              class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-timer/>
                <span>Сроки выполнения</span>
              </div>
              <div class="base-modal-info__value">
                <p
                  v-if="task.planned_start_date"
                  @click="updateResource()"
                  class="base-modal-info__value-date"
                  :class="{'base-modal-info__value-date--pointer': task.state.isNew}">
                  {{ task.plannedDatesRangeCaption }}
                  <icon-calendar/>
                </p>
                <p v-else
                   @click="showAssignResourceModal()"
                   class="base-modal-info__value-date base-modal-info__value-date--pointer">
                  Назначить
                  <icon-calendar/>
                </p>
              </div>
            </div>
            <div
              v-if="isPlanningVisible()"
              class="base-modal-info__item base-modal-info__item--border">
              <div class="base-modal-info__name">
                <icon-profile-circle/>
                <span>Исполнитель/Ресурс</span>
              </div>
              <div class="base-modal-info__value">
                <div v-if="task.brigade"
                     @click="updateResource()"
                     type="button">{{ task.brigade.name }}
                </div>
                <div v-else
                     type="button"
                     @click="showAssignResourceModal()">Назначить
                </div>
              </div>
            </div>

            <div v-if="task.typeFeatures.some(item => item.slug === 'works')"
                 class="base-modal-info__item base-modal-info__item--options">
              <works-list
                :task-id="task.id"
                :is-actions-visible="task.state.isInWork"
                :works-list="worksList"
                :work-type-lists="works(task.issue.category.group.id)"
              />
            </div>
            <div v-if="task.typeFeatures.some(item => item.slug === 'equipments')"
                 class="base-modal-info__item base-modal-info__item--options">
              <mechanical-equipments-list
                :task-id="task.id"
                :mechanical-equipments-list="mechanicalEquipmentList"
                :mechanical-equipments-types="mechanicalEquipmentsTypes"
                :is-actions-visible="task.state.isInWork"/>
            </div>
            <div v-if="task.typeFeatures.some(item => item.slug === 'transport_order')"
                 class="base-modal-info__item base-modal-info__item--options">
              <transport-orders-list
                :task-id="task.id"
                :transport-orders="transportOrders"
                :is-actions-visible="task.state.isNew"/>
            </div>
            <div v-if="task.typeFeatures.some(item => item.slug === 'water_flows')"
                 class="base-modal-info__item base-modal-info__item--options">
              <water-flows-list
                :task-id="task.id"
                :water-flows="waterFlows"
                :is-actions-visible="task.state.isInWork"/>
            </div>
            <div v-if="task.typeFeatures.some(item => item.slug === 'network_spread')"
                 class="base-modal-info__item base-modal-info__item--options">
              <network-spread-item
                :task-id="task.id"
                :network-spread="networkSpread"
                :is-actions-visible="task.state.isInWork"/>
            </div>
            <div v-if="task.typeFeatures.some(item => item.slug === 'materials')"
                 class="base-modal-info__item base-modal-info__item--options">
              <consumptions-list
                :task-id="task.id"
                :consumptions-list="consumptionsList"
                :is-actions-visible="task.state.isInWork"/>
            </div>
            <div v-if="task.typeFeatures.some(item => item.slug === 'detected_problems')"
                 class="base-modal-info__item base-modal-info__item--options">
              <detected-problems-list
                :task-id="task.id"
                :detected-problems-list="detectedProblemsList"
                :is-actions-visible="task.state.isInWork"/>
            </div>

            <div class="task-modal__base-action">
              <task-permit
                v-if="task.checkSafety"
                :permit="task.permit"
                :task-id="task.id"
                :is-task-review="task.state.isReview"
              />

              <task-files-list
                v-if="task.typeFeatures.some(item => item.slug === 'attachments')"
                :task-id="task.id"
                :files-list="taskAttachments"
                :is-actions-visible="task.state.isInWork"
              />

              <comments-list
                :task-id="task.id"
                :comments-list="commentsList"
              />
            </div>

            <div class="base-modal-info__item base-modal-info__item--last">
              <div class="base-modal-info__name">
                <icon-clock/>
                <span>Создал</span>
              </div>
              <div class="base-modal-info__value">
                <p v-if="firstHistoryItem"
                   class="base-modal-info__value-txt">
                  {{ firstHistoryItem.userName }}
                  <span class="base-modal-additional">
                    {{ formatDateTime(firstHistoryItem.historyDate) }}
                  </span>
                </p>
              </div>
            </div>

            <div v-if="task.state.id !== TaskState.CLOSED"
                 class="base-modal-btn-wp">
              <task-state-actions
                :task-state-id="task.state.id"
                :task-id="task.id"
                :check-safety="task.checkSafety"
              />
            </div>

          </div>

        </div>
        <div v-if="isHistoryVisible"
             :class="{'base-modal__right-side--visible': isHistoryVisible}"
             class="base-modal__right-side">
          <history-list :historyList="taskHistory"/>
        </div>
      </div>
      <base-side-modal-skeleton v-if="taskLoading"/>
    </div>

    <div v-if="Object.keys(task).length"
         class="i-modal-btn-wp i-modal-btn-wp--back">
      <div v-if="isHistoryVisible"
           @click="$store.commit('baseSideModal/TOGGLE_MODAL_HORIZONTAL')"
           class="button button--mode-outline task-modal__history-hide">
        <icon-arrow-down/>
        Вернуться назад
      </div>
      <div v-else
           class="task-modal__mobile-actions">
        <button @click="$store.dispatch('baseSideModal/closeModal')"
                class="button button--mode-outline task-modal__mobile-back">
          <icon-arrow-left/>
        </button>
        <task-state-actions
          :task-id="task.id"
          :task-state-id="task.state.id"
          :check-safety="task.checkSafety"
        />
      </div>

    </div>
  </div>
</template>

<script>
import IconHistoryToggle from "@/components/Icons/IconHistoryToggle";
import IconClipboard from "@/components/Icons/IconClipboard";
import IconLink from "@/components/Icons/IconLink";
import IconTimer from "@/components/Icons/IconTimer";
import IconCalendar from "@/components/Icons/IconCalendar";
import IconProfileCircle from "@/components/Icons/IconProfileCircle";
import IconClock from "@/components/Icons/IconClock";
import TaskState from "@/models/serviceDesk/TaskState";
import WorksList from "@/components/ServiceDesk/Tasks/TaskComponents/Works/WorksList";
import {mapGetters, mapState} from "vuex";
import MechanicalEquipmentsList
  from "@/components/ServiceDesk/Tasks/TaskComponents/MechanicalEquipments/MechanicalEquipmentsList";
import AssignResourceForm from "@/components/ServiceDesk/Tasks/TaskComponents/Resource/AssignResourceForm";
import WaterFlowsList from "@/components/ServiceDesk/Tasks/TaskComponents/WaterFlows/WaterFlowsList";
import TransportOrdersList from "@/components/ServiceDesk/Tasks/TaskComponents/TransportOrders/TransportOrdersList";
import TaskFilesList from "@/components/ServiceDesk/Tasks/TaskComponents/Files/TaskFilesList.vue";
import HistoryList from "@/components/ServiceDesk/Tasks/TaskComponents/History/HistoryList";
import CommentsList from "@/components/ServiceDesk/Tasks/TaskComponents/Comments/CommentsList";
import TaskStateActions from "@/components/ServiceDesk/Tasks/TaskComponents/TaskStateActions";
import IconArrowDown from "@/components/Icons/IconArrowDown";
import IconArrowLeft from "@/components/Icons/IconArrowLeft";
import NetworkSpreadItem from "@/components/ServiceDesk/Tasks/TaskComponents/NetworkSpread/NetworkSpreadItem";
import BaseSideModalSkeleton from "@/components/BaseSideModalSkeleton.vue";
import sideModalControls from "@/mixins/sideModalControls";
import {modalAdaptiveWidth} from "@/utils/helpers";
import ConsumptionsList from "@/components/ServiceDesk/Tasks/TaskComponents/Consumptions/ConsumptionsList.vue";
import DetectedProblemsList
  from "@/components/ServiceDesk/Tasks/TaskComponents/DetectedProblems/DetectedProblemsList.vue";
import IconTagCross from "@/components/Icons/IconTagCross.vue";
import {formatDateTime} from "@/utils/filters";
import TaskPermit from "@/components/ServiceDesk/Tasks/TaskComponents/Permit/TaskPermit.vue";

export default {
  name: "TaskModalContent",
  components: {
    TaskPermit,
    IconTagCross,
    DetectedProblemsList,
    ConsumptionsList,
    BaseSideModalSkeleton,
    NetworkSpreadItem,
    IconArrowLeft,
    IconArrowDown,
    TaskStateActions,
    CommentsList,
    HistoryList,
    TaskFilesList,
    TransportOrdersList,
    WaterFlowsList,
    MechanicalEquipmentsList,
    WorksList,
    IconClock,
    IconProfileCircle,
    IconCalendar,
    IconTimer,
    IconLink,
    IconClipboard,
    IconHistoryToggle
  },
  mixins: [sideModalControls],
  computed: {
    ...mapState({
      task: state => state.taskModal.task,
      worksList: state => state.taskModal.worksList,
      mechanicalEquipmentList: state => state.taskModal.mechanicalEquipmentsList,
      mechanicalEquipmentsTypes: state => state.mechanicalEquipments.mechanicalEquipments,
      waterFlows: state => state.taskModal.waterFlowsList,
      transportOrders: state => state.taskModal.transportOrdersList,
      taskAttachments: state => state.taskModal.attachmentsList,
      taskHistory: state => state.taskModal.history,
      commentsList: state => state.taskModal.commentsList,
      networkSpread: state => state.taskModal.networkSpread,
      taskLoading: state => state.taskModal.loading,
      isHistoryVisible: state => state.baseSideModal.isModalHorizontal,
      issue: state => state.issueModal.issue,
      consumptionsList: state => state.taskModal.consumptionsList,
      detectedProblemsList: state => state.taskModal.detectedProblemsList
    }),
    ...mapGetters({
      firstHistoryItem: 'taskModal/getHistoryFirstItem',
      works: 'works/getActiveWorkTypesByCategoryGroup'
    }),
    TaskState: () => TaskState,
    pdfTableData() {
      const headers = [
        '№','Наименование','Единица измерения','инв. №', '№ компонента ОС','Куда израсходованы',
        'Количество','Цена','Сумма'
      ]
      let body = [];
      for (let i = 0; i < this.consumptionsList.length; i++) {
        body.push([i + 1, this.consumptionsList[i].consumable.name, this.consumptionsList[i].consumable.measure,
          '', '', '', this.consumptionsList[i].amount,'','']);
      }
      return [
        headers, ...body
      ]
    }
  },
  mounted() {
    this.$store.dispatch('mechanicalEquipments/getAllMechanicalEquipments');
    this.$store.dispatch('works/getAllWorks', {group_id: this.issue.category.group_id});
  },
  methods: {
    formatDateTime,
    updateResource() {
      if (this.task.state.isNew && !this.task.typeFeatures.some(item => item.slug === 'transport_order')) {
        const oldPayload = {
          brigade_id: this.task.brigade.id,
          planned_start_date: this.task.planned_start_date,
          planned_end_date: this.task.planned_end_date
        }
        this.showAssignResourceModal(oldPayload);
      }
    },
    isPlanningVisible() {
      if (this.task.typeFeatures.some(item => item.slug === 'transport_order')) {
        return Boolean(this.task.planned_start_date)
      } else return true
    },
    showAssignResourceModal(oldPayload = {}) {
      this.$modal.show(
        AssignResourceForm,
        {
          'task-id': this.task.id,
          'department-id': this.task.department.id,
          'oldPayload': oldPayload
        },
        {
          name: 'assign-brigade-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(800)
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>

.task-modal__history-hide {
  width: 100%;

  & svg {
    stroke: var(--ico);
    transform: rotate(90deg);
  }
}

.task-modal__base-action {
  display: flex;
  flex-direction: column;
  gap: 36px;

  margin-top: 20px;
  padding-top: 36px;
  border-top: 1px solid var(--border-main);

  padding-bottom: 36px;
  margin-bottom: 36px;
  border-bottom: 1px solid var(--border-main);
}
</style>
