<template>
  <base-filter-modal
    :is-visible="isAddModalVisible"
    @toggle-modal="$emit('close-add-modal')">
    <form @submit.prevent="submitForm()"
          class="brigades-work-shift-modal">
      <div class="brigades-work-shift-modal__header">
        <h3>Добавить событие</h3>
      </div>
      <div>
        <label class="input-label">Подразделение</label>
        <v-select
          v-model="departmentId"
          :options="departments"
          :reduce="department => department.id"
          :get-option-label="(option) => option.name"
          placeholder="Выберите подразделение"
          @update:modelValue="getDepartmentResources()"
          class="vue-select-input"/>
      </div>
      <div>
        <label class="input-label">Ресурс</label>
        <v-select
          v-model="payload.resource_id"
          :options="resourcesList(departmentId)"
          :reduce="brigade => brigade.id"
          :get-option-label="(option) => option.name"
          placeholder="Выберите ресурс"
          class="vue-select-input">
          <template #no-options="">
            Не найдено ресурсов, выберите подразделение
          </template>
        </v-select>
      </div>
      <div>
        <label class="input-label">Дата</label>
        <base-date-picker
          v-model="payload.work_day"
          placeholder="Выберите дату"
          format="dd.MM.yyyy"
          value-format="yyyy-MM-dd"
          @cleared="payload.work_day = null"
        />
      </div>
      <button :disabled="isIncorrectDate"
              data-modal-close
              class="button">Добавить
      </button>
    </form>
  </base-filter-modal>
</template>

<script>
import BaseFilterModal from "@/components/BaseFilterModal.vue";
import {mapGetters, mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";
import moment from "moment";
import BaseDatePicker from "@/components/BaseDatePicker.vue";

export default {
  name: 'BrigadesWorkShiftModal',
  components: {BaseDatePicker, BaseFilterModal},
  props: {
    isAddModalVisible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      departmentId: null,
      payload: {
        work_day: null,
        resource_id: null
      },
      date: null
    }
  },
  computed: {
    ...mapState({
      workShifts: state => state.workShift.workShifts,
      filterParams: state => state.workShift.filterParams,
      departments: state => state.departments.departments
    }),
    ...mapGetters({
      resourcesList: 'resources/getResourcesByDepartmentId'
    }),
    isIncorrectDate() {
      return this.payload.work_day ? this.payload.work_day < moment().format('YYYY-MM-DD') : true;
    }
  },
  methods: {
    getDepartmentResources() {
      this.$store.dispatch('resources/getAllResourcesList');
    },
    submitForm() {
      this.$store.dispatch('workShift/createWorkShift', this.payload)
        .then(() => {
          this.payload = {
            work_day: null,
            resource_id: null
          }
          this.departmentId = null;
          this.$store.commit('departmentBrigades/SET_BRIGADES', []);
          showToast('Расписание добавлено', 'success');
          this.$store.dispatch('workShift/getWorkShifts', this.filterParams);
        })
        .catch(error => {
          showAlert('Ошибка', error);
        })
    }
  }
}
</script>

<style lang="scss" scoped>
form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.brigades-work-shift-modal__header h3 {
  color: var(--text-primary);
}
</style>
